import { v4 } from "uuid";
import { ApiCancelTokenSource } from "../services/ApiService";
import { QueryItemParams } from "../store/priceAndAvailabilityStore";
import { ErrorResponse } from "./Error";
import { MaterialDetailResult } from "./MaterialDetailResult";
import {
  CatalogNumberLookupResult,
  CustomerMaterialLookupResult,
  MaterialLookupResult,
  MaterialSearchResult,
  MaterialNumberLookupResult,
  DivisionLookupResult,
  SalesOrgLookupResult,
} from "./MaterialLookup";
import {
  MaterialPriceAndAvailabilityResult,
  MaterialProductDataResult,
} from "./MaterialPriceAndAvailabilityResult";

export const MaterialNumberRegex = new RegExp(/^[A-Za-z0-9- .!%]{2,35}$/);

export enum MaterialDataQueryItemStatus {
  LOADING = "loading",
  ERROR = "error",
  READY = "ready",
}

export interface MaterialDataQuery {
  value: string;
  quantity: number | undefined;
}
export interface ProductData {
  value: string;
  quantity: number | undefined;
}

export interface MaterialLookupSuggestion {
  selectedSuggestion:
    | CatalogNumberLookupResult
    | MaterialLookupResult
    | CustomerMaterialLookupResult
    | MaterialSearchResult
    | undefined;
  catalogNumberSuggestions: Array<CatalogNumberLookupResult> | undefined;
  materialNumberSuggestion: MaterialLookupResult | undefined;
  customerMaterialNumberSuggestion: CustomerMaterialLookupResult | undefined;
  materialSearchSuggestions: Array<MaterialSearchResult> | undefined;
}
export interface ProductLookupSuggestion {
  selectedSuggestion:
    | MaterialNumberLookupResult
    | SalesOrgLookupResult
    | DivisionLookupResult
    | undefined;
  materialNumberSuggestion: MaterialLookupResult | undefined;
  salesOrgSuggestion: SalesOrgLookupResult | undefined;
  divisionSuggestion: DivisionLookupResult | undefined;
}

export interface MaterialDataQueryItemInterface
  extends MaterialDataQuery,
    MaterialLookupSuggestion {
  id: string;
  materialPriceAndAvailabilityResult:
    | MaterialPriceAndAvailabilityResult
    | undefined; // P&A data
  materialDetailResult: MaterialDetailResult | null | undefined; //  Product Detail (hybris)
  isValid: boolean;
  status: MaterialDataQueryItemStatus | undefined;
  error: ErrorResponse | undefined;
  callParams?: QueryItemParams;
  cancel?: ApiCancelTokenSource;
}

export interface ProductDataInterface
  extends ProductData,
    ProductLookupSuggestion {
  id: string;
  materialProductDataResult: MaterialProductDataResult | undefined;
}

export function MaterialDataQueryItemFactory(params?: MaterialDataQuery) {
  const item = params
    ? (params as MaterialDataQueryItemInterface)
    : ({ value: "", quantity: 1 } as MaterialDataQueryItemInterface);
  item.value = item.value.trim();
  item.id = v4();
  return item;
}

export function validateMaterialNumber(value: string): boolean {
  let v = value.search(MaterialNumberRegex) !== -1;
  return v;
}
