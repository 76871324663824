import ApiService, { ApiCancelTokenSource } from "./ApiService";
import { OrderData } from "../services/MockOrder";
import HubbellConnectOrder, {
  Address,
  Detail,
  FreightDetailsTerms,
  Item,
  DetailData,
} from "../models/HubbellConnectOrder";
import { AxiosError, AxiosResponse } from "axios";
import { AddressValidationInterface } from "../store/cart/dispatchers";
import { Orders } from "../models/Order";
import {
  OrderEntryItemValidationResult,
  ValidateOrderInterface,
} from "../models/OrderEntrySAPValidation";

const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;
const baseUrl = process.env.REACT_APP_XREF_API;
const baseCoreURL = process.env.REACT_APP_APIM_CORE_BASE_COMMERCE;
const baseURL = process.env.REACT_APP_HUBBELLCONNECT_API_BASE;
const freightTermURL = process.env.REACT_APP_FREIGHT_API;
const dataQuality = "https://api.hubbell.com/dataquality/";
const carrierURL = process.env.REACT_APP_CARRIER_API;
const hubbellConnect_Country =
  process.env.REACT_APP_COUNTRY_CODE?.length === 2
    ? process.env.REACT_APP_COUNTRY_CODE
    : "US";

export interface DraftAccountNumberInterface {
  accountNumber: string;
}

export interface DraftOrderNumberInterface {
  orderNumber: string;
}

class OrderEntryService extends ApiService {
  async DeleteOrderItem(
    orderId: string,
    itemId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `Order/${orderId}/item/${itemId}`;
    return await this.connection.delete(url, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async OrderDraftCarrierDetails(
    salesOrg: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = `/${salesOrg}`;
    let result = await this.connection
      .get(url, {
        baseURL: carrierURL,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: any) => {
        return resp;
      })
      .catch((error: AxiosError) => {
        console.log("Error", error);
        return error;
      });
    return result;
  }

  async OrderDraftForAccountNumber(
    accountNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }

    const url = `/Order/GetDraft?accountNumber=${accountNumber}`;
    let result = await this.connection
      .get(url, {
        baseURL: baseURL,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: any) => {
        return resp;
      });
    return result.data.length
      ? result?.data[0]
      : result?.data;
  }

  async OrderDraftForOrderNumber(
    orderNumber: DraftOrderNumberInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = "/Order/getOrderDraftfForOrderNumber";
    let result = await this.connection
      .get(url, {
        baseURL: baseUrl,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: any) => {
        return resp;
      })
      .catch((error: any) => {
        console.log(
          "Exception occured while calling OrderDraftForOrderNumber API",
          error
        );
        console.log("Sending Mock Order Data");
        return OrderData;
      });

    return result;
  }

  async validateAddress(
    query: AddressValidationInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `/AddressCleanse`;
    let result = await this.connection
      .post(url, query, {
        baseURL: dataQuality,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
    return result;
  }

  async updateOrderEntryAddress(
    orderId: string,
    orderAddressId: string | undefined,
    query: Address | undefined,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Address> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const url = `/order/${orderId}/addresses/${orderAddressId}`;
    let result = await this.connection
      .put(url, query, {
        baseURL: baseURL,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: AxiosResponse) => {
        console.log("updateresp", resp);
        return resp.data;
      })
      .catch((error: AxiosError) => {
        console.log("Error", error);
        return error;
      });

    return result;
  }

  //Below API service is for OrderAddress

  async createOrder(
    query: HubbellConnectOrder,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const url = `/Order`;
    let result = await this.connection.post(url, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async createOrderAddress(
    orderId: string,
    query: HubbellConnectOrder,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<HubbellConnectOrder> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const url = `/Order/${orderId}/addresses`;
    let result = await this.connection.post(url, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async getOrderAddress(
    orderId: string,
    orderAddressId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const url = `/Order/${orderId}/addresses/${orderAddressId}`;
    let result = await this.connection.get(url, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async updateOrderAddress(
    orderId: string,
    orderAddressId: string,
    query: Address | undefined,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Address> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const url = `/Order/${orderId}/addresses/${orderAddressId}`;
    let result = await this.connection.put(url, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async deleteOrderAddress(
    orderId: string,
    orderAddressId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Address> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const relativeUrl = `/Order/${orderId}/item/${orderAddressId}`;
    return this.connection.delete(relativeUrl, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  //Below API Service is for order Item

  async createOrderItem(
    orderId: string,
    query: Item,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const relativeUrl = `/Order/${orderId}/item`;
    let result = await this.connection.post(relativeUrl, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  //the API under this is not correct please revisit to fix this before using it
  // async getOrderItem(
  //   orderId: string,
  //   orderItemId: string,
  //   cancelTokenSource?: ApiCancelTokenSource
  // ): Promise<any> {
  //   let token = await this.authorize();
  //   const headers: { [key: string]: any } =
  //     await this.getAuthorizationHeaders();
  //   if (coreAPIM_Header) {
  //     headers[coreAPIM_Header] = coreAPIM_Key;
  //     headers["Authorization"] = `Bearer ${token}`;
  //     headers["Country"] = hubbellConnect_Country;
  //   }
  //   const url = `/Order/${orderId}/addresses/${orderItemId}`;
  //   let result = await this.connection.get(url, {
  //     baseURL: baseURL,
  //     headers: headers,
  //     cancelToken: cancelTokenSource?.token,
  //   });
  //   return result.data;
  // }

  async updateOrderItem(
    orderId: string,
    orderItemId: string,
    query: Item,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const relativeUrl = `/Order/${orderId}/item/${orderItemId}`;
    let result = await this.connection.put(
      relativeUrl,
      query,
      {
        baseURL: baseURL,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      }
    );
    return result.data;
  }

  async deleteOrderItem(
    orderId: string,
    orderItemId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const relativeUrl = `/Order/${orderId}/item/${orderItemId}`;
    return this.connection.delete(relativeUrl, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  // freight terms service
  async freightTerm(
    accountNumber: string,
    salesOrg: string,
    division: string
  ): Promise<FreightDetailsTerms> {
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const relativeUrl = `?accountNumber=${accountNumber}&salesOrg=${salesOrg}&division=${division}`;
    let result = await this.connection.get(relativeUrl, {
      baseURL: freightTermURL,
      headers: headers,
    });
    return result.data;
  }

  async deleteOrder(
    orderId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Item> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const relativeUrl = `/Order/${orderId}`;
    return this.connection.delete(relativeUrl, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  async createOrderDraft(
    reqbody: any,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<AxiosResponse<Orders>> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Country"] = hubbellConnect_Country;
    }
    const url = "/Order";
    return await this.connection.post(url, reqbody, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
  }

  //Order Entry Details

  async updateOrderEntryDetails(
    orderId: string,
    orderDetailId: string,
    query: DetailData,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Detail> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const relativeUrl = `/order/${orderId}/orderdetail/${orderDetailId}`;
    let result = await this.connection.put(relativeUrl, query, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async getupdateOrderEntryDetails(
    orderId: string,
    orderDetailId: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Detail> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `/Order/${orderId}/orderdetail/${orderDetailId}`;
    let result = await this.connection.get(url, {
      baseURL: baseURL,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async validateOrderItem(
    orderId: string
  ): Promise<OrderEntryItemValidationResult> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const url = `/order/${orderId}/items/validate`;
    return await this.connection.get(url, {
      baseURL: baseURL,
      headers: headers,
    });
  }

  async validateWholeOrder(orderId: string): Promise<ValidateOrderInterface> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
      headers["Country"] = hubbellConnect_Country;
    }
    const url = `/order/${orderId}/validate`;
    return await this.connection.get(url, {
      baseURL: baseURL,
      headers: headers,
    });
  }

  async uploadOrderDetailAttatchment(data: FormData, orderDetailId: string): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `/orders/attachment/${orderDetailId}`;
    return await this.connection.post(url,data, {
      baseURL: baseCoreURL,
      headers: headers,
      responseType: "blob",
    });
  }

  async getOrderDetailAttatchment(orderDetailId: string): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders(); 
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `/orders/attachment/${orderDetailId}`;
    return await this.connection.get(url,{
      baseURL: baseCoreURL,
      headers: headers,
    });
  }

  async deleteOrderDetailAttatchment(orderDetailId: string): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders(); 
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `/orders/attachment/${orderDetailId}`;
    return await this.connection.delete(url,{
      baseURL: baseCoreURL,
      headers: headers,
    });
  }
}

export default OrderEntryService;
