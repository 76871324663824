import HubbellConnectOrder, {
  Item,
  Notification,
} from "../../../models/HubbellConnectOrder";
import { AsyncStateObject } from "../../AsyncStateObject";
import { MaterialPriceAndAvailabilityResult } from "../../../models/MaterialPriceAndAvailabilityResult";

export enum FormStatus {
  VALID = "true",
  INVALID = "false",
}

export enum HubbellOrderItemUpdateType {
  price = "price",
  inventory = "inventory",
  priceInventory = "price-inventory",
}

export interface ItemUpdateAsyncStateObject
  extends AsyncStateObject<MaterialPriceAndAvailabilityResult> {
  updateType: HubbellOrderItemUpdateType;
}

interface CartState {
  orderDraft: AsyncStateObject<HubbellConnectOrder>;
  checkoutValidity: {
    items: FormStatus;
    shippingDetails: FormStatus;
    orderDetails: FormStatus;
  };
  loading: boolean;
  itemData?: Array<ItemUpdateAsyncStateObject>;
  itemUpdateQueue?: Array<AsyncStateObject<Item>>;
  notificationToast?: Array<Notification>;
  validatedItems?: Array<any>;
  brands?: Array<string>;
}
export default CartState;
