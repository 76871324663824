import ApiService from "../../../services/ApiService";
import { RMASearchResponse, RmaDetailResponse } from "../store/model/RmaSearchQuery";
import { RmaSearchQuery } from "../store/model/RmaSearchQuery";
//const baseURL = process.env.REACT_APP_HUBBELLCONNECT_API_BASE;
const baseURL = process.env.REACT_APP_RMA_DETAIL_API;


class RMAService extends ApiService {
  async GetRMABySearchString(
    query: Partial<RmaSearchQuery>,
    abortSignal?: AbortSignal
  ): Promise<RMASearchResponse> {
    const headers = await this.getAuthorizationHeaders();

    let result = await this.connection.get("rmaSearch/searchall", {
      baseURL: baseURL,
      headers: headers,
      params: query,
      signal: abortSignal,
    });
    return result.data;
  }

  /**
   *
   * @param rmaNumber
   * @param {AbortSignal|undefined} abortSignal
   * @returns
   */
  async getRMADetails(
    rmaNumber: string,
    abortSignal?: AbortSignal
  ): Promise<RmaDetailResponse> {
    const headers = await this.getAuthorizationHeaders();
    let result = await this.connection.get(`rma/${rmaNumber}`, {
      baseURL: baseURL,
      headers: headers,
      signal: abortSignal,
    });

    return result.data;
  }

  /**
   *
   * @param rmaNumber
   * @param {AbortSignal|undefined} abortSignal
   * @returns
   */
  async getTermsOfSale(
    rmaNumber: string,
    abortSignal?: AbortSignal
  ): Promise<RmaDetailResponse> {
    const headers = await this.getAuthorizationHeaders();
    let result = await this.connection.get(`rmaterms/${rmaNumber}`, {
      baseURL: baseURL,
      headers: headers,
      signal: abortSignal,
    });

    return result.data;
  }
}

export default RMAService;
