import {
  CustomerQuoteSearchInterface,
  PDPQuoteSearchByMaterial,
} from "./QuoteServiceInterface";
import ApiService, { ApiCancelTokenSource } from "./ApiService";
import {
  DownloadQuoteResponse,
  EmailQuoteResponse,
  MaterialQuotes,
  QuoteDocumentTypes,
  Quotes,
  QuoteTermsAndConditions,
  QuoteTermsOfSaleResponse,
  SalesQuoteType,
} from "../models/Quote";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../models/Error";
import { FileHelper } from "../util/fileHelper";

const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;
const coreAPIM_SRI_PATH = process.env.REACT_APP_SRI_API_BASE;

class QuotesService extends ApiService {
  async Search(
    query: CustomerQuoteSearchInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Quotes> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = "quotes/Search";
    let result = await this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      params: query,
      cancelToken: cancelTokenSource?.token,
    });

    let quoteData = result.data as Quotes;
    return quoteData as Quotes;
  }

  async getQuotesForMaterial(
    query: PDPQuoteSearchByMaterial,
    materialNumber: string,
    division: string,
    salesOrg: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<MaterialQuotes> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = `quotes/formaterial/${materialNumber}?salesOrg=${salesOrg}&division=${division}`;
    let result = await this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      params: query,
      cancelToken: cancelTokenSource?.token,
    });

    let quoteData = result.data as MaterialQuotes;
    return quoteData as MaterialQuotes;
  }

  async GetQuoteTypes(
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Array<SalesQuoteType>> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    const url = "/quotes/quotetypes";
    let result = await this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });

    let quoteTypes = result.data as Array<SalesQuoteType>;
    return quoteTypes;
  }

  async GetQuoteDetails(
    quoteNumber: string,
    customerAccountNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    let result = await this.connection.get(`quotes/${quoteNumber}`, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      params: { customerAccountNumber: customerAccountNumber },
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async GetQuoteAggregateTermsAndConditions(
    quoteNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<QuoteTermsAndConditions> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    let result1 = await this.GetQuoteTerms(quoteNumber, cancelTokenSource);
    let result2 = await this.GetQuoteTermsOfSale(
      quoteNumber,
      cancelTokenSource
    );
    const aggregateTerms = {} as QuoteTermsAndConditions;
    // normalize our general terms since it comes over as an html string
    let generalTerms = new Array<string>();
    if (typeof result1 === "string") {
      result1.trim();
      if (result1.length > 0) {
        generalTerms.push(result1);
      }
    }
    aggregateTerms.generalTerms = generalTerms;
    // add the result from the terms of sale call
    aggregateTerms.paymentTerms = result2.paymentTerms;
    aggregateTerms.shippingTerms = result2.shippingTerms;
    return aggregateTerms;
  }

  async GetQuoteTerms(
    quoteNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<string> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    let result = await this.connection.get(`quotes/${quoteNumber}/terms`, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    let quoteTerms = result.data
      ? result.data === " "
        ? ""
        : result.data
      : "";
    return quoteTerms;
  }

  async GetQuoteTermsOfSale(
    quoteNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<QuoteTermsOfSaleResponse> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    let result = await this.connection.get(
      `quotes/${quoteNumber}/termsofsale`,
      {
        baseURL: coreAPIM_SRI_PATH,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      }
    );

    return result.data;
  }

  async GetQuoteDocument(
    quoteNumber: string,
    accountNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<DownloadQuoteResponse> {
    let downloadApiRelPath = `quotes/${quoteNumber}/pdf?accountNumber=${accountNumber}`;

    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    let result = await this.connection
      .get(downloadApiRelPath, {
        baseURL: coreAPIM_SRI_PATH,
        headers: headers,
        responseType: "blob",
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: AxiosResponse) => {
        return resp;
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          return error.response;
        } else {
          throw new Error(error.message);
        }
      });

    let fileHelper = new FileHelper<Document>();
    let downloadResponse = await fileHelper
      .parseDownloadApiResponse(quoteNumber, result)
      .then((downloadResponse) => {
        return downloadResponse;
      })
      .catch((error: Error) => {
        throw error;
      });
    return downloadResponse;
  }

  async GetQuoteDocuments(
    quoteNumbers: string[],
    accountNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<DownloadQuoteResponse> {
    let downloadApiRelPath = `quotes/pdf?accountNumber=${accountNumber}`;

    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    let result = await this.connection
      .get(downloadApiRelPath, {
        baseURL: coreAPIM_SRI_PATH,
        headers: headers,
        responseType: "blob",
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: AxiosResponse) => {
        return resp;
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          return error.response;
        } else {
          throw new Error(error.message);
        }
      });

    let fileHelper = new FileHelper<Document>();
    let downloadResponse = await fileHelper
      .parseDownloadApiResponse("MULTIPLE", result)
      .then((downloadResponse) => {
        return downloadResponse;
      })
      .catch((error: Error) => {
        throw error;
      });
    return downloadResponse;
  }

  async EmailQuoteDocuments(
    email: string[],
    quoteNumbers: string[],
    accountNumber: string,
    documentType: QuoteDocumentTypes,
    cc: string[],
    senderNameOrEmail?: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<EmailQuoteResponse> {
    //to do udpate with API-M URL & path
    let apiBaseUrl = process.env.REACT_APP_QUEUEFUNCTIONS_BASE;
    let emailApiRelPath = `api/QueueDocumentsToEmailHTTPTrigger?${process.env.REACT_APP_QUEUEFUNCTIONS_KEYNAME}=${process.env.REACT_APP_QUEUEFUNCTIONS_KEYVALUE}`;

    let apiData = {
      accountNumber: accountNumber,
      to: email,
      cc: cc,
      documentType,
      quoteNumbers,
      senderEmail: !senderNameOrEmail
        ? this.authAccountName || this.userName
        : senderNameOrEmail,
    };

    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    let result = await this.connection
      .post(emailApiRelPath, JSON.stringify(apiData), {
        baseURL: apiBaseUrl,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: AxiosResponse) => {
        return resp;
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          return error.response;
        } else {
          throw new Error(error.message);
        }
      });

    let emailResult = undefined;
    if (result.status === 200) {
      emailResult = {
        Error: {
          Error: "",
          Message: "",
          Detail: "",
        },
        Results: "Requested document(s) will be emailed shortly.",
      };
    } else {
      emailResult = {
        Error: result.data as ErrorResponse,
        Results: undefined,
      };
      if (emailResult.Error && emailResult.Error.Detail) {
        let details = JSON.parse(emailResult.Error.Detail) as string[];
        if (details && details.length > 0) {
          emailResult.Error.Detail = details.join(". ");
        }
      }
    }
    return emailResult;
  }
}
export default QuotesService;
