import { useRef, useEffect } from "react";

export default function useInterval(callback:Function, delay:number) {
    const savedCallback = useRef<Function>();
  
    useEffect(() => {
      savedCallback.current = callback;
    });
  
    useEffect(() => {
      function tick() {
        if(savedCallback.current){
            savedCallback.current()
        };
      }
  
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }