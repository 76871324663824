import { Nav } from "react-bootstrap";
import { RouteDefinition, useRoutes } from "./Routes";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/pro-solid-svg-icons";

export function PrimaryNavigation() {
  const routes = useRoutes();

  if (routes.moduleRoutes.length > 0) {
    return (
      <Nav className="primary-nav" role="navigation">
        <NavLink to="/">
          <span className="icon">
            <FontAwesomeIcon icon={faHouse} />
          </span>
          <span className="text">Home Page</span>
        </NavLink>
        {routes.moduleRoutes.map((route: RouteDefinition): JSX.Element => {
          let title = route.title;
          let icon = route.icon;
          return (
            <NavLink to={route.path.replace("/*", "")} key={route.name}>
              <span className="icon">{icon}</span>
              <span className="text">{title}</span>
            </NavLink>
          );
        })}
      </Nav>
    );
  } else {
    return <></>;
  }
}

export function SecondaryNavigation() {
  return (
    <Nav className="tertiary-nav secondary-nav" role="navigation" activeKey="/">
      <NavLink to="/support">
        <span className="text">Support</span>
      </NavLink>

      <a href="https://www.hubbell.com/hubbell/en/terms-of-use" target="blank">
        <span className="text">Terms of Use</span>
      </a>

      <a
        href="https://www.hubbell.com/hubbell/en/terms-of-use#99"
        target="blank"
      >
        <span className="text">Privacy Policy</span>
      </a>
    </Nav>
  );
}
