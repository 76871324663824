export const OrderData = {
  orderId: "1",
  orderNumber: "",
  accountNumber: "0000201037",
  sapOrderNumbers: "",
  orderState: {
    orderStateId: "",
    name: "",
  },
  orderStateNote: "",
  createdBy: {
    userId: "1",
    userName: "jpadgett@hubbell.com",
    name: "Jason Padgett",
  },
  createdOn: "2023-03-03T21:37:27.432Z",
  modifiedBy: {
    userId: "1",
    userName: "jpadgett@hubbell.com",
    name: "Jason Padgett",
  },
  modifiedOn: "2023-03-08T21:37:27.432Z",
  orderAddresses: [
    {
      orderAddressId: "1",
      name1: "VINSON ELECTRIC SUPPLY INC",
      address1: "2500 S ARKANSAS AVE",
      city: "RUSSELLVILLE",
      postal: "72802-7369",
      region: "AR",
      country: "US",
      isUserSupplied: false,
      orderAddressType: {
        name: "Sold-To",
      },
    },
    {
      orderAddressId: "2",
      name1: "VINSON ELECTRIC SUPPLY INC",
      address1: "2500 S ARKANSAS AVE",
      city: "RUSSELLVILLE",
      postal: "72802-7369",
      region: "AR",
      country: "US",
      isUserSupplied: false,
      orderAddressType: {
        name: "Ship-To",
      },
    },
    {
      orderAddressId: "3",
      name1: "EDN",
      address1: "1997 ANNAPOLIS EXCHANGE PK, STE 300",
      city: "ANNAPOLIS",
      postal: "21401-3273",
      region: "MD",
      country: "US",
      isUserSupplied: false,
      orderAddressType: {
        name: "Bill-To",
      },
    },
  ],
  items: [
    {
      materialNumber: "5173-0",
      customerMaterialNumber: null,
      description: "1G VERT/HORIZ WP COVER BLANK - GRAY",
      salesOrg: "C400",
      itemDivision: "4A",
      poLineNumber: "",
      quantity: 5,
      headerDivision: "4A",
      itemCategory: "NORM",
      minimumOrderQty: "0.000",
      baseUnitOfMeasure: "EA",
      standardPackageQty: "0.000",
      palletQty: "8250",
      caseQty: null,
      cartonQty: "50",
      averageLeadTime: "91",
      commodityCode: "7616995190",
      countryOfOrigin: "India",
      quoteGroup: "417",
      price: "103.0000",
      unitPrice: 0,
      listPrice: "428.3000",
      tradePrice: "0.0000",
      priceUnit: "100",
      currency: "USD",
      returnable: true,
      inventory: {
        primary: [
          {
            plantType: "P",
            plant: "4103",
            plantDescription: "HES Raco, Ontario, CA",
            quantityAvailable: "37403.000",
            street: "1392 Sarah Place, A",
            city: "Ontario",
            region: "CA",
            country: "US",
            postal: "91761",
            nextAtpDate: "20230327",
            nextAtpQty: "14050.000",
            transitQty: "0.000",
          },
        ],
        secondary: [
          {
            plantType: "S",
            plant: "4102",
            plantDescription: "HES Raco RTB, Aurora, IL",
            quantityAvailable: "55146.000",
            street: "945 Corporate BLVD, Ste 101",
            city: "Aurora",
            region: "IL",
            country: "US",
            postal: "60502-9176",
            nextAtpDate: "20230321",
            nextAtpQty: "5450.000",
            transitQty: "0.000",
          },
        ],
        alternative: [],
      },
      pricingAccessMessage: null,
      itemType: "Stock",
      weight: "0.175 LB",
      upc: "050169517307",
      grossWeight: "0.175",
      grossWeightUnitOfMeasure: "LB",
      createdBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      createdOn: "2023-03-03T21:37:27.432Z",
      requestedShipDate: "2023-03-08T21:37:27.432Z",
      referenceOrderNumber: "0121152715",
      consigneePurchaseOrderNumber: "",
      customerPartNumber: "",
      extendedPrice: 0,
      lineNumber: "",
      markings: "",
      modifiedBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      modifiedOn: "2023-03-08T21:37:27.432Z",
      sapOrderNumber: "",
      orderItemId: "abcd",
      orderItemState: {
        orderItemStateId: "",
        name: "",
      },
      orderItemStateNote: "",
    },
    {
      materialNumber: "5320-0",
      customerMaterialNumber: null,
      description: "1G WP BOX (3) 1/2 IN OUTLETS GRY",
      salesOrg: "C400",
      itemDivision: "4A",
      headerDivision: "4A",
      poLineNumber: "",
      quantity: 2,
      itemCategory: "NORM",
      minimumOrderQty: "1.000",
      baseUnitOfMeasure: "EA",
      standardPackageQty: "1.000",
      palletQty: "1400",
      caseQty: null,
      cartonQty: "20",
      averageLeadTime: "91",
      commodityCode: "7616995190",
      countryOfOrigin: "India",
      quoteGroup: "417",
      price: "389.0000",
      unitPrice: 0,
      listPrice: "1897.0200",
      tradePrice: "0.0000",
      priceUnit: "100",
      currency: "USD",
      returnable: true,
      inventory: {
        primary: [
          {
            plantType: "P",
            plant: "4103",
            plantDescription: "HES Raco, Ontario, CA",
            quantityAvailable: "31781.000",
            street: "1392 Sarah Place, A",
            city: "Ontario",
            region: "CA",
            country: "US",
            postal: "91761",
            nextAtpDate: "20230327",
            nextAtpQty: "6220.000",
            transitQty: "0.000",
          },
        ],
        secondary: [
          {
            plantType: "S",
            plant: "4102",
            plantDescription: "HES Raco RTB, Aurora, IL",
            quantityAvailable: "75298.000",
            street: "945 Corporate BLVD, Ste 101",
            city: "Aurora",
            region: "IL",
            country: "US",
            postal: "60502-9176",
            nextAtpDate: "20230327",
            nextAtpQty: "3560.000",
            transitQty: "0.000",
          },
        ],
        alternative: [],
      },
      pricingAccessMessage: null,
      itemType: "Stock",
      weight: "0.667 LB",
      upc: "050169532003",
      grossWeight: "0.667",
      grossWeightUnitOfMeasure: "LB",
      createdBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      createdOn: "2023-03-03T21:37:27.432Z",
      requestedShipDate: "2023-03-08T21:37:27.432Z",
      referenceOrderNumber: "0121152715",
      consigneePurchaseOrderNumber: "",
      customerPartNumber: "",
      extendedPrice: "389.0000",
      lineNumber: "",
      markings: "",
      modifiedBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      modifiedOn: "2023-03-08T21:37:27.432Z",
      sapOrderNumber: "",
      orderItemId: "1234",
      orderItemState: {
        orderItemStateId: "",
        name: "",
      },
      orderItemStateNote: "",
    },
    {
      materialNumber: "5341-0",
      customerMaterialNumber: null,
      description: "2G WP BOX (3) 3/4 IN. OUTLETS - GRAY",
      salesOrg: "C400",
      itemDivision: "4A",
      headerDivision: "4A",
      itemCategory: "NORM",
      poLineNumber: "",
      quantity: 5,
      minimumOrderQty: "0.000",
      baseUnitOfMeasure: "EA",
      standardPackageQty: "0.000",
      palletQty: "864",
      caseQty: null,
      cartonQty: "12",
      averageLeadTime: "91",
      commodityCode: "7616995190",
      countryOfOrigin: "India",
      quoteGroup: "417",
      price: "965.0000",
      unitPrice: "100",
      listPrice: "3845.0500",
      tradePrice: "0.0000",
      priceUnit: "100",
      currency: "USD",
      returnable: true,
      inventory: {
        primary: [
          {
            plantType: "P",
            plant: "4103",
            plantDescription: "HES Raco, Ontario, CA",
            quantityAvailable: "4268.000",
            street: "1392 Sarah Place, A",
            city: "Ontario",
            region: "CA",
            country: "US",
            postal: "91761",
            nextAtpDate: "20230327",
            nextAtpQty: "3456.000",
            transitQty: "0.000",
          },
        ],
        secondary: [
          {
            plantType: "S",
            plant: "4102",
            plantDescription: "HES Raco RTB, Aurora, IL",
            quantityAvailable: "5489.000",
            street: "945 Corporate BLVD, Ste 101",
            city: "Aurora",
            region: "IL",
            country: "US",
            postal: "60502-9176",
            nextAtpDate: "20230413",
            nextAtpQty: "3456.000",
            transitQty: "0.000",
          },
        ],
        alternative: [
          {
            plantType: "A",
            plant: "4106",
            plantDescription: "HES Raco, Clifton, NJ",
            quantityAvailable: "1184.000",
            street: "380 Allwood Road",
            city: "Clifton",
            region: "NJ",
            country: "US",
            postal: "07012",
            nextAtpDate: "20230403",
            nextAtpQty: "0.000",
            transitQty: "0.000",
          },
        ],
      },
      pricingAccessMessage: null,
      itemType: "Stock",
      weight: "0.892 LB",
      upc: "050169534106",
      grossWeight: "0.892",
      grossWeightUnitOfMeasure: "LB",
      createdBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      createdOn: "2023-03-03T21:37:27.432Z",
      requestedShipDate: "2023-03-08T21:37:27.432Z",
      referenceOrderNumber: "0121152715",
      consigneePurchaseOrderNumber: "",
      customerPartNumber: "",
      extendedPrice: "965.0000",
      lineNumber: "",
      markings: "",
      modifiedBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      modifiedOn: "2023-03-08T21:37:27.432Z",
      sapOrderNumber: "",
      orderItemId: "",
      orderItemState: {
        orderItemStateId: "",
        name: "",
      },
      orderItemStateNote: "",
    },
    {
      materialNumber: "5361-0",
      customerMaterialNumber: null,
      description: "RND WP BOX (5) 1/2 IN. OUTLETS - GRAY",
      salesOrg: "C400",
      itemDivision: "4A",
      headerDivision: "4A",
      poLineNumber: "",
      quantity: 5,
      itemCategory: "NORM",
      minimumOrderQty: "0.000",
      baseUnitOfMeasure: "EA",
      standardPackageQty: "0.000",
      palletQty: "1440",
      caseQty: null,
      cartonQty: "16",
      averageLeadTime: "91",
      commodityCode: "7616995190",
      countryOfOrigin: "India",
      quoteGroup: "417",
      price: "544.0000",
      unitPrice: "100",
      listPrice: "3032.6400",
      tradePrice: "0.0000",
      priceUnit: "100",
      currency: "USD",
      returnable: true,
      inventory: {
        primary: [
          {
            plantType: "P",
            plant: "4103",
            plantDescription: "HES Raco, Ontario, CA",
            quantityAvailable: "2131.000",
            street: "1392 Sarah Place, A",
            city: "Ontario",
            region: "CA",
            country: "US",
            postal: "91761",
            nextAtpDate: "20230327",
            nextAtpQty: "1728.000",
            transitQty: "0.000",
          },
        ],
        secondary: [
          {
            plantType: "S",
            plant: "4102",
            plantDescription: "HES Raco RTB, Aurora, IL",
            quantityAvailable: "4117.000",
            street: "945 Corporate BLVD, Ste 101",
            city: "Aurora",
            region: "IL",
            country: "US",
            postal: "60502-9176",
            nextAtpDate: "20230327",
            nextAtpQty: "3472.000",
            transitQty: "0.000",
          },
        ],
        alternative: [],
      },
      pricingAccessMessage: null,
      itemType: "Stock",
      weight: "0.650 LB",
      upc: "050169536100",
      grossWeight: "0.650",
      grossWeightUnitOfMeasure: "LB",
      createdBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      createdOn: "2023-03-03T21:37:27.432Z",
      requestedShipDate: "2023-03-08T21:37:27.432Z",
      referenceOrderNumber: "0121152715",
      consigneePurchaseOrderNumber: "",
      customerPartNumber: "",
      extendedPrice: "544.0000",
      lineNumber: "",
      markings: "",
      modifiedBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      modifiedOn: "2023-03-08T21:37:27.432Z",
      sapOrderNumber: "",
      orderItemId: "",
      orderItemState: {
        orderItemStateId: "",
        name: "",
      },
      orderItemStateNote: "",
    },
    {
      materialNumber: "5361-2",
      customerMaterialNumber: null,
      description: "RND WP BOX (5) 1/2 IN. OUTLETS - BRONZE",
      salesOrg: "C400",
      itemDivision: "4A",
      headerDivision: "4A",
      itemCategory: "NORM",
      poLineNumber: "",
      quantity: 5,
      minimumOrderQty: "1.000",
      baseUnitOfMeasure: "EA",
      standardPackageQty: "1.000",
      palletQty: "1440",
      caseQty: null,
      cartonQty: "16",
      averageLeadTime: "91",
      commodityCode: "7616995190",
      countryOfOrigin: "India",
      quoteGroup: "417",
      price: "896.0000",
      unitPrice: "100",
      listPrice: "3032.6400",
      tradePrice: "0.0000",
      priceUnit: "100",
      currency: "USD",
      returnable: true,
      inventory: {
        primary: [
          {
            plantType: "P",
            plant: "4103",
            plantDescription: "HES Raco, Ontario, CA",
            quantityAvailable: "1676.000",
            street: "1392 Sarah Place, A",
            city: "Ontario",
            region: "CA",
            country: "US",
            postal: "91761",
            nextAtpDate: "20230327",
            nextAtpQty: "560.000",
            transitQty: "0.000",
          },
        ],
        secondary: [
          {
            plantType: "S",
            plant: "4102",
            plantDescription: "HES Raco RTB, Aurora, IL",
            quantityAvailable: "10383.000",
            street: "945 Corporate BLVD, Ste 101",
            city: "Aurora",
            region: "IL",
            country: "US",
            postal: "60502-9176",
            nextAtpDate: "20230508",
            nextAtpQty: "560.000",
            transitQty: "0.000",
          },
        ],
        alternative: [
          {
            plantType: "A",
            plant: "4106",
            plantDescription: "HES Raco, Clifton, NJ",
            quantityAvailable: "2168.000",
            street: "380 Allwood Road",
            city: "Clifton",
            region: "NJ",
            country: "US",
            postal: "07012",
            nextAtpDate: "20230403",
            nextAtpQty: "0.000",
            transitQty: "0.000",
          },
        ],
      },
      pricingAccessMessage: null,
      itemType: "Stock",
      weight: "0.573 LB",
      upc: "050169536124",
      grossWeight: "0.573",
      grossWeightUnitOfMeasure: "LB",
      createdBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      createdOn: "2023-03-03T21:37:27.432Z",
      requestedShipDate: "2023-03-08T21:37:27.432Z",
      referenceOrderNumber: "0121152715",
      consigneePurchaseOrderNumber: "",
      customerPartNumber: "",
      extendedPrice: "896.0000",
      lineNumber: "",
      markings: "",
      modifiedBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      modifiedOn: "2023-03-08T21:37:27.432Z",
      sapOrderNumber: "",
      orderItemId: "",
      orderItemState: {
        orderItemStateId: "",
        name: "",
      },
      orderItemStateNote: "",
    },
    {
      materialNumber: "MM2420C",
      customerMaterialNumber: null,
      description: "2G WP EXT DTY 55IN1 WIU CVR 2-3/4 DP CLR",
      salesOrg: "C400",
      itemDivision: "4A",
      headerDivision: "4A",
      poLineNumber: "",
      quantity: 5,
      itemCategory: "NORM",
      minimumOrderQty: "1.000",
      baseUnitOfMeasure: "EA",
      standardPackageQty: "1.000",
      palletQty: "576",
      caseQty: null,
      cartonQty: "6",
      averageLeadTime: "91",
      commodityCode: "3925900000",
      countryOfOrigin: "United States",
      quoteGroup: "417",
      price: "1647.0000",
      unitPrice: "100",
      listPrice: "7661.4400",
      tradePrice: "0.0000",
      priceUnit: "100",
      currency: "USD",
      returnable: true,
      inventory: {
        primary: [
          {
            plantType: "P",
            plant: "4115",
            plantDescription: "HES Freeport Logistics, Phoeni",
            quantityAvailable: "35854.000",
            street: "4625 N 45th Ave",
            city: "Phoenix",
            region: "AZ",
            country: "US",
            postal: "85031-1505",
            nextAtpDate: "20230501",
            nextAtpQty: "19584.000",
            transitQty: "0.000",
          },
        ],
        secondary: [],
        alternative: [
          {
            plantType: "A",
            plant: "4106",
            plantDescription: "HES Raco, Clifton, NJ",
            quantityAvailable: "0.000",
            street: "380 Allwood Road",
            city: "Clifton",
            region: "NJ",
            country: "US",
            postal: "07012",
            nextAtpDate: "20230315",
            nextAtpQty: "1129.000",
            transitQty: "1152.000",
          },
        ],
      },
      pricingAccessMessage: null,
      itemType: "Stock",
      weight: "0.700 LB",
      upc: "050169917305",
      grossWeight: "0.700",
      grossWeightUnitOfMeasure: "LB",
      createdBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      createdOn: "2023-03-03T21:37:27.432Z",
      requestedShipDate: "2023-03-08T21:37:27.432Z",
      referenceOrderNumber: "0121152715",
      consigneePurchaseOrderNumber: "",
      customerPartNumber: "",
      extendedPrice: "1647.0000",
      lineNumber: "",
      markings: "",
      modifiedBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      modifiedOn: "2023-03-08T21:37:27.432Z",
      sapOrderNumber: "",
      orderItemId: "",
      orderItemState: {
        orderItemStateId: "",
        name: "",
      },
      orderItemStateNote: "",
    },
    {
      materialNumber: "MM420C",
      customerMaterialNumber: null,
      description: "1G WP EXT DTY 16IN1 WIU CVR 2-3/4 DP CLR",
      salesOrg: "C400",
      itemDivision: "4A",
      headerDivision: "4A",
      itemCategory: "NORM",
      poLineNumber: "",
      quantity: 5,
      minimumOrderQty: "1.000",
      baseUnitOfMeasure: "EA",
      standardPackageQty: "1.000",
      palletQty: "768",
      caseQty: null,
      cartonQty: "8",
      averageLeadTime: "63",
      commodityCode: "3925900000",
      countryOfOrigin: "Mexico",
      quoteGroup: "417",
      price: "843.0000",
      unitPrice: "100",
      listPrice: "6359.6000",
      tradePrice: "0.0000",
      priceUnit: "100",
      currency: "USD",
      returnable: true,
      inventory: {
        primary: [
          {
            plantType: "P",
            plant: "4115",
            plantDescription: "HES Freeport Logistics, Phoeni",
            quantityAvailable: "183431.000",
            street: "4625 N 45th Ave",
            city: "Phoenix",
            region: "AZ",
            country: "US",
            postal: "85031-1505",
            nextAtpDate: "20230317",
            nextAtpQty: "50600.000",
            transitQty: "0.000",
          },
        ],
        secondary: [],
        alternative: [],
      },
      pricingAccessMessage: null,
      itemType: "Stock",
      weight: "0.531 LB",
      upc: "050169917275",
      grossWeight: "0.531",
      grossWeightUnitOfMeasure: "LB",
      createdBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      createdOn: "2023-03-03T21:37:27.432Z",
      requestedShipDate: "2023-03-08T21:37:27.432Z",
      referenceOrderNumber: "0121152715",
      consigneePurchaseOrderNumber: "",
      customerPartNumber: "",
      extendedPrice: "843.0000",
      lineNumber: "",
      markings: "",
      modifiedBy: {
        userId: "1",
        userName: "jpadgett@hubbell.com",
        name: "Jason Padgett",
      },
      modifiedOn: "2023-03-08T21:37:27.432Z",
      sapOrderNumber: "",
      orderItemId: "",
      orderItemState: {
        orderItemStateId: "",
        name: "",
      },
      orderItemStateNote: "",
    },
  ],
  details: [
    {
      orderDetailId: "74c963ae-7da6-4460-8572-27cee8dc5afe",
      salesOrg: "C400",
      division: "7A",
      purchaseOrderNumber: "TESTPO0001",
      purchaseOrderDate: "03/08/2023",
      Rush: "true",
      freightTerms: {
        freightTermsId: "19501383-FA8B-4558-8E55-A17C07958247",
        name: "3rd Party",
        description: "",
        value: "ZOB",
      },
      freightAccountNumber: "172389171",
      freightThirdPartyAddress: {
        orderAddressId: "9cec172e-153a-497f-99a8-9105692dcedd",
        orderAddressType: {
          orderAddressTypeId: "5EDCC06C-CC9E-4D19-96EB-6E2D9746075B",
          name: "Freight-Third-Party",
        },
        name1: "FEDEX",
        name2: "",
        name3: "",
        address1: "",
        address2: "",
        city: "",
        region: "",
        postal: "",
        country: "US",
      },
      carrierPreference: "500033",
      requestedShipDate: "01/13/2023",
      holdForReview: "false",
      shipComplete: "false",
      destinationCountry: "US",
      notes: "",
      carrierInstructions: "",
      specialInstructions: "",
      markings: "",
      internalOrderNotes: "",
      agentNotes: "",
    },
  ],
};
