import { useLayoutEffect, useState } from "react";
import { store } from "../..";

export const getState = () => store.getState()["cart"];

export const getUserProfileState = () => store.getState()["userProfile"];

const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};

export const useCart = () => {
  const [state, setState] = useState(getState());
  useLayoutEffect(() => subscribe(setState), [setState]);
  return state;
};
