import { store } from "index";

export const selectSystemCurrentAccountNumber = () =>
  store.getState().userProfile.currentAccount?.accountNumber;

export const selectSystemCurrentAccount = () =>
  store.getState().userProfile.currentAccount;

export const selectSystemUserDefaultAccountNumber = () =>
  store.getState().userProfile.defaultAccount?.accountNumber;

export const selectSystemUserEmail = () =>
  store.getState().userProfile.userName;

export const selectSystemUserFeatures = () =>
  store.getState().system.userModules;

export const selectSystemUserIdentityId = () =>
  store.getState().userProfile.identityApiId;

export const selectSystemFeatures = () => store.getState().system.modules;

export const selectSystemUserAuthorizedFeatures = () => {
  const userFeatures = selectSystemUserFeatures();
  const systemFeatures = selectSystemFeatures();
  return (
    systemFeatures?.filter(
      (f) =>
        userFeatures?.find((u) => u.functionModuleId === f.functionModuleId) !==
        undefined
    ) || []
  );
};
