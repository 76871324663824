import InventoryEntry from "../models/InventoryItem";
import { MaterialDataQueryItemInterface } from "../models/MaterialDataQueryItem";
import MaterialInventory from "../models/MaterialInventory";
import {
  isCatalogNumberLookupResult,
  isCustomerMaterialLookupResult,
  isMaterialLookupResult,
  isMaterialSearchResult,
} from "../models/MaterialLookup";
import {
  MaterialPriceAndAvailabilityResult,
  MaterialResult,
  ServiceError,
  SkippedMaterial,
} from "../models/MaterialPriceAndAvailabilityResult";
import { AccountBrands, Brand } from "../models/UserAccount";
import { mapSystemStateToProps, SystemState } from "../store/systemStore";
import { findBrandData } from "./brandHelper";
import { formatBrandId } from "./formatHelper";
import { isPermittedBrand } from "./validationHelper";

const priceAndAvailabilityTesterFeatureFlag =
  process.env.REACT_APP_PRICEANDAVAILABILITY_FLAG;

// less passing of larger objects
// OPTIMIZE: looping and message processing should probably be done server side
export function transformErrorMessaging(
  data: MaterialPriceAndAvailabilityResult,
  allBrands: Brand[] = [],
  accountData?: AccountBrands
) {
  // check for product extension errors and transform to human readable text
  // need to review; possibly create a helper
  let materialNumberHash: {
    [materialNumber: string]: {
      message: string;
      originalMessage: string;
    };
  } = {};
  const getMaterialMessage = (
    salesOrg: string,
    division: string,
    account: string,
    materialNumber: string
  ): string => {
    let message = `Material brand (${salesOrg}/${division}) not authorized for account ${account}.`;
    let brandId = formatBrandId({ salesOrg, division });
    let brand = allBrands?.find((x) => formatBrandId(x) === brandId);
    if (brand?.name) {
      message = `Material brand (${brand.name}) not authorized for account ${account}.`;
    } else {
      console.error(
        `Unable to find ${brandId} for MaterialNumber ${materialNumber}`
      );
    }
    return message;
  };
  data.serviceErrorsReturned?.forEach((e: ServiceError) => {
    // SAP brand permission error
    if (e.code === "VP197") {
      const salesOrg = e?.messageVars[1];
      const division = e?.messageVars[3];
      const account = e?.messageVars[0];
      const originalMessage = e?.text;
      materialNumberHash[e.materialNumber] = {
        message: getMaterialMessage(
          salesOrg,
          division,
          account,
          e.materialNumber
        ),
        originalMessage,
      };
    }
  });
  // we're actually transforming the skipped material message, but the service error has the necessary information for formulate the message
  data.skippedMaterials?.forEach((s: SkippedMaterial) => {
    if (s.materialNumber && materialNumberHash[s.materialNumber]) {
      let m = materialNumberHash[s.materialNumber];
      s.evaluationResults?.forEach((ev: any) => {
        if (ev.message === m.originalMessage) {
          ev.message = m.message;
        }
      });
    }
  });
  // Application brand permission error
  if (
    data?.materialResults?.length > 0 &&
    accountData
  ) {
    data.materialResults?.forEach((e: MaterialResult) => {
      const salesOrg = e.salesOrg || "";
      const division = e.headerDivision || "";
      if (!isPermittedBrand(salesOrg, division, accountData.brands)) {
        let message = getMaterialMessage(
          salesOrg,
          division,
          accountData.accountNumber,
          e.materialNumber || ""
        );
        data.skippedMaterials?.push({
          materialNumber: e.materialNumber,
          evaluationResults: [
            {
              message: message,
            },
          ],
        } as SkippedMaterial);
      }
    });
  }
}

export function mapSalesOrgDivisionToBrandName(
  data: MaterialPriceAndAvailabilityResult
) {
  data?.materialResults?.forEach((i: MaterialResult) => {
    if (i.salesOrg && i.headerDivision) {
      findBrandData(i.salesOrg, i.headerDivision)
        .then((r) => {
          if (r) {
            i.brand = r.name;
          }
        })
        .catch((e) => console.error(e));
    }
  });
}

export const checkIfMaterialFound = (item: MaterialDataQueryItemInterface) => {
  return (
    item?.materialNumberSuggestion?.isExists === true
  );
};

export const checkIfCatalogNumberFound = (
  item: MaterialDataQueryItemInterface
) => {
  return (
    item.catalogNumberSuggestions &&
    item.catalogNumberSuggestions.length > 0
  );
};

export const checkIfCMIRFound = (item: MaterialDataQueryItemInterface) => {
  return (
    item.customerMaterialNumberSuggestion &&
    item.customerMaterialNumberSuggestion.materialNumber
  );
};

export const checkIfMaterialSearchFound = (
  item: MaterialDataQueryItemInterface
) => {
  return (
    item.materialSearchSuggestions &&
    item.materialSearchSuggestions.length > 0
  );
};

export const checkIfMaterialEntryFound = (
  item: MaterialDataQueryItemInterface
) => {
  const materialFound = checkIfMaterialFound(item);
  const catalogNumFound = checkIfCatalogNumberFound(item);
  const CMIRFound = checkIfCMIRFound(item);
  const materialSearchFound = checkIfMaterialSearchFound(item);

  let test = 0;
  test = materialFound ? test + 1 : test;
  test = catalogNumFound ? test + 1 : test;
  test = CMIRFound ? test + 1 : test;
  test = materialSearchFound ? test + 1 : test;
  return test > 0;
};

export const checkIfMultipleMaterialEntriesFound = (
  item: MaterialDataQueryItemInterface
) => {
  const materialFound = checkIfMaterialFound(item);
  const catalogNumFound = checkIfCatalogNumberFound(item);
  const CMIRFound = checkIfCMIRFound(item);
  const materialSearchFound = checkIfMaterialSearchFound(item);

  let test = 0;
  test = materialFound ? test + 1 : test;
  test = catalogNumFound ? test + item.catalogNumberSuggestions!.length : test;
  test = CMIRFound ? test + 1 : test;
  test = materialSearchFound
    ? test + item.materialSearchSuggestions!.length
    : test;
  return test > 1 ? true : false;
};

export const getLookupResultMaterialNumberValue = (x: any) => {
  let materialNumber = "";

  if (isCatalogNumberLookupResult(x)) {
    materialNumber = x.manufacturerNumber;
  }
  if (isCustomerMaterialLookupResult(x) && x.materialNumber) {
    materialNumber = x.materialNumber;
  }
  if (isMaterialLookupResult(x)) {
    materialNumber = x.materialNumber;
  }
  if (isMaterialSearchResult(x)) {
    materialNumber = x.materialNumber;
  }
  return materialNumber;
};

export const calculateTotalMaterialInventory = (
  inventory: MaterialInventory
): number => {
  let total = 0;

  Object.values(inventory)
    .flat()
    .forEach((i: InventoryEntry) => {
      if (!isNaN(i.quantityAvailable))
        total = total + Number(i.quantityAvailable);
    });
  return total;
};

export const determineReturnableContent = (
  salesOrg: string,
  returnable: boolean
) => {
  const brands = ["C610", "C630", "C640"];
  const message = "Contact Customer Service";
  return brands.find((i) => i === salesOrg?.toUpperCase()) !== undefined
    ? message
    : returnable === true
    ? "yes"
    : "no";
};

export const isPriceAndAvailabilityTester = (system: SystemState) => {
  return (
    mapSystemStateToProps(system).findUserModule(
      priceAndAvailabilityTesterFeatureFlag || ""
    ) !== undefined
  );
};
