export interface Inventory {
  location: string;
  quantity: number;
}

export interface ResourceItem {
  filename: string;
  url: string;
  thumbnail?: string;
  medium?: string;
  large?: string;
}

export interface Resource {
  type: string;
  items: Array<ResourceItem>;
}

export interface Feature {
  type: string;
  attributes: { [key: string]: string };
}

export enum ProductImageFormats {
  Product = "product",
  Zoom = "zoom",
  Thumb = "thumnail"
}

export enum ProductImageTypes {
  Primary = "PRIMARY",
  Gallery = "GALLERY"
}

export interface ProductImage {
  altText: string;
  format: ProductImageFormats;
  imageType: ProductImageTypes;
  url: string;
}
/*export interface Product {
  catalogNumber: string;
  name: string;
  description: string;
  manufacturerNumber: string;
  salesOrg: string;
  price: { msrp: number; cost: number };
  inventory: Array<Inventory>;
  details?: { [key: string]: string };
  features?: Array<Feature>;
  resources?: Array<Resource>;
}
*/
