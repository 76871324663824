import { SalesOrganizationInterface } from "../models/SalesOrganization";
import ApiService, { ApiCancelTokenSource } from "./ApiService";

const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;
const identityAPI_Base = process.env.REACT_APP_IDENTITYAPI_BASE;

class SalesOrganizationService extends ApiService {
  protected static data: SalesOrganizationInterface[];

  async getAll(accessToken?: string): Promise<SalesOrganizationInterface[]> {
    if (!SalesOrganizationService.data) {
      let cancelTokenSource: ApiCancelTokenSource = this.generateSourceToken();

      let token = accessToken || (await this.authorize());
      const headers: { [key: string]: any } = {};
      if (coreAPIM_Header) {
        headers[coreAPIM_Header] = coreAPIM_Key;
        headers["Authorization"] = `Bearer ${token}`;
      }
      const params = {
        limit: 100,
      };

      let result = await this.connection.get("brands", {
        baseURL: identityAPI_Base,
        params: params,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      });

      SalesOrganizationService.data = result.data
        .brands as Array<SalesOrganizationInterface>;
    }

    return Promise.resolve(SalesOrganizationService.data);
  }

  async getParentBrands(
    accessToken?: string
  ): Promise<SalesOrganizationInterface[]> {
    let cancelTokenSource: ApiCancelTokenSource = this.generateSourceToken();

    let token = accessToken || (await this.authorize());
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const params = {
      level: 1,
      limit: 100,
      country: "US",
    };

    let result = await this.connection.get("brands", {
      baseURL: identityAPI_Base,
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });

    let parentBrandData = result.data
      .brands as Array<SalesOrganizationInterface>;

    return Promise.resolve(parentBrandData);
  }

  async get(salesOrg: string, division: string) {
    if (!SalesOrganizationService.data) {
      SalesOrganizationService.data = await this.getAll();
    }

    return Promise.resolve(
      SalesOrganizationService.data.find(
        (i: SalesOrganizationInterface) =>
          i.salesOrg.trim() === salesOrg.trim() &&
          i.division.trim() === division.trim()
      )
    );
  }
}
export default SalesOrganizationService;
