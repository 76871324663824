import { AxiosResponse } from "axios";
// import moment from "moment";
import { format } from "date-fns";
import { ErrorResponse } from "../models/Error";
import { Document, ResponseData } from "../services/ApiServiceInterfaces";

// eslint-disable-next-line
export class FileHelper<T> {
  getFileDataFromHeader = (id: string, result: any): Document | undefined => {
    let fileName = "";
    if (result.headers["content-disposition"]) {
      fileName = result.headers["content-disposition"]
        .split(";")[1]
        .replace(" filename=", "");
    } else if (result.headers["content-type"]) {
      if (result.headers["content-type"].startsWith("application/") === true) {
        let fileExtension = result.headers["content-type"].split("/")[1];
        // let timeStamp = moment().format("YYYYMMDDhhmmss");
        let timeStamp = format(new Date(), "yyyyMMddhhmmss");
        fileName = `Order${id}_Documents_${timeStamp}.${fileExtension}`;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
    return {
      fileName: fileName,
      fileData: new Blob([result.data]),
    };
  };

  readBlobAsText = async (result: any): Promise<string> => {
    var fileContent = new FileReader();
    fileContent.readAsText(result.data);

    return new Promise((resolve, reject) => {
      fileContent.onload = () => {
        let resultText = fileContent.result as string;
        resolve(resultText);
      };
    });
  };

  statusCodeToText = (code: number): ErrorResponse => {
    switch (code) {
      case 401:
        return {
          Error: code.toString(),
          Message: "Unauthorized",
          Detail: "Unable to access resource.",
        };
      case 404:
        return {
          Error: code.toString(),
          Message: "Not found",
          Detail: "Unable to access resource.",
        };
      case 500:
      case 503:
      default:
        return {
          Error: code.toString(),
          Message: "Error",
          Detail: "Please contact the site administrator.",
        };
    }
  };

  parseDownloadApiResponse = async (
    id: string,
    result: AxiosResponse
  ): Promise<ResponseData<Document>> => {
    let downloadResult: ResponseData<Document> = {
      Error: {
        Error: "",
        Message: "",
        Detail: "",
      },
    };
    if (result.status === 200) {
      let orderDoc = this.getFileDataFromHeader(id, result);
      if (orderDoc !== undefined) {
        downloadResult.Results = orderDoc;
      }
    }

    if (downloadResult?.Results) {
      if (
        (result.headers["content-length"] &&
          parseInt(result.headers["content-length"]) > 0) ||
        result.headers["content-type"]
      ) {
        //  server provides friendly error
        let resultText = undefined;
        try {
          resultText = await this.readBlobAsText(result).then((resultText) => {
            return resultText;
          });
          let error: { statusCode: number; message: string } =
            JSON.parse(resultText);
          downloadResult.Error = this.statusCodeToText(error.statusCode);
        } catch (e) {
          if (resultText) {
            downloadResult.Error.Message = "Alert";
            downloadResult.Error.Detail = resultText;
          } else {
            console.log(e);
            throw new Error("Unknown error.");
          }
        }
      } else {
        // unhandled error
        throw new Error(result.statusText);
      }
    }
    return downloadResult;
  };
}
