export interface MaterialLookupResult {
  materialNumber: string;
  type: string | null;
  message: string | null;
  isExists: boolean;
  messageCode: string | null;
}
export interface MaterialNumberLookupResult{
  materialNumber : string;
  type: string | null;
  message: string | null;
  isExists: boolean;
  messageCode: string | null;
}
export interface SalesOrgLookupResult{
  salesOrg : string
  type: string | null;
  message: string | null;
  isExists: boolean;
  messageCode: string | null;
}
export interface DivisionLookupResult{
  division :string;
  message: string | null;
  isExists: boolean;
  messageCode: string | null;
}

export const isMaterialLookupResult = (x: any): x is MaterialLookupResult =>
  typeof x === "object" && "isExists" in x;

export interface CatalogNumberLookupResult {
  catalogNumber: string;
  brand: {
    salesOrg: string;
    division: string;
  };
  description: string;
  manufacturerNumber: string;
}

export const isCatalogNumberLookupResult = (
  x: any
): x is CatalogNumberLookupResult =>
  typeof x === "object" && "manufacturerNumber" in x;

export interface CustomerMaterialLookupResult {
  materialNumber: string | null;
  customerMaterialNumber: string;
  customerNumber: string;
  salesOrg: string | null;
}

export const isCustomerMaterialLookupResult = (
  x: any
): x is CustomerMaterialLookupResult =>
  typeof x === "object" && "customerMaterialNumber" in x;

export interface MaterialSearchResult {
  materialNumber: string;
  materialDescription: string;
  salesOrg: string;
  division: string;
  brandName: string;
  PIMIndicator: string;
}
export const isMaterialSearchResult = (x: any): x is MaterialSearchResult =>
  typeof x === "object" && "PIMIndicator" in x;
