import {
  CustomerOrderSearchByDateInterface,
  CustomerOrderSearchByMaterialDateInterface,
  CustomerOrderSearchByPurchaseOrderNumberInterface,
} from "./ApiServiceInterfaces";
import ApiService, { ApiCancelTokenSource } from "./ApiService";
import { Orders, SalesOrder } from "../models/Order";

const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;
const coreAPIM_SRI_PATH = process.env.REACT_APP_SRI_API_BASE;

class CustomerOrderService extends ApiService {
  async Search(
    query:
      | CustomerOrderSearchByDateInterface
      | CustomerOrderSearchByMaterialDateInterface
      | CustomerOrderSearchByPurchaseOrderNumberInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<Orders> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const url = "/salesorder/search";
    let result = await this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      params: query,
      cancelToken: cancelTokenSource?.token,
    });

    let orderData = result.data as Orders;
    orderData.salesOrders = orderData.salesOrders.filter(
      (o: SalesOrder) => o.salesOrderNumber !== null
    );
    return orderData as Orders;
  }
}
export default CustomerOrderService;
