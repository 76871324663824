import HubbellConnectOrder, {
  Address,
  Detail,
  OrderAddressTypeName,
} from "../../models/HubbellConnectOrder";
import { Item } from "../../models/Order";
import { getState, getUserProfileState } from "./useCart";
import {UserAccount as ProfileAddress} from "../../models/UserAccount"

export const selectCartOrder = (): HubbellConnectOrder =>
  getState().orderDraft.data;

export const selectCartSoldToAddress = (): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === OrderAddressTypeName.SoldTo
  );

export const selectCartShippingAddress = (): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === OrderAddressTypeName.ShipTo
  );
export const selectCartBillingAddress = (): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === OrderAddressTypeName.BillTo
  );

export const selectCart3rdPartyFreightAddress = (): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) =>
      a.orderAddressType?.name === OrderAddressTypeName.ThirdPartyFreight
  );

export const  selectCartOrderDetail = (): Detail[] =>
  getState().orderDraft.data?.details;

export const selectCartItems = (): Item[] => getState().orderDraft.data?.items;

export const selectSuggestedAddress = (): Address | undefined => getState().orderDraft.data?.suggestedAddress;

export const selectUserProfileSoldToAddress = (): ProfileAddress | undefined =>
    getUserProfileState().currentAccount

export const selectOrderId = () : string | undefined => 
    getState().orderDraft.data?.orderId

export const selectOrderAddressId = (addressName: string) : string | undefined => {  
  let addressId = getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === addressName
  );
  return addressId?.orderAddressId
}

export const selectOrderAddressTypeId = (addressName: string) : string | undefined => {  
  let addressId = getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === addressName
  );
  return addressId?.orderAddressType?.orderAddressTypeId || null;
}

export const selectOrderDetailId = () : string | undefined => 
    getState().orderDraft.data?.details[0]?.orderDetailId