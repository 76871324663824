export interface CustomerQuoteSearchByDateInterface {
  accountNumber: string;
  startDate: string; // YYYYMMDD
  endDate: string; // YYYYMMDD
}

export interface CustomerQuoteSearchByMaterialDateInterface
  extends CustomerQuoteSearchByDateInterface {
  materialNumber: string;
  projectName: string;
  quoteType: string;
}

export interface PDPQuoteSearchByMaterial
  extends CustomerQuoteSearchByDateInterface {
  projectName: string;
  quoteType: string;
}

export interface CustomerQuoteSearchByPurchaseOrderNumberInterface {
  quoteNumber: string;
  accountNumber: string;
}

export enum CustomerQuoteSearchMode {
  ALL = "all",
  ACTIVE = "active",
  EXPIRED = "expired",
  FUTURE = "future",
  EXPIRING_IN = "expiringIn",
}

export interface CustomerQuoteSearchInterface {
  createdFromDate?: string; //yyyyMMdd
  createdToDate?: string; //yyyyMMdd
  mode?: CustomerQuoteSearchMode;
  expiringInDays?: number; // number between 1-120 days
  quoteNumber?: string;
  projectName?: string;
  quoteType?: string;
  productNumber?: string;
  accountNumber: string;
}
