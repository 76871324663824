import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import * as React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import classNames from "classnames";

export interface ActionButtonProps extends ButtonProps {
  icon?: IconDefinition;
  description?: string;
}

const ActionButton: FC<ActionButtonProps> = ({
  icon,
  description,
  children,
  className,
  ...props
}): JSX.Element => {
  return (
    <Button
      role="button"
      variant="link"
      className={classNames("actionBtn", className)}
      {...props}
    >
      {icon && <FontAwesomeIcon icon={icon} fixedWidth title={description} />}
      {description && <span className="sr-only">{description}</span>}
      {children}
    </Button>
  );
};
export default ActionButton;
