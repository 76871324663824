import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import actionCreatorFactory, {
  ActionCreator,
  AsyncActionCreators,
  Failure,
  Success,
} from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  MaterialDataQueryItemFactory,
  MaterialDataQueryItemInterface,
  MaterialDataQueryItemStatus,
  MaterialLookupSuggestion,
  validateMaterialNumber,
} from "../models/MaterialDataQueryItem";
import { MaterialDetailResult } from "../models/MaterialDetailResult";
import { MaterialPriceAndAvailabilityResult } from "../models/MaterialPriceAndAvailabilityResult";
import { Brand, SalesOrgDivision, UserAccount } from "../models/UserAccount";
import { ApiCall, ApiCancelTokenSource } from "../services/ApiService";
import MaterialDataService from "../services/MaterialDataService";
import {
  getLookupResultMaterialNumberValue,
  mapSalesOrgDivisionToBrandName,
  transformErrorMessaging,
} from "../util/materialPriceAndAvailabilityDataHelper";
import { AppState } from "./app";
import { wrapAsyncWorker, wrapWorker } from "./helpers";
import {
  SavedTableFilterSettings,
  SavedTablePagingSettings,
  SavedTableSettings,
} from "../models/Table";
import { ErrorResponse } from "../models/Error";
import { isMaterialSearchResult } from "../models/MaterialLookup";

const PriceAvailabilityTableName = "PriceAvailabilityTable";
export const MAX_QUERY_LIMIT = 500;

export enum PriceAndAvailabilityView {
  entry = "ENTRY",
  results = "RESULTS",
}

export enum CancelReasons {
  USER_CANCELED = "user_canceled",
  SYSTEM_CANCELED = "system_canceled",
}

export interface MaterialWithDetails {
  material: MaterialPriceAndAvailabilityResult | undefined;
  details?: MaterialDetailResult | null;
}

export interface PriceAndAvailabilityStore {
  userIsTestingNewPriceService: boolean | undefined;
  queryItems: Array<MaterialDataQueryItemInterface>;
  view: PriceAndAvailabilityView;
  priceDate: Date | undefined;
  queryAccountNumber: string | undefined;
  tableSettings: SavedTableSettings;
}

interface MaterialPriceAndAvailabilityParams {
  materialNumber: string;
  quantity: number;
  accountNumber: string;
  accountBrands: SalesOrgDivision[];
  priceDate: Date;
  useNew: boolean;
  salesOrg?: string;
  division?: string;
}

export interface MaterialLookupQueryParams {
  id: string;
  value: string;
  account: UserAccount | undefined;
  userIsTestingNewPriceService: boolean;
  call?: ApiCall<MaterialLookupSuggestion>;
  cancel?: ApiCancelTokenSource;
}

export interface QueryItemParams extends MaterialPriceAndAvailabilityParams {
  id: string;
  brands?: Brand[];
  call?: ApiCall<AxiosResponse<MaterialPriceAndAvailabilityResult>>;
  getMaterialDetail?: boolean;
}

export interface CancelQueryItemParams {
  id: string;
  reason?: CancelReasons;
}

function getMaterialPriceAndAvailability(
  params: MaterialPriceAndAvailabilityParams
): ApiCall<AxiosResponse<MaterialPriceAndAvailabilityResult>> {
  const service = new MaterialDataService();
  const token = service.generateSourceToken();
  return {
    query: service.priceAndAvailability(
      {
        item: {
          materialNumber: params.materialNumber,
          salesOrg: params.salesOrg,
          division: params.division,
          quantity: params.quantity,
        },
        accountNumber: params.accountNumber,
        cancelTokenSource: token,
        priceDate: params.priceDate,
      },
      params.useNew
    ) as Promise<AxiosResponse<MaterialPriceAndAvailabilityResult>>,
    token,
  };
}

const getMaterialDetail = (
  materialNumber: string,
  salesOrg: string,
  division: string,
  token?: ApiCancelTokenSource
): ApiCall<MaterialDetailResult | undefined> => {
  const service = new MaterialDataService();
  if (!token) {
    token = service.generateSourceToken();
  }
  const query = service.productDetail(
    { materialNumber: materialNumber, salesOrg: salesOrg, division: division },
    token
  );
  return {
    query,
    token,
  };
};

const getMaterialLookup = (
  params: MaterialLookupQueryParams
): ApiCall<MaterialLookupSuggestion> => {
  const result: Partial<MaterialLookupSuggestion> = {};
  const service = new MaterialDataService();
  const cancelTokenSource = service.generateSourceToken();

  return {
    query: new Promise<MaterialLookupSuggestion>((resolve, reject) => {
      if (params?.account && params.value) {
        const calls: Promise<AxiosResponse<any>>[] = [];
        // CMIR
        calls.push(
          service.customerMaterialNumberLookup({
            value: params.value,
            accountNumber: (params.account as UserAccount).accountNumber,
            cancelTokenSource,
          })
        );
        if (params.userIsTestingNewPriceService) {
          // SHARED PART SEARCH
          calls.push(
            service.materialSearch({
              value: params.value,
              accountNumber: (params.account as UserAccount).accountNumber,
              cancelTokenSource,
            })
          );
        } else {
          // CATALOG NUMBER SEARCH
          calls.push(
            service.catalogNumberLookup({
              value: params.value,
              cancelTokenSource,
            })
          );
          // MATERIAL EXISTS SEARCH
          calls.push(
            service.materialNumberLookup({
              value: params.value,
              cancelTokenSource,
            })
          );
        }
        Promise.all(calls)
          .then((values) => {
            result.customerMaterialNumberSuggestion = values[0].data;
            if (params.userIsTestingNewPriceService) {
              result.materialSearchSuggestions = values[1].data;
            } else {
              result.catalogNumberSuggestions = values[1].data;
              result.materialNumberSuggestion = values[2].data;
            }
            resolve(result as MaterialLookupSuggestion);
          })
          .catch((e) => reject(e));
        /*
        service
          .materialSearch({
            value: params.value,
            accountNumber: (params.account as UserAccount).accountNumber,
            cancelTokenSource,
          }) 
          .catalogNumberLookup({
            value: params.value,
            cancelTokenSource,
          })
          .then((catalogLookupData) => {
            result.catalogNumberSuggestions = catalogLookupData.data;
            return service.materialNumberLookup({
              value: params.value,
              cancelTokenSource,
            });
          }) 
          .then((materialSearchResult) => {
            result.materialSearchSuggestions = materialSearchResult.data;
            return service.customerMaterialNumberLookup({
              value: params.value,
              accountNumber: (params.account as UserAccount).accountNumber,
              cancelTokenSource,
            });
          })
          .then((customerMaterialLookupData) => {
            result.customerMaterialNumberSuggestion =
              customerMaterialLookupData.data;
            resolve(result as MaterialLookupSuggestion);
          })
          .catch((e: ErrorResponse) => {
            reject(e);
          });
          */
      }
    }),
    token: cancelTokenSource,
  };
};

export const initialPriceAndAvailabilityTableSettings: SavedTableSettings = {
  tableId: PriceAvailabilityTableName,
  paging: {
    pageIndex: 0,
    pageSize: 10,
  },
  filters: { filters: [] },
  selectedIds: {},
  sortBy: [],
};

export const initialState: PriceAndAvailabilityStore = {
  userIsTestingNewPriceService: undefined,
  queryItems: [],
  view: PriceAndAvailabilityView.entry,
  priceDate: new Date(),
  queryAccountNumber: undefined,
  tableSettings: initialPriceAndAvailabilityTableSettings,
};

const ac = actionCreatorFactory("priceAndAvailability");

const ADD_QUERY_ITEM: ActionCreator<MaterialDataQueryItemInterface> =
  ac<MaterialDataQueryItemInterface>("addQueryItem");
const REMOVE_QUERY_ITEM: ActionCreator<MaterialDataQueryItemInterface> =
  ac<MaterialDataQueryItemInterface>("removeQueryItem");
const UPDATE_QUERY_ITEM: ActionCreator<MaterialDataQueryItemInterface> =
  ac<MaterialDataQueryItemInterface>("updateQueryItem");

const UPDATE_QUERY_ITEM_QTY: ActionCreator<{ id: string; qty: number }> = ac<{
  id: string;
  qty: number;
}>("updateQueryItemQty");
const UPDATE_QUERY_ITEM_VALUE: ActionCreator<{ id: string; value: string }> =
  ac<{ id: string; value: string }>("updateQueryItemValue");
const UPDATE_QUERY_ITEMS_ARRAY: ActionCreator<
  MaterialDataQueryItemInterface[]
> = ac<MaterialDataQueryItemInterface[]>("updateQueryItemsArray");

const CLEAR_QUERY_DATA = ac<void>("clearQueryData"); // @deprecate?

const CHECK_FOR_STALE_QUERY_DATA: ActionCreator<UserAccount["accountNumber"]> =
  ac<UserAccount["accountNumber"]>("checkForStaleQueryData");
const SET_PRICE_DATE: ActionCreator<Date | undefined> = ac<Date | undefined>(
  "setPriceDate"
);
const SET_ISUSERTESTINGNEWPRICINGSERVICE = ac<boolean>(
  "set_isusertestingnewpricingservice"
);

const SET_VIEW: ActionCreator<PriceAndAvailabilityView> =
  ac<PriceAndAvailabilityView>("setView");
const STOP_ALL = ac<boolean | undefined>("stop_all");

const EXECUTE_QUERY_ITEM: AsyncActionCreators<
  QueryItemParams | undefined,
  MaterialWithDetails | undefined,
  Error | undefined
> = ac.async<
  QueryItemParams | undefined,
  MaterialWithDetails | undefined,
  Error | undefined
>("execute_query_item");
const RETRY_QUERY_ITEM = ac<Partial<QueryItemParams> | undefined>(
  "retry_query"
);
const CANCEL_QUERY_ITEM = ac<CancelQueryItemParams | undefined>("cancel_query");
const CLEAR_EMPTY_QUERIES = ac("clear_empty_queries");

const SET_QUERY_RESULTS_TABLEPAGING: ActionCreator<SavedTablePagingSettings> =
  ac<SavedTablePagingSettings>("set_query_results_tablepaging");
const SET_QUERY_RESULTS_TABLEFILTERS: ActionCreator<SavedTableFilterSettings> =
  ac<SavedTableFilterSettings>("set_query_results_tablefilters");
const SET_QUERY_RESULTS_TABLESELECTEDROWS: ActionCreator<
  Record<string, boolean>
> = ac<Record<string, boolean>>("set_query_results_tableselectedrows");
const SET_SEARCH_RESULTS_TABLESORTBY = ac<
  Array<{ id: string; desc?: boolean }>
>("set_search_results_tablesortby");

const MATERIAL_LOOKUP = ac.async<
  MaterialLookupQueryParams | undefined,
  MaterialLookupSuggestion | undefined,
  ErrorResponse | undefined
>("material_lookup");
const CANCEL_MATERIAL_LOOKUP = ac<string>("cancel_material_lookup");
const CANCEL_MATERIAL_LOOKUPS = ac<void>("cancel_material_lookups");

// helpers
const accountsAreDifferent = (
  queryItem: MaterialDataQueryItemInterface,
  accountNumber: string
) => {
  return (
    queryItem.materialPriceAndAvailabilityResult?.accountNumber !==
    accountNumber
  );
};

const shouldExecute = (
  queryItem: MaterialDataQueryItemInterface,
  accountNumber: string
) => {
  return (
    (queryItem.materialPriceAndAvailabilityResult === undefined &&
      queryItem.status !== MaterialDataQueryItemStatus.LOADING &&
      (queryItem.status !== MaterialDataQueryItemStatus.ERROR ||
        (queryItem.status === MaterialDataQueryItemStatus.ERROR &&
          queryItem.error?.Error === CancelReasons.SYSTEM_CANCELED))) ||
    (queryItem.materialPriceAndAvailabilityResult?.accountNumber &&
      accountsAreDifferent(queryItem, accountNumber))
  );
};

const materialHasReplacement = (
  data: MaterialPriceAndAvailabilityResult | undefined
) => {
  return (
    data?.skippedMaterials?.find((i) =>
      i.evaluationResults?.find(
        (r) =>
          r.operationName?.toLowerCase() ===
          "ValidateSubstitution".toLowerCase()
      )
    ) !== undefined
  );
};

const materialIsSilentlyReplaced = (
  data: MaterialPriceAndAvailabilityResult | undefined
) => {
  return (
    data?.skippedMaterials?.find((i) =>
      i.evaluationResults?.find(
        (r) =>
          r.operationName?.toLowerCase() ===
            "ValidateSubstitution".toLowerCase() && r.reason === "0002"
      )
    ) !== undefined
  );
};

// thunks
export const executeQueries = (
  priceDate?: Date,
  soldTo?: UserAccount,
  refreshAll?: boolean
) => {
  return (dispatch: ThunkDispatch<any, any, any>, getState: () => AppState) => {
    const { userProfile: user, priceAndAvailability, system } = getState();
    const { queryItems } = priceAndAvailability;
    const account = soldTo || user.currentAccount;

    queryItems.forEach((x) => {
      if (
        account?.accountNumber &&
        (refreshAll || shouldExecute(x, account.accountNumber))
      ) {
        PriceAndAvailabilityDuck.actions.CANCEL_QUERY_ITEM(dispatch, {
          id: x.id,
          reason: CancelReasons.SYSTEM_CANCELED,
        });
        let materialNumberValue = x.value;
        if (x.selectedSuggestion) {
          materialNumberValue = getLookupResultMaterialNumberValue(
            x.selectedSuggestion
          );
        }
        let salesOrg = undefined;
        let division = undefined;
        if (
          x.selectedSuggestion &&
          isMaterialSearchResult(x.selectedSuggestion)
        ) {
          salesOrg = x?.selectedSuggestion?.salesOrg;
          division = x?.selectedSuggestion?.division;
        }
        // execute the query again with new params
        PriceAndAvailabilityDuck.actions.EXECUTE_QUERY_ITEM(dispatch, {
          id: x.id,
          brands: system.brands,
          accountBrands: account.brands || [],
          materialNumber: materialNumberValue?.trim(),
          salesOrg: salesOrg,
          division: division,
          quantity: x.quantity || 1,
          accountNumber: account.accountNumber,
          priceDate: priceDate || new Date(),
          useNew: priceAndAvailability.userIsTestingNewPriceService || false,
          getMaterialDetail: x.materialDetailResult === undefined,
        });
      }
    });
  };
};

export const retryQuery = (params: Partial<QueryItemParams>) => {
  return (dispatch: ThunkDispatch<any, any, any>, getState: () => AppState) => {
    const queryItems = getState().priceAndAvailability.queryItems;
    const i = queryItems.findIndex((x) => x.id === params?.id);
    if (queryItems[i] && queryItems[i].callParams) {
      PriceAndAvailabilityDuck.actions.EXECUTE_QUERY_ITEM(
        dispatch,
        queryItems[i].callParams
      );
    }
  };
};

const actions = {
  ADD_QUERY_ITEM: wrapWorker(
    ADD_QUERY_ITEM,
    (
      dispatch: Dispatch,
      params: MaterialDataQueryItemInterface
    ): MaterialDataQueryItemInterface => {
      return params;
    }
  ),
  REMOVE_QUERY_ITEM: wrapWorker(
    REMOVE_QUERY_ITEM,
    (
      dispatch: Dispatch,
      params: MaterialDataQueryItemInterface
    ): MaterialDataQueryItemInterface => {
      return params;
    }
  ),
  UPDATE_QUERY_ITEM: wrapWorker(
    UPDATE_QUERY_ITEM,
    (
      dispatch: Dispatch,
      params: MaterialDataQueryItemInterface
    ): MaterialDataQueryItemInterface => {
      return params;
    }
  ),
  UPDATE_QUERY_ITEM_QTY: wrapWorker(
    UPDATE_QUERY_ITEM_QTY,
    (
      dispatch: Dispatch,
      params: { id: string; qty: number }
    ): { id: string; qty: number } => {
      return params;
    }
  ),
  UPDATE_QUERY_ITEM_VALUE: wrapWorker(
    UPDATE_QUERY_ITEM_VALUE,
    (
      dispatch: Dispatch,
      params: { id: string; value: string }
    ): { id: string; value: string } => {
      return params;
    }
  ),
  UPDATE_QUERY_ITEMS_ARRAY: wrapWorker(
    UPDATE_QUERY_ITEMS_ARRAY,
    (dispatch: Dispatch, params: MaterialDataQueryItemInterface[]) => {
      return params;
    }
  ),
  CLEAR_QUERY_DATA: wrapWorker(CLEAR_QUERY_DATA, (dispatch: Dispatch) => {
    return;
  }),
  SET_PRICE_DATE: wrapWorker(
    SET_PRICE_DATE,
    (dispatch: Dispatch, params: Date | undefined): Date | undefined => {
      return params;
    }
  ),
  SET_VIEW: wrapWorker(
    SET_VIEW,
    (
      dispatch: Dispatch,
      params: PriceAndAvailabilityView
    ): PriceAndAvailabilityView => {
      return params;
    }
  ),
  SET_ISUSERTESTINGNEWPRICINGSERVICE: wrapWorker(
    SET_ISUSERTESTINGNEWPRICINGSERVICE,
    (dispatch: Dispatch, value: boolean) => {
      return value;
    }
  ),
  STOP_ALL: wrapWorker(
    STOP_ALL,
    (dispatch: Dispatch, clear: boolean | undefined) => {
      return clear;
    }
  ),
  CHECK_FOR_STALE_QUERY_DATA: wrapWorker(
    CHECK_FOR_STALE_QUERY_DATA,
    (dispatch: Dispatch, params: UserAccount["accountNumber"]) => {
      return params;
    }
  ),
  RETRY_QUERY_ITEM: wrapWorker(
    RETRY_QUERY_ITEM,
    (
      dispatch: ThunkDispatch<any, any, any>,
      params: Partial<QueryItemParams> | undefined
    ) => {
      if (params) {
        dispatch(retryQuery(params));
      }
      return params;
    }
  ),
  EXECUTE_QUERY_ITEM: wrapAsyncWorker(
    EXECUTE_QUERY_ITEM,
    (dispatch: Dispatch, params: QueryItemParams | undefined) => {
      return new Promise<MaterialWithDetails | undefined>((resolve, reject) => {
        if (params?.call) {
          params.call.query
            .then(
              (
                response?: AxiosResponse<MaterialPriceAndAvailabilityResult>
              ) => {
                let material = response?.data;
                if (material) {
                  mapSalesOrgDivisionToBrandName(material);
                  transformErrorMessaging(material, params.brands, {
                    accountNumber: params.accountNumber,
                    brands: params.accountBrands,
                  });
                  material.accountNumber = params.accountNumber;
                  material.time = new Date();
                  const item =
                    material?.materialResults?.length > 0
                      ? material.materialResults[0]
                      : undefined;
                  if (
                    params.getMaterialDetail &&
                    item &&
                    item.materialNumber &&
                    item.salesOrg &&
                    item.itemDivision
                  ) {
                    return getMaterialDetail(
                      item.materialNumber,
                      item.salesOrg,
                      item.itemDivision,
                      params.call?.token
                    )
                      .query.then((details) => {
                        resolve({
                          material,
                          details: details || null, // set to null if checked and none found
                        });
                      })
                      .catch((e: Error) => {
                        resolve({
                          material,
                        });
                      });
                  } else {
                    // check for edge case for no results returned from API
                    if (
                      material.materialResults.length === 0 &&
                      material.serviceErrorsReturned.length === 0 &&
                      material.skippedMaterials.length === 0
                    ) {
                      material.skippedMaterials.push({
                        materialNumber: "",
                        evaluationResults: [
                          {
                            message: "API returned no data.",
                          },
                        ],
                        brandDetails: null,
                      });
                    }
                    return resolve({ material });
                  }
                }
              }
            )
            .catch((e: Error) => {
              reject(e);
            });
        } else {
          reject();
        }
      });
    }
  ),
  CANCEL_QUERY_ITEM: wrapWorker(
    CANCEL_QUERY_ITEM,
    (dispatch: Dispatch, params: CancelQueryItemParams | undefined) => {
      return params;
    }
  ),
  CANCEL_MATERIAL_LOOKUP: wrapWorker(
    CANCEL_MATERIAL_LOOKUP,
    (dispatch: Dispatch, id: string) => {
      return id;
    }
  ),
  CANCEL_MATERIAL_LOOKUPS: wrapWorker(
    CANCEL_MATERIAL_LOOKUPS,
    (dispatch: Dispatch) => {
      return;
    }
  ),
  SET_QUERY_RESULTS_TABLEPAGING: wrapWorker(
    SET_QUERY_RESULTS_TABLEPAGING,
    (
      dispatch: Dispatch,
      params: SavedTablePagingSettings
    ): SavedTablePagingSettings => {
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLEFILTERS: wrapWorker(
    SET_QUERY_RESULTS_TABLEFILTERS,
    (
      dispatch: Dispatch,
      params: SavedTableFilterSettings
    ): SavedTableFilterSettings => {
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLESORTBY: wrapWorker(
    SET_SEARCH_RESULTS_TABLESORTBY,
    (
      dispatch: Dispatch,
      params: Array<{ id: string; desc?: boolean }>
    ): Array<{ id: string; desc?: boolean }> => {
      return params;
    }
  ),
  SET_QUERY_RESULTS_TABLESELECTEDROWS: wrapWorker(
    SET_QUERY_RESULTS_TABLESELECTEDROWS,
    (
      dispatch: Dispatch,
      params: Record<string, boolean>
    ): Record<string, boolean> => {
      return params;
    }
  ),
  CLEAR_EMPTY_QUERIES: wrapWorker(CLEAR_EMPTY_QUERIES, (dispatch: Dispatch) => {
    return;
  }),
  MATERIAL_LOOKUP: wrapAsyncWorker(
    MATERIAL_LOOKUP,
    (dispatch: Dispatch, params: MaterialLookupQueryParams | undefined) => {
      return new Promise<MaterialLookupSuggestion | undefined>(
        (resolve, reject) => {
          const defaultError = {
            Message: "Unknown Error",
            Error: "1011",
            Detail: "An unknown error has occurred, please contact support.",
          };
          if (params?.call) {
            params.call?.query
              .then((result) => {
                resolve(result);
              })
              .catch((e: Error) => {
                reject(
                  e
                    ? {
                        Message: e.name,
                        Error: "1011",
                        Detail: e.message,
                      }
                    : defaultError
                );
              });
          }
        }
      );
    }
  ),
};

const priceAndAvailabilityReducer = reducerWithInitialState(initialState)
  .case(
    ADD_QUERY_ITEM,
    (
      state: PriceAndAvailabilityStore,
      item: MaterialDataQueryItemInterface
    ) => {
      // in case of bulk imports put empty items last
      const notEmpty = state.queryItems?.filter((i) => i.value?.length > 0);
      const empty = state.queryItems?.filter((i) => i.value?.length === 0);
      const queryItems = [...notEmpty, item, ...empty];
      // return new state
      return { ...state, queryItems: queryItems };
    }
  )
  .case(
    REMOVE_QUERY_ITEM,
    (
      state: PriceAndAvailabilityStore,
      item: MaterialDataQueryItemInterface
    ) => {
      let queryItems: MaterialDataQueryItemInterface[] = [];
      let removeItems: MaterialDataQueryItemInterface[] = [];
      state.queryItems.forEach((x) => {
        if (x.id === item.id) {
          if (x.cancel && x.status === MaterialDataQueryItemStatus.LOADING) {
            x.cancel.cancel(CancelReasons.USER_CANCELED);
          }
          removeItems.push(x);
        } else {
          queryItems.push(x);
        }
      });
      return {
        ...state,
        queryItems,
      };
    }
  )
  .case(
    UPDATE_QUERY_ITEM,
    (
      state: PriceAndAvailabilityStore,
      item: MaterialDataQueryItemInterface
    ) => {
      const queryItems = state.queryItems.slice();
      queryItems.splice(
        state.queryItems?.findIndex((i) => i.id === item.id),
        1,
        item
      );
      return { ...state, queryItems };
    }
  )
  .case(
    UPDATE_QUERY_ITEM_QTY,
    (
      state: PriceAndAvailabilityStore,
      payload: { id: string; qty: number }
    ) => {
      const i = state.queryItems?.findIndex((x) => x.id === payload?.id);
      let queryItems = [...state.queryItems];
      if (payload && queryItems[i]) {
        const item = Object.assign({}, queryItems[i]);
        item.quantity = payload.qty < 1 ? 1 : payload.qty;
        item.materialPriceAndAvailabilityResult = undefined;
        item.status = undefined;
        item.error = undefined;
        queryItems.splice(i, 1, item);
      }
      return { ...state, queryItems };
    }
  )
  .case(
    UPDATE_QUERY_ITEM_VALUE,
    (
      state: PriceAndAvailabilityStore,
      payload: { id: string; value: string }
    ) => {
      const i = state.queryItems.findIndex((x) => x.id === payload?.id);
      let queryItems = [...state.queryItems];
      if (payload && queryItems[i]) {
        const item = Object.assign({}, queryItems[i]);
        item.value = payload.value;
        item.status = undefined;
        item.materialPriceAndAvailabilityResult = undefined;
        item.materialDetailResult = undefined;
        item.catalogNumberSuggestions = undefined;
        item.materialNumberSuggestion = undefined;
        item.customerMaterialNumberSuggestion = undefined;
        item.materialSearchSuggestions = undefined;
        item.selectedSuggestion = undefined;
        item.isValid = validateMaterialNumber(payload.value);
        queryItems.splice(i, 1, item);
      }
      return { ...state, queryItems };
    }
  )
  .case(
    UPDATE_QUERY_ITEMS_ARRAY,
    (
      state: PriceAndAvailabilityStore,
      items: MaterialDataQueryItemInterface[]
    ) => {
      return { ...state, queryItems: items };
    }
  )
  .case(CLEAR_QUERY_DATA, (state: PriceAndAvailabilityStore) => {
    /* Clears any errors and unsets P&A query result data
     * Usually called when user account is changed; forcing a re-query
     */
    return {
      ...state,
      queryItems: state.queryItems?.map((i) => {
        i.error = undefined;
        i.materialPriceAndAvailabilityResult = undefined;
        return i;
      }),
    };
  })
  .case(
    CHECK_FOR_STALE_QUERY_DATA,
    (
      state: PriceAndAvailabilityStore,
      accountNumber: UserAccount["accountNumber"]
    ) => {
      return {
        ...state,
        queryItems: state.queryItems.map((i) => {
          if (
            i.materialPriceAndAvailabilityResult?.accountNumber &&
            i.materialPriceAndAvailabilityResult.accountNumber !== accountNumber
          ) {
            i.materialPriceAndAvailabilityResult = undefined;
          }
          return i;
        }),
      };
    }
  )
  .case(
    SET_PRICE_DATE,
    (state: PriceAndAvailabilityStore, date: Date | undefined) => {
      const queryItems = state.queryItems.slice();
      queryItems.forEach(
        (i) => (i.materialPriceAndAvailabilityResult = undefined)
      );
      return { ...state, priceDate: date, queryItems: queryItems };
    }
  )
  .case(
    SET_VIEW,
    (state: PriceAndAvailabilityStore, view: PriceAndAvailabilityView) => {
      return { ...state, view: view };
    }
  )
  .case(
    SET_ISUSERTESTINGNEWPRICINGSERVICE,
    (state: PriceAndAvailabilityStore, value: boolean) => {
      return { ...state, userIsTestingNewPriceService: value };
    }
  )
  .case(
    STOP_ALL,
    (state: PriceAndAvailabilityStore, clear: boolean | undefined) => {
      const queryItems = state.queryItems.slice();

      queryItems.forEach((x) => {
        if (x.cancel && x.status === MaterialDataQueryItemStatus.LOADING) {
          x.cancel.cancel(CancelReasons.SYSTEM_CANCELED);
          x.status = undefined;
        }
      });
      if (clear) {
        return {
          ...initialState,
          userIsTestingNewPriceService: state.userIsTestingNewPriceService,
        };
      }
      return { ...state, queryItems: queryItems };
    }
  )
  .case(
    RETRY_QUERY_ITEM,
    (
      state: PriceAndAvailabilityStore,
      payload: Partial<QueryItemParams> | undefined
    ) => {
      // let queryItems = [ ...state.queryItems ];
      // let id = payload?.id;
      // if (id) {
      //   const queryItem = queryItems.find(x => x.id === id);
      //   if (queryItem?.callParams) {
      //     payload = {
      //        ...queryItem.callParams,
      //     }
      //   }
      // }

      return { ...state };
    }
  )
  .case(
    EXECUTE_QUERY_ITEM.started,
    (
      state: PriceAndAvailabilityStore,
      payload: QueryItemParams | undefined
    ) => {
      const i = state.queryItems?.findIndex((x) => x.id === payload?.id);
      let queryItems = [...state.queryItems];
      if (payload && queryItems[i]) {
        const item = Object.assign({}, queryItems[i]);
        item.value = item.value?.trim();
        if (
          item.cancel &&
          item.status === MaterialDataQueryItemStatus.LOADING
        ) {
          item.cancel?.cancel(CancelReasons.USER_CANCELED);
        }
        payload.call = getMaterialPriceAndAvailability(payload);
        item.error = undefined;
        item.status = MaterialDataQueryItemStatus.LOADING;
        item.cancel = payload.call?.token;
        item.callParams = { ...payload };
        queryItems.splice(i, 1, item);
      }
      return { ...state, queryItems };
    }
  )
  .case(
    EXECUTE_QUERY_ITEM.done,
    (
      state: PriceAndAvailabilityStore,
      payload: Success<
        QueryItemParams | undefined,
        MaterialWithDetails | undefined
      >
    ) => {
      const i = state.queryItems.findIndex((x) => x.id === payload?.params?.id);
      let queryItems = [...state.queryItems];

      if (queryItems[i] && payload.result) {
        // Some items get substituted for other items in the API; we need to split those into multiple queryItems
        if (materialHasReplacement(payload.result.material)) {
          // determine if material replacement should show the original query
          // THIS IS NOT GOOD, but we were forced to do it!!
          let replaceSilently = materialIsSilentlyReplaced(
            payload.result?.material
          );

          let requestedMaterialNumberValue = getLookupResultMaterialNumberValue(
            queryItems[i].selectedSuggestion
          );

          // split grouped material data; original and replacement
          const replacementMaterialData =
            {} as MaterialPriceAndAvailabilityResult;
          replacementMaterialData.materialResults = [];
          replacementMaterialData.serviceErrorsReturned = [];
          replacementMaterialData.skippedMaterials = [];

          const originalMaterialData = {} as MaterialPriceAndAvailabilityResult;
          originalMaterialData.materialResults = [];
          originalMaterialData.serviceErrorsReturned = [];
          originalMaterialData.skippedMaterials = [];

          payload.result.material?.materialResults.forEach((x) => {
            if (x.materialNumber !== requestedMaterialNumberValue) {
              replacementMaterialData.materialResults.push(x);
            } else {
              originalMaterialData.materialResults.push(x);
            }
          });

          payload.result.material?.skippedMaterials.forEach((x) => {
            if (x.materialNumber !== requestedMaterialNumberValue) {
              replacementMaterialData.skippedMaterials.push(x);
            } else {
              originalMaterialData.skippedMaterials.push(x);
            }
          });

          payload.result.material?.serviceErrorsReturned.forEach((x) => {
            if (x.materialNumber !== requestedMaterialNumberValue) {
              replacementMaterialData.serviceErrorsReturned.push(x);
            } else {
              originalMaterialData.serviceErrorsReturned.push(x);
            }
          });

          // determine the replacement item material number
          let replacementItemMaterialNumber = replacementMaterialData
            .materialResults?.length
            ? replacementMaterialData.materialResults[0].materialNumber || ""
            : replacementMaterialData.skippedMaterials?.length
            ? replacementMaterialData.skippedMaterials[0].materialNumber
            : "";

          // check if replacement has already been added to the queryItems array.
          const ri = state.queryItems?.findIndex(
            (x) =>
              x.value?.toUpperCase() ===
              replacementItemMaterialNumber?.toUpperCase()
          );

          // build new query item from replacement material data
          let replacementItem: MaterialDataQueryItemInterface | null = null;
          if (ri < 0) {
            replacementItem = Object.assign(
              {},
              queryItems[i],
              MaterialDataQueryItemFactory({
                value: replaceSilently
                  ? queryItems[i].value
                  : replacementItemMaterialNumber,
                quantity: queryItems[i].quantity,
              }),
              {
                materialDetailResult: payload.result?.details,
                materialPriceAndAvailabilityResult: Object.assign(
                  {},
                  payload.result?.material,
                  replacementMaterialData
                ),
                status: MaterialDataQueryItemStatus.READY,
                isValid: true,
              }
            );
          }

          // update the original query item
          if (!replaceSilently) {
            queryItems[i].materialPriceAndAvailabilityResult = Object.assign(
              {},
              payload.result?.material,
              originalMaterialData
            );
            queryItems[i].materialDetailResult = null;
            // add the replacement after the original
            if (replacementItem) {
              queryItems.splice(i + 1, 0, replacementItem);
            }
          }
          // unless we should REPLACE the original; BAD (we were forced!)
          if (replacementItem && replaceSilently) {
            queryItems[i] = replacementItem;
          }
        } else {
          queryItems[i].materialPriceAndAvailabilityResult =
            payload.result.material;
          queryItems[i].materialDetailResult =
            queryItems[i].materialDetailResult || payload.result?.details;
        }

        queryItems[i] = {
          ...queryItems[i],
          status: MaterialDataQueryItemStatus.READY,
          error: undefined,
          cancel: undefined,
        };
      }
      return {
        ...state,
        queryItems,
        tableSettings: initialPriceAndAvailabilityTableSettings,
      };
    }
  )
  .case(
    EXECUTE_QUERY_ITEM.failed,
    (
      state: PriceAndAvailabilityStore,
      payload: Failure<QueryItemParams | undefined, Error | undefined>
    ) => {
      const i = state.queryItems.findIndex((x) => x.id === payload?.params?.id);
      let queryItems = [...state.queryItems];
      if (queryItems[i] && payload.params) {
        const userCanceled =
          payload.error?.message === CancelReasons.USER_CANCELED;
        const userCleared =
          payload.error?.message === CancelReasons.SYSTEM_CANCELED;
        queryItems[i] = {
          ...queryItems[i],
          status: MaterialDataQueryItemStatus.ERROR,
          error: {
            // do not denote system cancellations
            Error:
              userCanceled || userCleared
                ? payload.error?.message || "Cancelled"
                : payload.error?.name || "Error",
            Message:
              userCanceled || userCleared
                ? "Canceled"
                : payload.error?.name || `Query Error`,
            Detail:
              userCanceled || userCleared
                ? "Canceled"
                : payload.error?.message ||
                  `${payload.params.id} query failed.`,
          },
          cancel: undefined,
        };
      }
      return { ...state, queryItems };
    }
  )
  .case(
    CANCEL_QUERY_ITEM,
    (
      state: PriceAndAvailabilityStore,
      payload: CancelQueryItemParams | undefined
    ) => {
      const i = payload
        ? state.queryItems.findIndex((x) => x.id === payload?.id)
        : -1;
      let queryItems = [...state.queryItems];
      if (
        queryItems[i] != null &&
        queryItems[i].status === MaterialDataQueryItemStatus.LOADING
      ) {
        // default to user cancelled if reason unspecified, system cancel is rare.
        queryItems[i].cancel?.cancel(
          payload?.reason || CancelReasons.USER_CANCELED
        );
      }
      return { ...state, queryItems };
    }
  )
  .case(
    SET_QUERY_RESULTS_TABLEPAGING,
    (state: PriceAndAvailabilityStore, paging: SavedTablePagingSettings) => {
      return { ...state, tableSettings: { ...state.tableSettings, paging } };
    }
  )
  .case(
    SET_QUERY_RESULTS_TABLEFILTERS,
    (state: PriceAndAvailabilityStore, filters: SavedTableFilterSettings) => {
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          filters,
          paging: { ...state.tableSettings.paging, pageIndex: 0 },
          selectedIds: {},
        },
      };
    }
  )
  .case(
    SET_SEARCH_RESULTS_TABLESORTBY,
    (
      state: PriceAndAvailabilityStore,
      sortBy: Array<{ id: string; desc?: boolean }>
    ) => {
      return { ...state, tableSettings: { ...state.tableSettings, sortBy } };
    }
  )
  .case(
    SET_QUERY_RESULTS_TABLESELECTEDROWS,
    (
      state: PriceAndAvailabilityStore,
      selectedIds: Record<string, boolean>
    ) => {
      return {
        ...state,
        tableSettings: { ...state.tableSettings, selectedIds },
      };
    }
  )
  .case(CLEAR_EMPTY_QUERIES, (state: PriceAndAvailabilityStore) => {
    let { queryItems } = { ...state };
    return {
      ...state,
      queryItems: queryItems?.filter((x) => x.value?.trim() !== ""),
    };
  })
  .case(
    MATERIAL_LOOKUP.started,
    (
      state: PriceAndAvailabilityStore,
      payload: MaterialLookupQueryParams | undefined
    ) => {
      const i = state.queryItems?.findIndex((x) => x.id === payload?.id);
      let queryItems = [...state.queryItems];
      if (queryItems[i] && payload?.value && payload.account) {
        payload.call = getMaterialLookup(payload);
        queryItems[i] = {
          ...queryItems[i],
          status: MaterialDataQueryItemStatus.LOADING,
          cancel: payload.call?.token,
          error: undefined,
        };
      }
      return { ...state, queryItems };
    }
  )
  .case(
    MATERIAL_LOOKUP.done,
    (
      state: PriceAndAvailabilityStore,
      payload: Success<
        MaterialLookupQueryParams | undefined,
        MaterialLookupSuggestion | undefined
      >
    ) => {
      const i = state.queryItems?.findIndex((x) => x.id === payload?.params?.id);
      let queryItems = [...state.queryItems];
      if (queryItems[i] && payload?.result) {
        queryItems[i] = {
          ...queryItems[i],
          ...payload.result,
          status: MaterialDataQueryItemStatus.READY,
          cancel: undefined,
          error: undefined,
        };
      }
      return { ...state, queryItems };
    }
  )
  .case(
    MATERIAL_LOOKUP.failed,
    (
      state: PriceAndAvailabilityStore,
      payload: Failure<
        MaterialLookupQueryParams | undefined,
        ErrorResponse | undefined
      >
    ) => {
      const i = state.queryItems?.findIndex((x) => x.id === payload?.params?.id);
      let queryItems = [...state.queryItems];
      if (queryItems[i] && payload?.error) {
        const item = Object.assign({}, queryItems[i]);
        item.error = payload.error;
        item.status = MaterialDataQueryItemStatus.ERROR;
        item.cancel = undefined;
        queryItems.splice(i, 1, item);
      }
      return { ...state, queryItems };
    }
  )
  .case(
    CANCEL_MATERIAL_LOOKUP,
    (state: PriceAndAvailabilityStore, id: string) => {
      const i = state.queryItems?.findIndex((x) => x.id === id);
      let queryItems = [...state.queryItems];
      if (queryItems[i]) {
        const item = Object.assign({}, queryItems[i]);
        if (item.cancel) {
          item?.cancel?.cancel(CancelReasons.USER_CANCELED);
        }
        item.error = undefined;
        item.status = MaterialDataQueryItemStatus.READY;
        queryItems.splice(i, 1, item);
      }
      return { ...state, queryItems };
    }
  )
  .case(CANCEL_MATERIAL_LOOKUPS, (state: PriceAndAvailabilityStore) => {
    let queryItems = [...state.queryItems];
    queryItems?.forEach((x) => {
      if (x.cancel) {
        x.cancel.cancel(CancelReasons.SYSTEM_CANCELED);
      }
      x.status = MaterialDataQueryItemStatus.READY;
      x.error = undefined;
    });
    return { ...state, queryItems };
  })
  .build();

export const PriceAndAvailabilityDuck = {
  actions,
  reducer: priceAndAvailabilityReducer,
};
