import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AsyncStateObjectInitialStateFactory,
  AsyncStatus,
} from "store/AsyncStateObject";
import InvoiceSearchState, {
  initialInvoiceSearchQueryState,
} from "./models/InvoiceSearchState";
import { InvoiceSearchQuery } from "./models/InvoiceSearchQuery";
//import searchResult from "./models/InvoiceSearchResultMock";
import { ControlledTableState } from "features/common/components/Table/models";
import { LocalStorage } from "store/localStorage";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";

export const storeKey = "invoiceSearch";

export const initialState: InvoiceSearchState = {
  query: initialInvoiceSearchQueryState,
  searchResult: AsyncStateObjectInitialStateFactory(),
  tableState: undefined,
};

const cachedState: InvoiceSearchState = LocalStorage.loadSerialized(storeKey);

export const searchInvoices = createAsyncThunk(
  storeKey + "/searchInvoices",
  async (query: Partial<InvoiceSearchQuery>, thunkApi) => {
    const invoiceSearchService = new SapRfcIntegrationApi.Invoice.Service();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await invoiceSearchService
      .searchAll(query, abortSignal)
      .then((r) => {
        data = r;
      })
      .catch((e) => {
        return Promise.reject(e?.message || e);
      });
    return data;
  }
);

export const invoiceSearchSlice = createSlice({
  name: storeKey,
  initialState: cachedState || initialState,
  reducers: {
    setQuery: (
      state,
      action: PayloadAction<Partial<InvoiceSearchState["query"]>>
    ) => {
      state.query = Object.assign(state.query, action.payload);
    },
    reset: (
      state,
      action: PayloadAction<Partial<InvoiceSearchState> | undefined>
    ) => {
      return action.payload
        ? Object.assign({}, initialState, action.payload)
        : initialState;
    },
    setSearchResult: (
      state,
      action: PayloadAction<Partial<InvoiceSearchState["searchResult"]>>
    ) => {
      state.searchResult = Object.assign(state.searchResult, action.payload);
    },
    setTableState: (
      state,
      action: PayloadAction<ControlledTableState | undefined>
    ) => {
      state.tableState = action.payload;
    },
  },
  extraReducers(builder) {
    // THUNK getSystemBrands
    builder.addCase(searchInvoices.pending, (state) => {
      state.searchResult.status = AsyncStatus.LOADING;
      state.searchResult.error = undefined;
      state.searchResult.data = undefined;
    });
    builder.addCase(searchInvoices.fulfilled, (state, { payload }) => {
      state.searchResult.status = AsyncStatus.SUCCEEDED;
      state.searchResult.data = payload;
    });
    builder.addCase(searchInvoices.rejected, (state, { error }) => {
      state.searchResult.status = AsyncStatus.FAILED;
      state.searchResult.error =
        error.message ||
        "An unknown error occurred while searching for invoices.";
      console.error("searchInvoices", error);
    });
  },
});
