import classNames from "classnames";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useIdleTimer } from "react-idle-timer";
import { useCountdownTimer } from "use-countdown-timer";
import { AuthService } from "../services/AuthService";

const idleTimeoutValue = 1000 * 60 * 30; // 30 minutes before considered idle
const initialCounterValue = 1000 * 120; // 120 seconds before auto sign-off

/*
 * Component to determine if user is idle.
 * If user is idle a prompt is displayed asking if they wish to log out or continue.
 * If no response, the user is logged out after a set interval
 */
const IdleUserComponent: FC<{}> = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const authService = useMemo(() => new AuthService(), []);
  const { countdown, start, reset, pause } = useCountdownTimer({
    timer: initialCounterValue,
  });
  const logUserOut = useCallback(() => {
    authService.signOut();
  }, [authService]);

  const startTimer = useCallback(
    () => start(),
    // eslint-disable-next-line
    []
  );

  const resetTimer = useCallback(
    () => {
      if (showModal === true) {
        setShowModal(false);
      }
      pause();
      reset();
    },
    // eslint-disable-next-line
    [showModal]
  );

  useEffect(() => {
    if (showModal === true) {
      startTimer();
    } else {
      resetTimer();
    }
  }, [showModal, startTimer, resetTimer]);

  useEffect(() => {
    if (countdown === 0) {
      logUserOut();
    }
  }, [countdown, logUserOut]);

  useIdleTimer({
    timeout: idleTimeoutValue,
    onIdle: () => {
      setShowModal(true);
    },
  });

  return (
    <Modal show={showModal} backdrop="static" centered={true} size="sm">
      <ModalBody>
        <div className="text-center">
          <b>Are you still there?</b>
          <br />
          You will be signed out in:{" "}
          <div>
            <b
              className={classNames({ "text-danger": countdown / 1000 <= 30 })}
            >
              {countdown / 1000}
            </b>{" "}
            seconds
          </div>
          <div>
            <button
              type="button"
              onClick={logUserOut}
              className="btn btn-light btn-small"
            >
              Log Out
            </button>{" "}
            <button
              type="button"
              onClick={resetTimer}
              className="btn btn-light btn-small"
            >
              Keep working
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default IdleUserComponent;
