import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypedDictionary } from "models/Indexable";
//import store, { StateSelectorFactory } from "store";
import {
  AddFileDownloadInterface,
  FileDownloadInterface,
  FileDownloadInterfaceFactory,
  MaxActiveDownloads,
  RemoveFileDownloadInterface,
} from "./FileDownloadInterface";
import { AsyncStatus } from "store/AsyncStateObject";
import { selectActiveDownloadCount } from "./selectors";

export const storeKey = "systemDownloadManager";

export interface DownloadManagerState {
  activeDownloads: number;
  downloadMap: TypedDictionary<FileDownloadInterface>;
}

export const initialState: DownloadManagerState = {
  activeDownloads: 0,
  downloadMap: {},
};

// Thunks
export const addDownload = createAsyncThunk(
  storeKey + "/addDownload",
  async (args: AddFileDownloadInterface, thunkApi) => {
    let activeDownloads = selectActiveDownloadCount();
    if (activeDownloads >= MaxActiveDownloads) {
      let msg = `Maximum number of downloads (${MaxActiveDownloads}) exceeded. Please try again once your active downloads are completed.`;
      args?.abort?.abort(msg);
      // thrown in case of timing issues with abort and api resolution
      throw new Error(msg);
    }
    let result = await args.call.catch((e) => {
      throw e;
    });
    if (result.error) {
      return Promise.reject(result.error.detail);
    }

    return {
      url: window.URL.createObjectURL(result.results.fileData),
      fileName: result.results.fileName,
    };
  }
);

// Slice Definition

export const systemDownloadManagerSlice = createSlice({
  name: storeKey,
  initialState: initialState,
  reducers: {
    removeDownload: (
      state,
      action: PayloadAction<RemoveFileDownloadInterface>
    ) => {
      const { [action.payload.id]: removed, ...rest } = state.downloadMap;
      if (removed.status === AsyncStatus.LOADING) {
        state.activeDownloads = state.activeDownloads - 1;
      }
      state.downloadMap = rest;
    },
  },
  extraReducers(builder) {
    // THUNK addDownload
    builder.addCase(addDownload.pending, (state, thunkApi) => {
      const args = thunkApi.meta.arg;
      let id = args.id;
      let item = FileDownloadInterfaceFactory();
      item.id = id;
      item.status = AsyncStatus.LOADING;
      item.featureId = args.featureId;
      item.fileType = args.fileType;
      state.activeDownloads = state.activeDownloads + 1;
      state.downloadMap = Object.assign({}, state.downloadMap, { [id]: item });
    });
    builder.addCase(addDownload.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const { [id]: item, ...rest } = state.downloadMap;
      state.activeDownloads = state.activeDownloads - 1;
      item.status = AsyncStatus.SUCCEEDED;
      item.url = action.payload.url;
      item.fileName = action.payload.fileName;
      state.downloadMap = Object.assign({}, rest, {
        [id]: item,
      });
    });
    builder.addCase(addDownload.rejected, (state, action) => {
      const { id } = action.meta.arg;
      const { [id]: item, ...rest } = state.downloadMap;
      state.activeDownloads = state.activeDownloads - 1;
      item.status = AsyncStatus.FAILED;
      item.error = action.error.message || "Download failed.";
      state.downloadMap = Object.assign({}, rest, {
        [id]: item,
      });
    });
  },
});

// Helpers

/*
export const getState = StateSelectorFactory(initialState, storeKey);

export const subscribe = (f: Function) => {
  let lastState = getState();
  return store.subscribe(
    () => lastState !== getState() && f((lastState = getState()))
  );
};
*/
