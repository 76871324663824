//import { AsyncStatus } from "store/AsyncStateObject";
//import InvoiceSearchMockData from "./InvoiceSearchMockData.json"

export const InvoiceSearchMockData = [
    {
      "invoiceNumber": "123456",
      "brand": "Wiring Devices",
      "salesOrg": "salesOrg1",
      "division": "division2",
      "printable": "YES",
      "total": "5150.46",
      "currency": "USD",
      "type": "ZF2",
      "shipTo": {
        "name": "US ELECTRICAL SERVICES INC",
        "city": "MIDDLETOWN CT"
      },
      "date": "9/15/2022",
      "salesOrderNumber": "120720917",
      "purchaseOrderNumber": "P101085407",
      "referenceNumber": "123456",
      "ultimateDestinationCountry": "US",
      "incoTerms": "WOB-SHIPPING POINT",
      "payTerms": "0291 2% 10th Prox"
    },
    {
      "invoiceNumber": "123456",
      "brand": "Wiring Devices",
      "salesOrg": "salesOrg1",
      "division": "division2",
      "printable": "YES",
      "total": "5150.46",
      "currency": "USD",
      "type": "ZF2",
      "shipTo": {
        "name": "US ELECTRICAL SERVICES INC",
        "city": "MIDDLETOWN CT"
      },
      "date": "9/15/2022",
      "salesOrderNumber": "120720917",
      "purchaseOrderNumber": "P101085407",
      "referenceNumber": "123456",
      "ultimateDestinationCountry": "US",
      "incoTerms": "WOB-SHIPPING POINT",
      "payTerms": "0291 2% 10th Prox"
    },
    {
      "invoiceNumber": "123456",
      "brand": "Wiring Devices",
      "salesOrg": "salesOrg1",
      "division": "division2",
      "printable": "YES",
      "total": "5150.46",
      "currency": "USD",
      "type": "ZF2",
      "shipTo": {
        "name": "US ELECTRICAL SERVICES INC",
        "city": "MIDDLETOWN CT"
      },
      "date": "9/15/2022",
      "salesOrderNumber": "120720917",
      "purchaseOrderNumber": "P101085407",
      "referenceNumber": "123456",
      "ultimateDestinationCountry": "US",
      "incoTerms": "WOB-SHIPPING POINT",
      "payTerms": "0291 2% 10th Prox"
    },
    {
      "invoiceNumber": "123456",
      "brand": "Wiring Devices",
      "salesOrg": "salesOrg1",
      "division": "division2",
      "printable": "YES",
      "total": "5150.46",
      "currency": "USD",
      "type": "ZF2",
      "shipTo": {
        "name": "US ELECTRICAL SERVICES INC",
        "city": "MIDDLETOWN CT"
      },
      "date": "9/15/2022",
      "salesOrderNumber": "120720917",
      "purchaseOrderNumber": "P101085407",
      "referenceNumber": "123456",
      "ultimateDestinationCountry": "US",
      "incoTerms": "WOB-SHIPPING POINT",
      "payTerms": "0291 2% 10th Prox"
    }
    
  ]