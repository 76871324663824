import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store/app";
import {
  MappedSystemState,
  mapSystemStateToProps,
} from "../../store/systemStore";
import { faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import "./style.scss";
import ActionButton from "../ActionButton";
import { UserProfile } from "../../store/userProfileStore";
import { fetchOrderDraftAccountNumber } from "../../store/cart/dispatchers";
import ItemUpdateListener from "./ItemUpdateListener";
import { resetCart } from "../../store/cart/dispatchers";
import ModalCart from "./ModalCart";

const UserAccountCartItem: FC<{ isLoading: boolean }> = (
  props
): JSX.Element => {
  const { system, userProfile: user } = useSelector<
    AppState,
    { system: MappedSystemState; userProfile: UserProfile }
  >((state) => {
    return {
      system: mapSystemStateToProps(state.system),
      userProfile: state.userProfile,
    };
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();

  const handleAddToCartEvent = (e: any) => {
    console.log("Reorder payload UserAccountCartItem", e.detail);
    setDetails(e.detail);
    setShowModal(true);
  };

  useEffect(() => {
    document?.addEventListener("HC_AddToCart", handleAddToCartEvent);
  }, []);

  useEffect(() => {
    if (user.currentAccount?.accountNumber) {
      resetCart();
      fetchOrderDraftAccountNumber(user.currentAccount?.accountNumber);
    }
  }, [user.currentAccount?.accountNumber]);

  const displayCartItem = (system: any) => {
    const orderEntryModule = system.findUserModule("order-entry");
    return orderEntryModule ? orderEntryModule : false;
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return !props.isLoading && displayCartItem(system) ? (
    <>
      <ItemUpdateListener />
      <Link
        title="Cart Item"
        to="/order-entry"
        onClick={() => document.body.click()}
      >
        <ActionButton icon={faShoppingCart} className="shoppingCartIcon" />
      </Link>
      {showModal && <ModalCart onClose={handleClose} details={details} />}
    </>
  ) : (
    <div></div>
  );
};

export default UserAccountCartItem;
