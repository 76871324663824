import { store } from "../..";
import HubbellConnectOrder, {
  Address,
  Detail,
  DetailData,
  Item,
  OrderAddressTypeName,
} from "../../models/HubbellConnectOrder";
import OrderEntryService from "../../services/OrderEntryService";
import { compareAddresses } from "./helpers";
import CartState, { FormStatus } from "./models/CartState";
import {
  cartSlice,
  getOrderDraftAccountNumber,
  saveOrderItemToDraft,
  updateAddressService,
  // updateOrderDetails,
} from "./reducers";

export interface OrderAddressCall {
  orderId: string;
  orderAddressId: string | undefined;
  suggestedData: Address;
}

export interface OrderDetailsUpdateCall {
  orderId: string;
  orderDetailsData: DetailData;
}

export interface UpdateOrderItemInterface {
  orderId: string | undefined;
  item: Item;
}

export interface AddressValidationInterface {
  line1: string;
  line2: string;
  city: string;
  region: string;
  postalcode: string;
  country: string;
}

export interface DocumentURLUpdateInterface {
  orderDetailId: string;
  documentURL: string;
}

export const saveOrderItem = (payload: UpdateOrderItemInterface) =>
  store.dispatch(saveOrderItemToDraft(payload));

export const updateOrderDraftItem = (payload: {
  item: Partial<Omit<Item, "orderItemId">>;
  itemId: string;
}) => store.dispatch(cartSlice.actions.updateDraftItem(payload));

export const LocationAvailabilityItem = (payload: {
  item: Partial<Omit<Item, "orderItemId">>;
  itemId: string;
}) => store.dispatch(cartSlice.actions.updateLocationAvailability(payload));

export const updateDraftTotal = (payload: {
  itemId: string;
}) => store.dispatch(cartSlice.actions.updateTotal(payload));

export const fetchOrderDraftAccountNumber = (accountNumber: string) =>
  store.dispatch(getOrderDraftAccountNumber(accountNumber));

// export const getSuggestedAddress = (
//   payload: Omit<OrderAddressCall, "suggestedData">
// ) => {
//   if (payload) store.dispatch(getSuggestedAddressService(payload));
// };

export const shippingAddressUpdateService = (payload: OrderAddressCall) => {
  if (payload) store.dispatch(updateAddressService(payload));
};

export const updateCart = (payload: Partial<CartState>) => {
  store.dispatch(cartSlice.actions.updateCart(payload));
};

export const updateOrderDraft = (orderDraft: Partial<HubbellConnectOrder>) => {
  store.dispatch(cartSlice.actions.updateOrderDraft(orderDraft));
};

export const updateDocumentUrl = (documentData: DocumentURLUpdateInterface) => {
  store.dispatch(cartSlice.actions.updateDocumentURL(documentData));
};

export const resetDocumentUrl = (orderDetailId: string) => {
  store.dispatch(cartSlice.actions.resetDocumentURL(orderDetailId));
};

export const exisitingOrderDraft = () => {
  store.dispatch(cartSlice.actions.existingUpdateOrderDraft());
};

export const resetCart = () => {
  store.dispatch(cartSlice.actions.resetCart());
};

export const updateShippingAddress = (payload: Partial<Address>) => {
  store.dispatch(cartSlice.actions.updateShippingAddress(payload));
};

export const setItemScreenValidity = (payload: FormStatus) => {
  store.dispatch(cartSlice.actions.setCartValidityStatus({ items: payload }));
};

export const setShippingScreenValidity = (payload: FormStatus) => {
  store.dispatch(
    cartSlice.actions.setCartValidityStatus({ shippingDetails: payload })
  );
};

export const setDetailsScreenValidity = (payload: FormStatus) => {
  store.dispatch(
    cartSlice.actions.setCartValidityStatus({ orderDetails: payload })
  );
};

export const validateAddress = async (enteredAddress: Address) => {
  const orderEntryServiceCall = new OrderEntryService();
  if (enteredAddress) {
    let query = {
      line1: enteredAddress.address1,
      line2: enteredAddress.address2,
      city: enteredAddress.city,
      region: enteredAddress.region,
      postalcode: enteredAddress.postal,
      country: enteredAddress.country,
    };
    const response = await orderEntryServiceCall.validateAddress(query);
    if (response.data) {
      if (!response.data?.errors) {
        let mismatchedAddressFields = compareAddresses(query, response.data);
        if (Object.keys(mismatchedAddressFields).length === 0) {
          let address = Object.assign({}, enteredAddress, {
            orderAddressType: { name: OrderAddressTypeName.ShipTo },
          });
          store.dispatch(cartSlice.actions.updateShippingAddress(address));
          return undefined;
        } else {
          let suggestedAddress = {
            ...enteredAddress,
            address1: response.data?.line1,
            address2: response.data?.line2,
            city: response.data?.city,
            region: response.data?.region,
            postal: response.data?.postalcode,
            country: response.data?.country,
          };
          console.log("suggested address from api", suggestedAddress);
          return {
            suggestedAddress: suggestedAddress,
            mismatchedAddressFields: mismatchedAddressFields,
          };
        }
      } else {
        return response.data;
      }
    } else {
      return { errors: { Message: response } };
    }
  }
};

export const fetchOrderDraftCarrierDetails = async (salesOrg: string) => {
  const orderEntryServiceCall = new OrderEntryService();
  if (salesOrg) {
    const response = await orderEntryServiceCall.OrderDraftCarrierDetails(
      salesOrg
    );
    if (response) {
      return response;
    }
  }
};

export const updatePrice = () => { };
export const updateInventory = () => { };
export const updatePriceAndInventory = () => { };

export const removeNotification = (payload: string) =>
  store.dispatch(cartSlice.actions.removeNotification(payload));

export const deleteOrderItem = async (orderId: string, itemId: string) => {
  const orderEntryServiceCall = new OrderEntryService();
  if (orderId && itemId) {
    return await orderEntryServiceCall
      .DeleteOrderItem(orderId, itemId)
      .then((resp: any) => {
        if (resp?.status === 200) {
          return resp;
        }
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
};

export const updateOrderItemsOnDelete = (
  orderDraft: Partial<Item[]>,
  updatedItemData: any[]
) =>
  store.dispatch(
    cartSlice.actions.updateOrderItemsOnDelete({
      items: orderDraft,
      itemData: updatedItemData,
    })
  );

export const updateValidatedItems = (orderDraft: Partial<Item[]>) =>
  store.dispatch(cartSlice.actions.updateValidatedItems({ items: orderDraft }));

export const updateValidatedItemsOnDelete = (
  orderDraft: Partial<Item[]>,
  itemId: string
) =>
  store.dispatch(
    cartSlice.actions.updateValidatedItemsOnDelete({
      items: orderDraft,
      itemId: itemId,
    })
  );

export const updateOrderEntryDetails = async (payload: Detail) => {
  store.dispatch(cartSlice.actions.updateDraftOrderDetails(payload));
};

export const setBrands = async (payload: String) => {
  store.dispatch(cartSlice.actions.setBrands(payload));
}

//create a new key for orderDetails form .. to update the array
export const setOrderDetails = async (payload: any) => {
  store.dispatch(cartSlice.actions.setOrderDetails(payload))
}
