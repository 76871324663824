import { format } from "date-fns";
import { AbstractService } from "./AbstractService";
import { Dictionary } from "models/Indexable";
import MaterialInventory from "services/models/MaterialInventory";
import IdentityApi from "services/IdentityApi";

export namespace Materials {
  export const isCustomerMaterialLookupResult = (
    x: any
  ): x is Output.CustomerMaterialLookup =>
    typeof x === "object" && "customerMaterialNumber" in x;

  export interface MaterialResult {
    materialNumber: string | null;
    customerMaterialNumber: string | null;
    description: string | null;
    salesOrg: string | null;
    itemDivision: string | null;
    headerDivision: string | null;
    itemType: string | null;
    minimumOrderQty: string | null;
    baseUnitOfMeasure: string | null;
    standardPackageQty: string | null;
    palletQty: string | null;
    caseQty: string | null;
    cartonQty: string | null;
    averageLeadTime: string | null;
    commodityCode: string | null;
    countryOfOrigin: string | null;
    quoteGroup: string | null;
    price: string | null;
    listPrice: string | null;
    tradePrice: string | null;
    priceUnit: string | null;
    currency: string | null;
    inventory: MaterialInventory;
    brand: string; // this value is mapped in by the service call
  }

  export interface ServiceError {
    callDescription: string;
    materialNumber: string;
    type: string;
    code: string;
    text: string;
    logNumber: string;
    messageVars: Array<string>;
  }

  export interface SkippedMaterial {
    materialNumber: string;
    evaluationResults: Array<{
      message: string;
      callingFunction?: string | null;
      operationName?: string | null;
      reason?: string | null;
    }>;
    brandDetails: IdentityApi.Brands.Brand[] | null;
  }

  export namespace Input {
    export interface CustomerMaterialLookup {
      value: string;
      customerAccountNumber: string;
    }

    export interface PriceAndAvailabilityInterface {
      materialNumber: string;
      salesOrg: string;
      division: string;
      quantity: string;
      customerAccountNumber: string;
      priceDate?: Date;
    }
  }

  export namespace Output {
    export interface CustomerMaterialLookup {
      materialNumber: string | null;
      customerMaterialNumber: string;
      customerNumber: string;
      salesOrg: string | null;
    }

    export interface PriceAndAvailability {
      accountNumber: string;
      time: Date;
      materialResults: Array<MaterialResult>;
      serviceErrorsReturned: Array<ServiceError>;
      skippedMaterials: Array<SkippedMaterial>;
    }
  }

  export class Service extends AbstractService {
    /**
     *
     * @param {string} query.value
     * @param {string} query.customerAccountNumber
     * @param {AbortSignal|undefined} signal
     * @returns {Materials.Output.CustomerMaterialLookup}
     */
    async customerMaterialNumberLookup(
      query: Input.CustomerMaterialLookup,
      signal: AbortSignal
    ): Promise<Output.CustomerMaterialLookup> {
      const headers = await this.getAuthorizationHeaders();
      const url = "customermaterials/{1}".replace(
        "{1}",
        encodeURIComponent(query.value)
      );

      const params = {
        accountNumber: query.customerAccountNumber,
      };

      const result = await this.connection.get(url, {
        baseURL: this.baseUrl,
        headers: headers,
        params: params,
        signal: signal,
      });
      return result.data;
    }

    // P&A endpoint call - single item only
    /**
     *
     * @param {string} query.materialNumber
     * @param {number} query.quantity
     * @param {string} query.salesOrg
     * @param {string} query.division
     * @param {Date | undefined} query.priceDate
     * @param {string} query.customerAccountNumber
     * @param {AbortSignal|undefined} signal
     * @returns
     */
    async priceAndAvailability(
      query: Input.PriceAndAvailabilityInterface,
      signal: AbortSignal
    ): Promise<Output.PriceAndAvailability> {
      const headers = await this.getAuthorizationHeaders();

      const url = "materials/{1}/pricing".replace(
        "{1}",
        encodeURIComponent(query.materialNumber)
      );

      let params: Dictionary = {
        accountNumber: query.customerAccountNumber,
        qty: query.quantity,
        salesOrg: query.salesOrg,
        division: query.division,
      };
      if (query.priceDate) {
        const priceDateString = format(new Date(query.priceDate), "yyyyMMdd");
        params = Object.assign(params, { priceDate: priceDateString });
      }
      return this.connection.get(url, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: signal,
        params: params,
      });
    }
  }
}
