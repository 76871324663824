import { sub, isBefore } from "date-fns";
import { Dispatch } from "redux";
import { AsyncActionCreators, ActionCreator } from "typescript-fsa";
import { Item } from "../models/HubbellConnectOrder";

const DataRefreshPeriodInMinutes = 15;

export function wrapAsyncWorker<TParameters, TSuccess, TError>(
  asyncAction: AsyncActionCreators<TParameters | undefined, TSuccess, TError>,
  worker: (
    dispatch: Dispatch,
    params?: TParameters
  ) => Promise<TSuccess>
) {
  return function wrappedWorker(
    dispatch: Dispatch,
    params?: TParameters
  ): Promise<TSuccess | void> {
    dispatch(asyncAction.started(params));
    return worker(dispatch, params).then(
      result => {
        dispatch(asyncAction.done({ params, result }));
        return result;
      },
      (error: TError) => {
        dispatch(asyncAction.failed({ params, error }));
      }
    );
  };
}

export function wrapWorker<TParameters>(
  action: ActionCreator<TParameters>,
  worker: (dispatch: Dispatch, params: TParameters) => TParameters) {
  return function wrappedWorker(dispatch: Dispatch, params: TParameters): TParameters {
    dispatch(action(params));
    return worker(dispatch, params);
  };
};

export const hashExpired = (hash: string | undefined, dataRefreshPeriodInMinutes?: number): boolean => {
  const timestamp = parseInt(hash as string);
  if (isNaN(timestamp)){
    return false;
  }
  return dataExpired(new Date(timestamp), dataRefreshPeriodInMinutes);
}


export const dataExpired = (currentRefresh: Date, dataRefreshPeriodInMinutes?: number): boolean => {  
  let latestRefreshAllowed = sub(new Date(),{minutes: dataRefreshPeriodInMinutes || DataRefreshPeriodInMinutes});
  let isStale = isBefore(currentRefresh,latestRefreshAllowed); 
  return isStale;
}

export const createStringHash = (...args: any[]): string => {
  let result = "";
  args.map((x, i) => result += args[i]);
  return result;
}

export const isNewTableFilters = (currentFilters: Array<{ id: string; value: any }>, newFilters: Array<{ id: string; value: any }>): boolean => {  
  if (currentFilters?.length !== newFilters?.length){
    return true;
  }
  let isNew = false;
  for (let i = 0; i < newFilters.length && !isNew; i++) {
    let newFilterId = newFilters[i].id
    let index = currentFilters.findIndex((val: { id: string; value: any }) => val.id === newFilterId);
    if (index === -1){
      isNew = true;
    }
    else{
      let newFilterValues = newFilters[i].value as Array<string>;
      let currentFilterValues = currentFilters[index].value as Array<string>;
      if (currentFilterValues.length !== newFilterValues.length){
        isNew = true;
      }
      else{
        isNew = !newFilterValues.every((newVal: string) => currentFilterValues.includes(newVal));          
      }
    }
  }    
  return isNew;
}

export const isNewSelectedRows = (currentSelections: Record<string, boolean>, newSelections: Record<string, boolean>): boolean => {  
  let currentKeys = Object.keys(currentSelections);
  let newKeys = Object.keys(newSelections);
  if (currentKeys.length !== newKeys.length){
    return true;
  }

  let isNew = !newKeys.every((newVal: string) => currentKeys.includes(newVal))
  return isNew;
}

export const isNewTableSortBy = (currentSortBy: Array<{ id: string; desc?: boolean }>, newSortBy: Array<{ id: string; desc?: boolean }>): boolean => {  
  if (currentSortBy.length !== newSortBy.length){
    return true;
  }
  let isNew = false;
  for (let i = 0; i < newSortBy.length && !isNew; i++) {
    let newSortById = newSortBy[i].id
    let index = currentSortBy.findIndex((val: { id: string; desc?: boolean }) => val.id === newSortById);
    isNew = index === -1 || currentSortBy[index].desc !== newSortBy[i].desc;
  }    
  return isNew;
}

export const timeDifference = (time1: string, time2?: string) =>{
  let currentTime: Date | number =  new Date().getTime() / 1000;

  if(time1){
    let refreshTime: Date | number =  new Date(time1).getTime() / 1000;
    const timeDifference = Math.floor(Math.abs(currentTime - refreshTime)/3600)
    return timeDifference > 12 ? true : false;
  }
  if(time2) {
    let refreshTime: Date | number =  new Date(time2).getTime() / 1000;
    const timeDifference = Math.floor(Math.abs(currentTime - refreshTime)/60)
    return timeDifference > 60 ? true : false;
  }
}

export const updateItem  = (item: Item) => {
  if(!item.unitPrice && !item.extendedPrice)
  {
    return true
  } else if(item.priceLastRefreshed || item.inventoryLastRefreshed) {
    return timeDifference(item.priceLastRefreshed, item.inventoryLastRefreshed)
  }
  return false
}


export const updateOrderItem = (index: number, items: any, item: any) => {
  console.log(items[index], item , 'items and item from state');
  let updatedItem: any = items[index];

  // updatedItem.extendedPrice = item?.price * item?.quantity;
  updatedItem.unitPrice = item.unitPrice;
  updatedItem.catalogNumber =  item.catalogNumber;
  updatedItem.description = item.description;
  updatedItem.minimumOrderQty = item.minimumOrderQty;
  updatedItem.baseUnitOfMeasure = item.baseUnitOfMeasure;
  updatedItem.standardPackageQty = item.standardPackageQty;
  updatedItem.palletQty = item.palletQty;
  updatedItem.caseQty = item.caseQty;
  updatedItem.cartonQty = item.cartonQty;
  updatedItem.grossWeight = item.grossWeight;
  updatedItem.inventory = item.inventory;

  return updatedItem;
}