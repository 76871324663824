import { getYear } from "date-fns";
import OrderEntryService from "../../services/OrderEntryService";
import { OrderDetailsUpdateCall } from "./dispatchers";
import { userAuthorizedFeature } from "store/system/helpers";

const OrderEntry_Group = [
  { salesOrg: "C400", division: "4A" },
  { salesOrg: "C400", division: "4B" },
  { salesOrg: "C400", division: "4J" },
  { salesOrg: "C400", division: "7A" },
];

export function enableCart() {
  return userAuthorizedFeature("order-entry");
}

export function brandSupportsOrderEntry(
  salesOrg: string,
  headerDivision: string
) {
  return (
    OrderEntry_Group.find(
      (i: any) => i.salesOrg === salesOrg && i.division === headerDivision
    ) !== undefined
  );
}

//----- This method will be useful for determining if we need to render order notes or not
export const displayOrderNotes = (salesOrg: any, headerDivision: any) => {
  return false;
};

export const compareAddresses = (address1: any, address2: any) => {
  var changes: any = {};
  for (var i in address1) {
    if (
      address1[i]?.toString().toLowerCase() !==
      address2[i]?.toString().toLowerCase()
    ) {
      if (address2[i] !== undefined) {
        changes[i] = address1[i];
      }
    }
  }
  return changes;
};

export const mapInputField = (field: string) => {
  switch (field) {
    case "line1":
      return "address1";
    case "line2":
      return "address2";
    case "city":
      return "city";
    case "region":
      return "region";
    case "postalcode":
      return "postal";
    default:
      return "address1";
  }
};

export const fetchFreightTerm = async (
  accountNumber: string,
  salesOrg: string,
  division: string
) => {
  const orderEntryServiceCall = new OrderEntryService();
  const response = await orderEntryServiceCall.freightTerm(
    accountNumber,
    salesOrg,
    division
  );
  return response;
};

export const fetchOrderDraftCarrierDetails = async (salesOrg: string) => {
  const orderEntryServiceCall = new OrderEntryService();
  if (salesOrg) {
    const response = await orderEntryServiceCall.OrderDraftCarrierDetails(
      salesOrg
    );
    if (response) {
      return response;
    }
  }
};

export const mappingDetailsFormDefaultValues = (detailsFormData: any) => {
  let detailsFormDefaultValues: any = {}
  detailsFormDefaultValues.orderDetails = []
  detailsFormData?.forEach((detailFormData: any) => {
    const mappedDetails =  { 
    orderDetailId: detailFormData?.orderDetailId,
    detailsSalesOrg : detailFormData?.salesOrg,
    detailsDivision : detailFormData?.division,
    purchaseOrderNumber: detailFormData?.purchaseOrderNumber,
    purchaseOrderDate: detailFormData?.purchaseOrderDate
      ? getYear(new Date(detailFormData?.purchaseOrderDate)) !== 1
        ? detailFormData?.purchaseOrderDate
        : new Date()
      : new Date(),
    poAttachmentUrl: detailFormData?.poAttachmentUrl,
    rush: detailFormData?.rush || false,
    shipComplete: detailFormData?.shipComplete || false,
    orderType: detailFormData?.orderType || "Standard",
    requestedShipDate: detailFormData?.requestedShipDate
      ? getYear(new Date(detailFormData?.requestedShipDate)) !== 1
        ? detailFormData?.requestedShipDate
        : new Date()
      : new Date(),
    freightTermsName: detailFormData?.freightTermsName || "",
    freightAccountNumber: detailFormData?.freightAccountNumber || "",
    carrierPreference: detailFormData?.carrierPreference || "",
    ultimateDestinationCountry: detailFormData?.destinationCountry || "",
    freightThirdPartyAddress_Name:
      detailFormData?.freightThirdPartyAddress_Name || "",
    freightThirdPartyAddress_Address:
      detailFormData?.freightThirdPartyAddress_Address || "",
    freightThirdPartyAddress_City:
      detailFormData?.freightThirdPartyAddress_City || "",
    freightThirdPartyAddress_State:
      detailFormData?.freightThirdPartyAddress_State || "",
    freightThirdPartyAddress_PostalCode:
      detailFormData?.freightThirdPartyAddress_PostalCode|| "",
    freightThirdPartyAddress_Country:
      detailFormData?.freightThirdPartyAddress_Country || "US",
    notes: detailFormData?.notes !== null ? detailFormData?.notes : "",
    carrierInstructions:
      detailFormData?.carrierInstructions !== null
        ? detailFormData?.carrierInstructions
        : "",
    specialInstructions:
      detailFormData?.specialInstructions !== null
        ? detailFormData?.specialInstructions
        : "",
    markings: detailFormData?.markings !== null ? detailFormData?.markings : "",
    internalOrderNotes:
      detailFormData?.internalNotes !== null
        ? detailFormData?.internalNotes
        : "",
    agentNotes:
      detailFormData?.agentNotes !== null ? detailFormData?.agentNotes : "",
    }
    detailsFormDefaultValues.orderDetails.push(mappedDetails)
  })
  return detailsFormDefaultValues;
};

export const returnNullIfStringEmpty = (str: string) => {
  if (str === "") {
    return null;
  } else {
    return str;
  }
};

export const updateOrderDetails = async (args: OrderDetailsUpdateCall) => {
  const orderEntryServiceCall = new OrderEntryService();
  const response = await orderEntryServiceCall.updateOrderEntryDetails(
    args.orderId,
    args.orderDetailsData.orderDetailId,
    args.orderDetailsData
  );
  console.log("updateOrderDetails", response);
  return response;
};

export const compareObjects = (obj1: any, obj2: any) => {
  var changes: any = {};
  for (var i in obj1) {
    if (
      obj1[i]?.toString().toLowerCase() !== obj2[i]?.toString().toLowerCase()
    ) {
      if (obj2[i] !== undefined) {
        changes[i] = obj1[i];
      }
    }
  }
  return changes;
};