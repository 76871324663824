import { FC, useEffect, useMemo } from "react";
import { useCart } from "../../store/cart/useCart";
import { debounce } from "lodash";
import { selectOrderId } from "../../store/cart/selectors";
import {
  removeNotification,
  saveOrderItem,
} from "../../store/cart/dispatchers";
import { AsyncStatus } from "../../store/AsyncStateObject";
import { Toast } from "react-bootstrap";

const ItemUpdateListener: FC<{}> = (): JSX.Element => {
  const { itemUpdateQueue, notificationToast } = useCart();
  const orderId = selectOrderId();

  const updateItems = useMemo(() => {
    return debounce(() => {
      itemUpdateQueue.forEach((item: any) => {
        if (item.status === AsyncStatus.IDLE) {
          let query = {
            orderId: orderId,
            item: item.data,
          };
          saveOrderItem(query);
        }
      });
    }, 500);
  }, [itemUpdateQueue, orderId]);

  useEffect(() => {
    if (itemUpdateQueue.length > 0) {
      let cancel = updateItems.cancel;

      updateItems();
      return () => {
        if (cancel) cancel();
      };
    }
  }, [itemUpdateQueue, updateItems]);

  return (
    <div>
      <div className="errorNotificatonToast">
        {notificationToast?.length > 0 &&
          notificationToast?.map((item: any) => (
            <Toast
              key={item?.id}
              className="toast-notification"
              onClose={() => {
                removeNotification(item.id);
              }}
            >
              <Toast.Header closeVariant="white">Error Updating</Toast.Header>
              <Toast.Body>{item?.toastMessage}</Toast.Body>
            </Toast>
          ))}
      </div>
    </div>
  );
};

export default ItemUpdateListener;
