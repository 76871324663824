import { Dispatch } from "redux";
import actionCreatorFactory, {
  ActionCreator,
  AsyncActionCreators,
  Failure,
  Success,
} from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "./app";
import { wrapAsyncWorker, wrapWorker, dataExpired } from "./helpers";
import { ApiError, ApiCancelTokenSource } from "../services/ApiService";
import QuoteService from "../services/QuoteSearchService";
import {
  Quotes,
  SalesQuoteType,
  QuoteDetails,
  QuoteDocumentTypes,
  EmailQuoteResponse,
  QuoteDocument,
  DownloadQuoteResponse,
  QuoteTermsAndConditions,
} from "../models/Quote";
import { ErrorResponse } from "../models/Error";
import {
  CustomerQuoteSearchInterface,
  CustomerQuoteSearchMode,
} from "../services/QuoteServiceInterface";
import {
  mapInputSearchParameters,
  checkForNewSearchParameters,
  buildQuotesSearchQuery,
  updateDetailsMap,
} from "../util/quotesHelper";
import {
  SavedTableFilterSettings,
  SavedTablePagingSettings,
  SavedTableSettings,
} from "../models/Table";
import { DeNotify, Notify } from "./system/notifications/helpers";
import { triggerFileDownload } from "./system/downloadManager/helpers";

const DetailsCacheSize = 10;
const DownloadPrefix = "quote_download";
const EmailToastPrefix = "quote_email";
const QuotesSearchTableName = "QuoteSearchTable";

export enum QuoteSearchPreset {
  "VALID" = "All",
  "ACTIVE" = "Active",
  "EXPIRED" = "Expired",
  "FUTURE" = "Future",
  "EXPIRING_30" = "Expiring in 30 days",
  "EXPIRING_60" = "Expiring in 60 days",
  "EXPIRING_90" = "Expiring in 90 days",
  "ADVANCED" = "Advanced Search",
}

export const defaultQuoteSearchPreset = QuoteSearchPreset.ACTIVE;

interface PartialQuoteSearchParameters {
  presetValue: QuoteSearchPreset | undefined;
  materialNumber: string | undefined;
  quoteNumber: string | undefined;
  projectName: string | undefined;
  quoteType: string | undefined;
  customerAccountNumber: string | undefined;
  quoteTypeName: string | undefined;
}
export interface QuoteSearchParameters extends PartialQuoteSearchParameters {
  createdDateFrom: string | undefined;
  createdDateTo: string | undefined;
  mode?: CustomerQuoteSearchMode;
  expiringInDays?: number;
}

export interface QuoteSearchInputParameters
  extends PartialQuoteSearchParameters {
  createdDateFrom: Date | undefined;
  createdDateTo: Date | undefined;
  mode?: CustomerQuoteSearchMode | undefined;
  expiringInDays?: number | undefined;
}

export interface QuoteDocumentParameters {
  quoteNumbers: string[];
  accountNumber?: string;
  documentType: QuoteDocumentTypes;
  email?: string[];
  cc?: string[];
  sender?: string;
  id?: string;
  onEmailSuccess?: (response: EmailQuoteResponse) => void;
}

interface QuoteDownloadCancelTokanParameters {
  id: string;
  cancelToken: ApiCancelTokenSource;
}

export interface QuoteResults {
  searchParameters: QuoteSearchParameters | undefined;
  quoteData: Quotes | undefined;
  quoteTypes: SalesQuoteType[] | undefined;
  loading: boolean;
  cancelToken: ApiCancelTokenSource | undefined;
  error: ErrorResponse | undefined;
  timestamp: Date;
  tableSettings: SavedTableSettings;
}

export interface QuoteDetailData {
  terms: QuoteTermsAndConditions | undefined;
  data: QuoteDetails | undefined;
  timestamp: Date;
}

export interface QuoteDetailsState {
  currentDetails: QuoteDetailData;
  loading: boolean;
  loadingTerms: boolean;
  cancelToken: ApiCancelTokenSource | undefined;
  error: ErrorResponse | undefined;
  timestamp: Date;
  latestDetails: Map<string, QuoteDetailData>;
}

export interface QuoteDocumentDownload {
  id: string;
  document: QuoteDocument | undefined;
  downloading: boolean;
  error: ErrorResponse | undefined;
  cancelToken: ApiCancelTokenSource | undefined;
}

export interface QuoteDocuments {
  emailing: boolean;
  emailResult: EmailQuoteResponse | undefined;
  emailError: ErrorResponse | undefined;
  documentDownloads: {
    [key: string]: QuoteDocumentDownload;
  };
}

export interface QuotesStore {
  searchResults: QuoteResults;
  activeQuote: string | undefined;
  timestamp: Date;
  details: QuoteDetailsState;
  documents: QuoteDocuments;
}

const initialTableSettings: SavedTableSettings = {
  tableId: QuotesSearchTableName,
  paging: {
    pageIndex: 0,
    pageSize: 10,
  },
  filters: { filters: [] },
  selectedIds: {},
  sortBy: [],
};

export const initialState: QuotesStore = {
  searchResults: {
    searchParameters: undefined,
    quoteData: undefined,
    quoteTypes: undefined,
    loading: false,
    cancelToken: undefined,
    error: undefined,
    timestamp: new Date(0),
    tableSettings: initialTableSettings,
  },
  activeQuote: undefined,
  timestamp: new Date(),
  details: {
    currentDetails: {
      terms: undefined,
      data: undefined,
      timestamp: new Date(0),
    },
    loading: false,
    loadingTerms: false,
    cancelToken: undefined,
    error: undefined,
    timestamp: new Date(0),
    latestDetails: new Map(),
  },
  documents: {
    emailing: false,
    emailResult: undefined,
    emailError: undefined,
    documentDownloads: {},
  },
};

export const initialSearchParameters: QuoteSearchParameters = {
  presetValue: defaultQuoteSearchPreset,
  materialNumber: undefined,
  quoteNumber: undefined,
  projectName: undefined,
  quoteType: undefined,
  createdDateTo: undefined,
  createdDateFrom: undefined,
  customerAccountNumber: undefined,
  quoteTypeName: undefined,
  mode: undefined,
  expiringInDays: undefined,
};

// Action creators
const ac = actionCreatorFactory("quotes");
// for quote search
const SET_SEARCH_LOADING: ActionCreator<boolean> =
  ac<boolean>("set_search_loading");
const SET_SEARCH_PARAMETERS: ActionCreator<QuoteSearchParameters> =
  ac<QuoteSearchParameters>("set_search_parameters");
const SET_SEARCH_RESULTS: ActionCreator<Quotes> =
  ac<Quotes>("set_search_results");
const SET_SEARCH_ERROR: ActionCreator<ErrorResponse> =
  ac<ErrorResponse>("set_search_error");
const SET_SEARCH_CANCEL_TOKEN: ActionCreator<ApiCancelTokenSource> =
  ac<ApiCancelTokenSource>("set_search_cancel_token");
const CANCEL_SEARCH_RESULTS: ActionCreator<void> = ac<void>(
  "cancel_search_results"
);
//to do - remove
const WAITING_TO_SEARCH_RESULTS: ActionCreator<void> = ac<void>(
  "waiting_to_search_results"
);
const GET_QUOTE_TYPES: AsyncActionCreators<
  boolean | undefined,
  SalesQuoteType[] | undefined,
  ErrorResponse
> = ac.async<boolean | undefined, SalesQuoteType[] | undefined, ErrorResponse>(
  "get_quote_types"
);
const SET_SEARCH_RESULTS_TABLEPAGING: ActionCreator<SavedTablePagingSettings> =
  ac<SavedTablePagingSettings>("set_search_results_tablepaging");
const SET_SEARCH_RESULTS_TABLEFILTERS: ActionCreator<SavedTableFilterSettings> =
  ac<SavedTableFilterSettings>("set_search_results_tablefilters");
const SET_SEARCH_RESULTS_TABLESELECTEDROWS: ActionCreator<
  Record<string, boolean>
> = ac<Record<string, boolean>>("set_search_results_tableselectedrows");
const SET_SEARCH_RESULTS_TABLESORTBY: ActionCreator<
  Array<{ id: string; desc?: boolean }>
> = ac<Array<{ id: string; desc?: boolean }>>("set_search_results_tablesortby");
// for quote details
const SET_ACTIVE_QUOTE: ActionCreator<string> = ac<string>("set_active_quote");
const SET_DETAILS_LOADING: ActionCreator<boolean> = ac<boolean>(
  "set_details_loading"
);
const SET_DETAILS_CANCEL_TOKEN: ActionCreator<ApiCancelTokenSource> =
  ac<ApiCancelTokenSource>("set_details_cancel_token");
const CANCEL_DETAILS: ActionCreator<void> = ac<void>("cancel_details");
const CANCEL_DETAILS_TERMS: ActionCreator<void> = ac<void>(
  "cancel_details_terms"
);
const SET_CACHED_DETAILS: ActionCreator<QuoteDetailData> =
  ac<QuoteDetailData>("set_cached_details");
const SET_DETAILS: ActionCreator<QuoteDetails> =
  ac<QuoteDetails>("set_details");
const SET_DETAILS_ERROR: ActionCreator<ErrorResponse> =
  ac<ErrorResponse>("set_details_error");
const SET_DETAILS_TERMS_LOADING: ActionCreator<boolean> = ac<boolean>(
  "set_details_terms_loading"
);
const SET_DETAILS_TERMS: ActionCreator<QuoteTermsAndConditions> =
  ac<QuoteTermsAndConditions>("set_details_terms");
const SET_DETAILS_TERMS_ERROR: ActionCreator<ErrorResponse> = ac<ErrorResponse>(
  "set_details_terms_error"
);
const CLEAR_DETAILS: ActionCreator<void> = ac<void>("clear_details");
// for quote documents
const DOCUMENTS_DOWNLOAD: AsyncActionCreators<
  QuoteDocumentParameters | undefined,
  QuoteDocumentDownload,
  ErrorResponse
> = ac.async<
  QuoteDocumentParameters | undefined,
  QuoteDocumentDownload,
  ErrorResponse
>("documents_download");
const DOCUMENTS_DOWNLOAD_CANCEL_TOKEN: ActionCreator<QuoteDownloadCancelTokanParameters> =
  ac<QuoteDownloadCancelTokanParameters>("documents_download_cancel_token");
const DOCUMENTS_DOWNLOAD_CANCEL = ac<string | undefined>(
  "documents_download_cancel"
);
const DOCUMENTS_CLEAR: ActionCreator<string> = ac<string>(
  "documents_download_clear"
);
const DOCUMENTS_CLEARALL: ActionCreator<void> = ac<void>(
  "documents_download_clearall"
);
const DOCUMENTS_EMAIL: AsyncActionCreators<
  QuoteDocumentParameters | undefined,
  EmailQuoteResponse,
  ErrorResponse
> = ac.async<
  QuoteDocumentParameters | undefined,
  EmailQuoteResponse,
  ErrorResponse
>("documents_email");
const DOCUMENTS_EMAIL_CLEAR: ActionCreator<void> = ac<void>(
  "documents_email_clear"
);

//thunks
export const fetchQuoteResults = (query: QuoteSearchInputParameters) => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    try {
      if (query?.customerAccountNumber) {
        const { quotes: quotesStore } = getState();
        const searchState = quotesStore.searchResults;
        let mappedSearchParams = mapInputSearchParameters(query);
        let currentParameters: QuoteSearchParameters =
          searchState.searchParameters || initialSearchParameters;
        let isStale = dataExpired(searchState?.timestamp);
        let isChangeInParameters = checkForNewSearchParameters(
          currentParameters,
          mappedSearchParams
        );

        let needToQuery = isChangeInParameters || isStale;

        if (needToQuery) {
          let quoteService = new QuoteService();
          let token = quoteService.generateSourceToken();
          dispatch(SET_SEARCH_CANCEL_TOKEN(token));
          let query: CustomerQuoteSearchInterface | undefined =
            buildQuotesSearchQuery(mappedSearchParams);

          if (query) {
            dispatch(SET_SEARCH_LOADING(true));
            try {
              let results: Quotes = await quoteService.Search(query, token);
              dispatch(SET_SEARCH_PARAMETERS(mappedSearchParams));
              dispatch(SET_SEARCH_RESULTS(results));
            } catch (error: any) {
              let errorResponse: ErrorResponse = {
                Message: error.name ? error.name : error.message,
                Error: "1011",
                Detail: error.name && error.message ? error.message : "",
              };
              if (searchState.cancelToken) {
                dispatch(SET_SEARCH_PARAMETERS(mappedSearchParams));
              }
              dispatch(SET_SEARCH_ERROR(errorResponse));
              console.error(errorResponse);
            }
          } else {
            console.error("Improper query specified.");
            dispatch(SET_SEARCH_LOADING(false));
          }
        } else {
          console.info("No need to search for quotes.");
          if (searchState.loading) {
            dispatch(SET_SEARCH_LOADING(false));
          }
        }
      } else {
        console.error("Improper query specified.");
        dispatch(SET_SEARCH_LOADING(false));
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };
};

export const cancelFetchQuoteResults = () => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    try {
      const { quotes: quotesStore } = getState();
      const searchState = quotesStore.searchResults;

      if (searchState.cancelToken) {
        searchState.cancelToken.cancel(
          "Canceling Quote Search due to user request."
        );
        dispatch(CANCEL_SEARCH_RESULTS());
      } else {
        dispatch(SET_SEARCH_LOADING(false));
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };
};

export const cancelFetchDetailsOrTerms = (callType: "details" | "terms") => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    try {
      const { quotes: quotesStore } = getState();
      const detailsState = quotesStore.details;

      if (detailsState.cancelToken) {
        detailsState.cancelToken.cancel(
          `Canceling retrieval of Quote ${callType} due to user request.`
        );
        if (callType === "details") {
          dispatch(CANCEL_DETAILS);
        } else {
          dispatch(CANCEL_DETAILS_TERMS);
        }
      } else {
        if (callType === "details") {
          dispatch(SET_DETAILS_LOADING(false));
        } else {
          dispatch(SET_DETAILS_TERMS_LOADING(false));
        }
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };
};

export const fetchQuoteDetails = (
  quoteNumber: string,
  customerAccountNumber: string
) => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    try {
      if (quoteNumber?.length > 0) {
        const { quotes: quotesStore } = getState();
        const detailsState = quotesStore.details;
        dispatch(SET_ACTIVE_QUOTE(quoteNumber));

        let cachedDetailData: QuoteDetailData | undefined =
          detailsState.latestDetails.get(quoteNumber + customerAccountNumber);
        if (cachedDetailData?.data) {
          let isStaleData = dataExpired(cachedDetailData?.timestamp);
          if (isStaleData) {
            cachedDetailData = undefined;
          }
        }

        if (cachedDetailData?.data) {
          console.log("No need to fetch quote details.");
          dispatch(SET_CACHED_DETAILS(cachedDetailData));
        } else {
          let quoteService = new QuoteService();
          let token = quoteService.generateSourceToken();
          dispatch(SET_DETAILS_CANCEL_TOKEN(token));
          dispatch(SET_DETAILS_LOADING(true));
          try {
            let results: QuoteDetails = await quoteService.GetQuoteDetails(
              quoteNumber,
              customerAccountNumber,
              token
            );
            dispatch(SET_DETAILS(results));
          } catch (error: any) {
            let errorResponse: ErrorResponse = {
              Message: "Error",
              Error: "1011",
              Detail: error.message,
            };
            dispatch(SET_DETAILS_ERROR(errorResponse));
            console.error(errorResponse);
          }
        }
      } else {
        console.error("Improper quote number specified.");
        dispatch(SET_DETAILS_LOADING(false));
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };
};

export const fetchQuoteTerms = (
  quoteNumber: string,
  customerAccountNumber: string
) => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    try {
      if (quoteNumber?.length > 0) {
        const { quotes: quotesStore } = getState();
        const detailsState = quotesStore.details;

        let cachedDetailData: QuoteDetailData | undefined =
          detailsState.latestDetails?.get(quoteNumber + customerAccountNumber);
        if (cachedDetailData?.terms) {
          console.log("No need to fetch quote terms.");
          dispatch(SET_CACHED_DETAILS(cachedDetailData));
        } else {
          let quoteService = new QuoteService();
          let token = quoteService.generateSourceToken();
          dispatch(SET_DETAILS_CANCEL_TOKEN(token));
          dispatch(SET_DETAILS_TERMS_LOADING(true));
          try {
            let results =
              await quoteService.GetQuoteAggregateTermsAndConditions(
                quoteNumber,
                token
              );
            if (results) {
              let terms = results;
              dispatch(SET_DETAILS_TERMS(terms));
            } else {
              let noTermsError: ErrorResponse = {
                Message: "Not Found",
                Error: "1011",
                Detail: "Terms & Conditions were not found.",
              };
              dispatch(SET_DETAILS_TERMS_ERROR(noTermsError));
            }
          } catch (error: any) {
            let errorResponse: ErrorResponse = {
              Message: "Error",
              Error: "1011",
              Detail: error.message,
            };
            dispatch(SET_DETAILS_TERMS_ERROR(errorResponse));
            console.error(errorResponse);
          }
        }
      } else {
        console.error("Improper quote number specified.");
        dispatch(SET_DETAILS_LOADING(false));
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };
};

//actions are where you can handle your async calls to the API and manipulate them as needed. Afterwards, the result is passed to the reducers to modify the global state.
const actions = {
  //to do - remove
  WAITING_TO_SEARCH: wrapWorker(
    WAITING_TO_SEARCH_RESULTS,
    (dispatch: Dispatch): void => {
      return;
    }
  ),
  GET_QUOTE_TYPES: wrapAsyncWorker(
    GET_QUOTE_TYPES,
    (
      dispatch: Dispatch,
      params: boolean | undefined
    ): Promise<SalesQuoteType[]> => {
      return new Promise<SalesQuoteType[]>((resolve, reject) => {
        if (!params) reject();
        let quoteService = new QuoteService();
        let token = quoteService.generateSourceToken();
        quoteService
          .GetQuoteTypes(token)
          .then((quoteTypes: SalesQuoteType[]) => {
            resolve(quoteTypes);
          })
          .catch((error: ApiError) => {
            let errorResponse: ErrorResponse = {
              Message: error.message,
              Error: "1011",
              Detail: error.stack ? error.stack : "",
            };
            reject(errorResponse);
          });
      });
    }
  ),
  SET_SEARCH_RESULTS_TABLEPAGING: wrapWorker(
    SET_SEARCH_RESULTS_TABLEPAGING,
    (
      dispatch: Dispatch,
      params: SavedTablePagingSettings
    ): SavedTablePagingSettings => {
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLEFILTERS: wrapWorker(
    SET_SEARCH_RESULTS_TABLEFILTERS,
    (
      dispatch: Dispatch,
      params: SavedTableFilterSettings
    ): SavedTableFilterSettings => {
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLESELECTEDROWS: wrapWorker(
    SET_SEARCH_RESULTS_TABLESELECTEDROWS,
    (
      dispatch: Dispatch,
      params: Record<string, boolean>
    ): Record<string, boolean> => {
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLESORTBY: wrapWorker(
    SET_SEARCH_RESULTS_TABLESORTBY,
    (
      dispatch: Dispatch,
      params: Array<{ id: string; desc?: boolean }>
    ): Array<{ id: string; desc?: boolean }> => {
      return params;
    }
  ),
  SET_ACTIVE_QUOTE: wrapWorker(
    SET_ACTIVE_QUOTE,
    (dispatch: Dispatch, params: string): string => {
      return params;
    }
  ),
  CLEAR_DETAILS: wrapWorker(CLEAR_DETAILS, (dispatch: Dispatch): void => {
    return;
  }),
  DOCUMENTS_DOWNLOAD_CANCEL: wrapWorker(
    DOCUMENTS_DOWNLOAD_CANCEL,
    (dispatch: Dispatch, params: string | undefined): string | undefined => {
      return params;
    }
  ),
  DOCUMENTS_DOWNLOAD: wrapAsyncWorker(
    DOCUMENTS_DOWNLOAD,
    (
      dispatch: Dispatch,
      params: QuoteDocumentParameters | undefined
    ): Promise<QuoteDocumentDownload> => {
      return new Promise<QuoteDocumentDownload>((resolve, reject) => {
        if (
          params &&
          params.quoteNumbers &&
          params.accountNumber &&
          params.documentType &&
          params.id
        ) {
          let id = params.id;
          Notify({
            id,
            type: DownloadPrefix,
            title: "Downloading... ",
          });
          let waitTime = setTimeout(() => {
            Notify({
              id,
              message: "Please wait. This might take a few moments.",
            });
          }, 2000);

          let quoteService = new QuoteService();
          let token = quoteService.generateSourceToken();
          dispatch(
            DOCUMENTS_DOWNLOAD_CANCEL_TOKEN({
              id,
              cancelToken: token,
            })
          );

          let downloadCall: Promise<DownloadQuoteResponse> | undefined;
          if (params.quoteNumbers?.length === 1) {
            downloadCall = quoteService.GetQuoteDocument(
              params.quoteNumbers[0],
              params.accountNumber,
              token
            );
          } else {
            downloadCall = quoteService.GetQuoteDocuments(
              params.quoteNumbers,
              params.accountNumber,
              token
            );
          }
          Promise.resolve(downloadCall)
            .then((result) => {
              clearTimeout(waitTime);
              if (
                result &&
                result.Results?.fileName &&
                result.Results?.fileData
              ) {
                const url = window.URL.createObjectURL(result.Results.fileData);
                const filename = result.Results.fileName;
                triggerFileDownload({ url: url, fileName: filename });
                DeNotify(id);
                QuotesDuck.actions.DOCUMENTS_CLEAR(dispatch, id);

                resolve({
                  id,
                  document: {
                    ...result.Results,
                    url,
                  },
                  downloading: false,
                  error: result.Error,
                  cancelToken: undefined,
                });
              } else {
                Notify({
                  id,
                  title: result.Error?.Message,
                  message: result.Error?.Detail,
                  allowDismiss: true,
                });
                reject(result.Error);
              }
            })
            .catch((error: any) => {
              Notify({
                id,
                title: error.name,
                message: error.message,
                allowDismiss: true,
              });
              reject(error);
            });
        } else {
          Notify({
            id: new Date().toTimeString(),
            title: "Error",
            message: "No document download params provided.",
            allowDismiss: true,
          });
          reject("No document download params provided.");
        }
      });
    }
  ),
  DOCUMENTS_CLEAR: wrapWorker(
    DOCUMENTS_CLEAR,
    (dispatch: Dispatch, params: string) => {
      return params;
    }
  ),
  DOCUMENTS_CLEARALL: wrapWorker(
    DOCUMENTS_CLEARALL,
    (dispatch: Dispatch): void => {
      return;
    }
  ),
  DOCUMENTS_EMAIL: wrapAsyncWorker(
    DOCUMENTS_EMAIL,
    (
      dispatch: Dispatch,
      params: QuoteDocumentParameters | undefined
    ): Promise<EmailQuoteResponse> => {
      return new Promise<EmailQuoteResponse>((resolve, reject) => {
        if (
          params &&
          params.quoteNumbers &&
          params.quoteNumbers.length > 0 &&
          params.accountNumber &&
          params.documentType &&
          params.email
        ) {
          let quoteService = new QuoteService();
          let token = quoteService.generateSourceToken();
          debugger;
          quoteService
            .EmailQuoteDocuments(
              params.email,
              params.quoteNumbers,
              params.accountNumber,
              params.documentType,
              params.cc || [],
              params.sender,
              token
            )
            .then((result) => {
              if (result?.Results) {
                if (params?.onEmailSuccess) {
                  params.onEmailSuccess(result);
                }
                let time = new Date();
                let id = time.getTime().toString();
                Notify({
                  id,
                  type: EmailToastPrefix,
                  title: result.Results,
                });
                setTimeout(
                  (id: string) => {
                    DeNotify(id);
                    QuotesDuck.actions.DOCUMENTS_EMAIL_CLEAR(dispatch);
                  },
                  3000,
                  id
                );
                resolve(result.Results);
              } else {
                reject(result?.Error || "Email result is undefined.");
              }
            })
            .catch((reason) => {
              reject({
                Message: "Error",
                Error: "1012",
                Detail: reason.message,
              } as ErrorResponse);
            });
        } else {
          reject({
            Message: "Validation Error",
            Error: "1012",
            Detail: "Required parameters are missing.",
          } as ErrorResponse);
        }
      });
    }
  ),
  DOCUMENTS_EMAIL_CLEAR: wrapWorker(
    DOCUMENTS_EMAIL_CLEAR,
    (dispatch: Dispatch): void => {
      return;
    }
  ),
};

//the reducers modify the global state of redux with the results of the actions above
const QuoteSearchReducer = reducerWithInitialState(initialState)
  .case(SET_SEARCH_LOADING, (state: QuotesStore, loading: boolean) => {
    return {
      ...state,
      searchResults: { ...state.searchResults, loading, error: undefined },
    };
  })
  //to do - remove
  .case(WAITING_TO_SEARCH_RESULTS, (state: QuotesStore) => {
    return {
      ...state,
      searchResults: { ...state.searchResults, loading: true },
    };
  })
  .case(
    SET_SEARCH_CANCEL_TOKEN,
    (state: QuotesStore, cancelToken: ApiCancelTokenSource) => {
      return {
        ...state,
        searchResults: { ...state.searchResults, cancelToken },
      };
    }
  )
  .case(
    SET_SEARCH_PARAMETERS,
    (state: QuotesStore, searchParameters: QuoteSearchParameters) => {
      return {
        ...state,
        searchResults: { ...state.searchResults, searchParameters },
      };
    }
  )
  .case(SET_SEARCH_RESULTS, (state: QuotesStore, searchResults: Quotes) => {
    let typesLoading = state.searchResults.quoteTypes === undefined;
    return {
      ...state,
      searchResults: {
        ...state.searchResults,
        loading: typesLoading,
        error: undefined,
        quoteData: searchResults,
        timestamp: new Date(),
        cancelToken: undefined,
        tableSettings: initialTableSettings,
      },
    };
  })
  .case(SET_SEARCH_ERROR, (state: QuotesStore, error: ErrorResponse) => {
    return {
      ...state,
      searchResults: {
        ...state.searchResults,
        loading: true,
        error,
        cancelToken: undefined,
      },
    };
  })
  .case(
    GET_QUOTE_TYPES.started,
    (state: QuotesStore, payload: boolean | undefined) => {
      return {
        ...state,
        searchResults: { ...state.searchResults, error: undefined },
      };
    }
  )
  .case(
    GET_QUOTE_TYPES.done,
    (
      state: QuotesStore,
      payload: Success<boolean | undefined, SalesQuoteType[] | undefined>
    ) => {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          error: undefined,
          quoteTypes: payload.result ? payload.result : [],
        },
      };
    }
  )
  .case(
    GET_QUOTE_TYPES.failed,
    (
      state: QuotesStore,
      payload: Failure<boolean | undefined, ErrorResponse>
    ) => {
      if (payload.error) {
        return {
          ...state,
          searchResults: {
            ...state.searchResults,
            loading: true,
            error: payload.error,
          },
        };
      } else {
        return {
          ...state,
          searchResults: { ...state.searchResults, error: undefined },
        };
      }
    }
  )
  .case(CANCEL_SEARCH_RESULTS, (state: QuotesStore) => {
    return {
      ...state,
      searchResults: {
        ...state.searchResults,
        cancelToken: undefined,
        loading: false,
        error: undefined,
      },
    };
  })
  .case(
    SET_SEARCH_RESULTS_TABLEPAGING,
    (state: QuotesStore, paging: SavedTablePagingSettings) => {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          tableSettings: {
            ...state.searchResults.tableSettings,
            paging,
          },
        },
      };
    }
  )
  .case(
    SET_SEARCH_RESULTS_TABLEFILTERS,
    (state: QuotesStore, filters: SavedTableFilterSettings) => {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          tableSettings: {
            ...state.searchResults?.tableSettings,
            filters,
            paging: {
              ...state.searchResults?.tableSettings?.paging,
              pageIndex: 0,
            },
            selectedIds: {},
          },
        },
      };
    }
  )
  .case(
    SET_SEARCH_RESULTS_TABLESORTBY,
    (state: QuotesStore, sortBy: Array<{ id: string; desc?: boolean }>) => {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          tableSettings: { ...state.searchResults?.tableSettings, sortBy },
        },
      };
    }
  )
  .case(
    SET_SEARCH_RESULTS_TABLESELECTEDROWS,
    (state: QuotesStore, selectedIds: Record<string, boolean>) => {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          tableSettings: { ...state.searchResults?.tableSettings, selectedIds },
        },
      };
    }
  )
  .case(SET_ACTIVE_QUOTE, (state: QuotesStore, quoteNumber: string) => {
    return { ...state, activeQuote: quoteNumber };
  })
  .case(SET_DETAILS_LOADING, (state: QuotesStore, loading: boolean) => {
    return {
      ...state,
      details: { ...state.details, loading, error: undefined },
    };
  })
  .case(
    SET_DETAILS_CANCEL_TOKEN,
    (state: QuotesStore, cancelToken: ApiCancelTokenSource) => {
      return { ...state, details: { ...state.details, cancelToken } };
    }
  )
  .case(CANCEL_DETAILS, (state: QuotesStore) => {
    return {
      ...state,
      details: {
        ...state.details,
        cancelToken: undefined,
        loading: false,
        error: undefined,
      },
    };
  })
  .case(CANCEL_DETAILS_TERMS, (state: QuotesStore) => {
    return {
      ...state,
      details: {
        ...state.details,
        cancelToken: undefined,
        loadingTerms: false,
        error: undefined,
      },
    };
  })
  .case(SET_CACHED_DETAILS, (state: QuotesStore, details: QuoteDetailData) => {
    return {
      ...state,
      details: {
        ...state.details,
        currentDetails: details,
      },
    };
  })
  .case(SET_DETAILS, (state: QuotesStore, data: QuoteDetails) => {
    let newMap = updateDetailsMap(
      data.quoteNumber + data.accountNumber?.replace(/^0+/, ""),
      DetailsCacheSize,
      state.details.latestDetails,
      data,
      undefined
    );
    return {
      ...state,
      details: {
        ...state.details,
        loading: false,
        error: undefined,
        cancelToken: undefined,
        latestDetails: newMap,
        currentDetails: { ...state.details?.currentDetails, data },
      },
    };
  })
  .case(SET_DETAILS_ERROR, (state: QuotesStore, error: ErrorResponse) => {
    return {
      ...state,
      details: {
        ...state.details,
        loading: true,
        error,
        cancelToken: undefined,
      },
    };
  })
  .case(
    SET_DETAILS_TERMS_LOADING,
    (state: QuotesStore, loadingTerms: boolean) => {
      return {
        ...state,
        details: { ...state.details, loadingTerms, error: undefined },
      };
    }
  )
  .case(
    SET_DETAILS_TERMS,
    (state: QuotesStore, terms: QuoteTermsAndConditions) => {
      let quoteNumber = state.activeQuote || "";
      let accountNumber =
        state.details.currentDetails.data?.accountNumber.replace(/^0+/, "");
      let newMap = updateDetailsMap(
        quoteNumber + accountNumber,
        DetailsCacheSize,
        state.details.latestDetails,
        undefined,
        terms
      );
      return {
        ...state,
        details: {
          ...state.details,
          loadingTerms: false,
          error: undefined,
          cancelToken: undefined,
          latestDetails: newMap,
          currentDetails: { ...state.details.currentDetails, terms },
        },
      };
    }
  )
  .case(SET_DETAILS_TERMS_ERROR, (state: QuotesStore, error: ErrorResponse) => {
    return {
      ...state,
      details: {
        ...state.details,
        loadingTerms: true,
        error,
        cancelToken: undefined,
      },
    };
  })
  .case(CLEAR_DETAILS, (state: QuotesStore) => {
    return {
      ...state,
      activeQuote: undefined,
      details: {
        ...state.details,
        cancelToken: undefined,
        loading: false,
        loadingTerms: false,
        error: undefined,
        currentDetails: {
          terms: undefined,
          data: undefined,
          timestamp: new Date(0),
        },
      },
    };
  })
  .case(
    DOCUMENTS_DOWNLOAD_CANCEL,
    (state: QuotesStore, id: string | undefined) => {
      let documentDownloads = { ...state.documents.documentDownloads };
      if (id && documentDownloads[id]) {
        if (documentDownloads[id]?.cancelToken) {
          documentDownloads[id]?.cancelToken?.cancel();
        }
        delete documentDownloads[id];
      }
      return { ...state, documents: { ...state.documents, documentDownloads } };
    }
  )
  .case(
    DOCUMENTS_DOWNLOAD_CANCEL_TOKEN,
    (state: QuotesStore, payload: QuoteDownloadCancelTokanParameters) => {
      let documentDownloads = { ...state.documents.documentDownloads };
      if (
        payload &&
        payload.id &&
        payload.cancelToken &&
        documentDownloads[payload.id]
      ) {
        documentDownloads[payload.id].cancelToken = payload.cancelToken;
      }
      return { ...state, documents: { ...state.documents, documentDownloads } };
    }
  )
  .case(
    DOCUMENTS_DOWNLOAD.started,
    (state: QuotesStore, payload: QuoteDocumentParameters | undefined) => {
      let documentDownloads = { ...state.documents.documentDownloads };
      if (payload && payload.id) {
        documentDownloads[payload.id] = {
          id: payload?.id,
          downloading: true,
          error: undefined,
          document: undefined,
          cancelToken: undefined,
        };
      }
      return { ...state, documents: { ...state.documents, documentDownloads } };
    }
  )
  .case(
    DOCUMENTS_DOWNLOAD.done,
    (
      state: QuotesStore,
      payload: Success<
        QuoteDocumentParameters | undefined,
        QuoteDocumentDownload
      >
    ) => {
      let documentDownloads = { ...state.documents.documentDownloads };
      if (
        payload.result.id &&
        state.documents.documentDownloads[payload.result.id]
      ) {
        documentDownloads[payload.result.id] = payload.result;
      }
      return { ...state, documents: { ...state.documents, documentDownloads } };
    }
  )
  .case(
    DOCUMENTS_DOWNLOAD.failed,
    (
      state: QuotesStore,
      payload: Failure<QuoteDocumentParameters | undefined, ErrorResponse>
    ) => {
      let documentDownloads = { ...state.documents.documentDownloads };
      if (payload.params?.id) {
        documentDownloads[payload.params?.id] = {
          ...documentDownloads[payload.params?.id],
          downloading: false,
          error: payload.error || {
            Message: "Unknown Error",
            Error: "1011",
            Detail: "An unknown error has occurred, please contact support.",
          },
          cancelToken: undefined,
        };
      }
      return { ...state, documents: { ...state.documents, documentDownloads } };
    }
  )
  .case(DOCUMENTS_CLEAR, (state: QuotesStore, id: string) => {
    if (id && state.documents.documentDownloads[id]) {
      let documentDownloads = { ...state.documents.documentDownloads };
      let url = documentDownloads[id].document?.url;
      if (url) window.URL.revokeObjectURL(url);
      delete documentDownloads[id];
      return { ...state, documents: { ...state.documents, documentDownloads } };
    }
    return state;
  })
  .case(DOCUMENTS_CLEARALL, (state: QuotesStore) => {
    let documentDownloads = { ...state.documents?.documentDownloads };
    if (documentDownloads) {
      Object.keys(documentDownloads)?.forEach((x) => {
        let url = documentDownloads[x].document?.url;
        if (url) window.URL.revokeObjectURL(url);
      });
    }

    return {
      ...state,
      documents: { ...state.documents, documentDownloads: {} },
    };
  })
  .case(
    DOCUMENTS_EMAIL.started,
    (state: QuotesStore, payload: QuoteDocumentParameters | undefined) => {
      return {
        ...state,
        documents: {
          ...state.documents,
          emailing: true,
          emailSuccess: undefined,
          emailError: undefined,
        },
      };
    }
  )
  .case(
    DOCUMENTS_EMAIL.done,
    (
      state: QuotesStore,
      payload: Success<QuoteDocumentParameters | undefined, EmailQuoteResponse>
    ) => {
      return {
        ...state,
        documents: {
          ...state.documents,
          emailing: false,
          emailSuccess: payload.result,
        },
      };
    }
  )
  .case(
    DOCUMENTS_EMAIL.failed,
    (
      state: QuotesStore,
      payload: Failure<QuoteDocumentParameters | undefined, ErrorResponse>
    ) => {
      let emailError: ErrorResponse = payload.error || {
        Message: "Unknown Error",
        Error: "1011",
        Detail: "An unknown error has occurred, please contact support.",
      };
      return {
        ...state,
        documents: {
          ...state.documents,
          emailing: false,
          emailError,
        },
      };
    }
  )
  .case(DOCUMENTS_EMAIL_CLEAR, (state: QuotesStore) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        emailing: false,
        emailSuccess: undefined,
        emailError: undefined,
      },
    };
  })
  .build();

export const QuotesDuck = {
  actions,
  reducer: QuoteSearchReducer,
};
