import {
  faCircleCheck,
  faCircleExclamation,
  faCircleQuestion,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "react-bootstrap";
import { SearchingIcon } from "../SearchingIcon";
import "./style.scss";
import {
  exisitingOrderDraft,
  updateOrderDraft,
} from "../../store/cart/dispatchers";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/app";
import { useCart } from "../../store/cart/useCart";
import { OrderDetails } from "../../models/Order";
import { useLocation, useNavigate } from "react-router-dom";
import {
  mapOrderDetailToNewOrder,
  mapOrderDetailToDescriptionAndBrand,
  deleteDraftOrders,
  getReOrderDraft,
  changeCurrentAccount,
} from "../../util/ordersHelper";
import { QuoteDetails } from "../../models/Quote";
import { ProfileDuck, UserProfile } from "store/userProfileStore";
import useUserProfile from "store/system/useUserProfile";

enum ModalCartStage {
  AddingToCart = "Adding To cart",
  ExistingDraftFound = "Existing Draft Found",
  AddedToCart = "Added To cart",
  Error = "Error",
}

enum Source {
  ExistingOrder = "ExistingOrder",
  ExistingQuote = "ExistingQuote",
}

const ModalCart: FC<{
  onClose: Function;
  details: OrderDetails | QuoteDetails;
}> = ({ onClose, details }): JSX.Element => {
  // const [loading, setLoading] = useState<boolean>(true);
  const [stage, setStage] = useState<any>(ModalCartStage.AddingToCart);
  const { state: user } = useUserProfile();
  const [orderData, setOrderData] = useState<any>(undefined);
  const [createOrderError, setCreateOrderError] = useState<{
    detail?: String;
    error?: string;
    message?: string;
  }>({});
  const [createOrderRes, setCreateOrderRes] = useState<any>({});
  const { orderDraft } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const createOrder = useCallback(() => {
    let source = location.pathname.toLocaleLowerCase().includes("quotes");
    mapOrderDetailToNewOrder(
      details,
      source ? Source.ExistingQuote : Source.ExistingOrder
    )
      .then((response: any) => {
        setCreateOrderRes({});
        const result = mapOrderDetailToDescriptionAndBrand(details, response);
        let reOrderAccountNumber = Number(details.accountNumber).toString();
        if (
          user.currentAccount?.accountNumber === reOrderAccountNumber
        ) {
          updateOrderDraft(response[0]);
        }
        setCreateOrderRes(result);
        setStage(ModalCartStage.AddedToCart);
      })
      .catch((error: any) => {
        setCreateOrderError(error.data || { message: "Something went wrong!" });
        setCreateOrderRes({});
        setStage(ModalCartStage.Error);
      });
  }, [details, user.currentAccount?.accountNumber, location.pathname]);

  useEffect(() => {
    let reOrderAccountNumber = Number(details.accountNumber).toString();
    if (user.currentAccount?.accountNumber !== reOrderAccountNumber) {
      getReOrderDraft(reOrderAccountNumber)
        .then((resp: any) => {
          setOrderData(resp);
        })
        .catch((noDraft: any) => {
          console.log("Triggered from Modal Cart Catch");
          if (noDraft) {
            createOrder();
          }
        });
    } else if (
      user.currentAccount?.accountNumber === reOrderAccountNumber
    ) {
      if (orderDraft.data) {
        setOrderData(orderDraft.data);
      } else {
        console.log("Triggered from Modal Cart else block");
        createOrder();
      }
    }
    // eslint-disable-next-line
  }, [details, user.currentAccount?.accountNumber, createOrder]);

  useEffect(() => {
    if (orderData !== undefined) {
      setStage(ModalCartStage.ExistingDraftFound);
    }
  }, [orderData]);

  const handleDelete = useCallback(() => {
    setStage(ModalCartStage.AddingToCart);
    deleteDraftOrders(orderData?.orderId)
      .then((resp) => {
        if (resp === true) {
          exisitingOrderDraft();
          createOrder();
        }
      })
      .catch((error: any) => {
        console.log("Error while Deleting", error);
      });
  }, [orderData, createOrder]);

  const modalTitle = useCallback(() => {
    switch (stage) {
      case ModalCartStage.AddingToCart:
        return <h4 className="modal-title">{stage}</h4>;

      case ModalCartStage.ExistingDraftFound:
        return (
          <h4 className="modal-title">
            <FontAwesomeIcon icon={faCircleQuestion} color="#ffd100" /> {stage}
          </h4>
        );

      case ModalCartStage.AddedToCart:
        return (
          <h4 className="modal-title">
            <FontAwesomeIcon icon={faCircleCheck} color="green" /> {stage}
          </h4>
        );

      case ModalCartStage.Error:
        return (
          <h4 className="modal-title">
            <FontAwesomeIcon icon={faCircleExclamation} color="red" /> {stage}
          </h4>
        );

      default:
        return null;
    }
  }, [stage]);

  const handleViewCart = useCallback(() => {
    changeCurrentAccount(user.identityApiId,parseInt(details.accountNumber)).then((r) => {
      if (r.data?.customers?.length > 0) {
        console.log(r.data?.customers[0])
        ProfileDuck.actions.SET_CURRENT_ACCOUNT(dispatch, {
          account: r.data.customers[0],
          user,
        });
        onClose();
        navigate("/order-entry");
      }
    })
  },[user.identityApiId]);

  const modalContent = useCallback(() => {
    switch (stage) {
      case ModalCartStage.AddingToCart:
        return (
          <ModalBody>
            <div className="modal-cart text-center">
              <SearchingIcon
                title={"Please wait while we configure your cart"}
              />
            </div>
          </ModalBody>
        );

      case ModalCartStage.ExistingDraftFound:
        return (
          <div className="draft-modal">
            <ModalBody>
              <b>
                A draft order already exists for account{" "}
                {Number(details.accountNumber).toString()}.
              </b>
              <br />
              <span>This action will replace the existing draft.</span>
              <br />
              <span>Do you want to continue?</span>
              <br />
            </ModalBody>
            <Modal.Footer>
              <Button className="btn-primary w-50" onClick={handleDelete}>
                Yes
              </Button>
              <Button className="btn-secondary w-50" onClick={() => onClose()}>
                No
              </Button>
            </Modal.Footer>
          </div>
        );

      case ModalCartStage.AddedToCart:
        return (
          <div className="addedToCart">
            <ModalBody>
              <>
                <p className="textLeft">
                  <strong>
                    {`(${createOrderRes[0].items.length}) `} Items added to cart
                    for account {createOrderRes[0].accountNumber}
                  </strong>
                </p>
                {createOrderRes[0]?.items?.length > 0 &&
                  createOrderRes[0].items.map((item: any) => {
                    return (
                      <div className="hideHr" key={item.materialNumber}>
                        <Row>
                          <Col className="textLeft">
                            <p className="m5">
                              Material Number : {item.materialNumber}{" "}
                            </p>
                            <p className="m5">Brand Name : {item.brandName || item?.brand?.name}</p>
                            <Row>
                              <Col lg={10}>
                                <p className="ml5">
                                  Description : {item.description}
                                </p>
                              </Col>
                              <Col lg={2} className="textRight">
                                <b>Quantity : {item.quantity}</b>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    );
                  })}
              </>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center", display: "block" }}>
              {user.currentAccount?.accountNumber !==
                Number(details?.accountNumber).toString() && (
                <Row>
                  <p>
                    <strong>
                      Clicking view cart will change your current active
                      account.
                    </strong>
                  </p>
                </Row>
              )}
              <Row>
                <Col lg={6}>
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CONTINUE
                  </Button>
                </Col>
                <Col lg={6}>
                  <Button
                    style={{ width: "100%" }}
                    variant="secondary"
                    onClick={handleViewCart}
                  > 
                    VIEW CART
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </div>
        );

      case ModalCartStage.Error:
        return (
          <div className="modalCartError">
            <ModalBody>
              <div style={{ textAlign: "left", padding: "10px" }}>
                <b>An error occurred while updating your cart.</b>
                <br />
                <b>{createOrderError.message}</b>
              </div>
            </ModalBody>
            <Modal.Footer>
              <Button style={{ width: "100%" }} onClick={() => onClose()}>
                OK
              </Button>
            </Modal.Footer>
          </div>
        );

      default:
        return null;
    }
  }, [
    stage,
    onClose,
    handleDelete,
    createOrderRes,
    details,
    navigate,
    user.currentAccount?.accountNumber,
    createOrderError.message,
  ]);

  return (
    <Modal
      dialogClassName="modal-lg"
      show={true}
      backdrop="static"
      centered={true}
    >
      <Modal.Header>
        {modalTitle()}
        <div className="d-flex justify-content-end">
          <button className="btn close" onClick={() => onClose()}>
            <FontAwesomeIcon icon={faTimes} />
            <span className="sr-only">Close</span>
          </button>
        </div>
      </Modal.Header>
      {modalContent()}
    </Modal>
  );
};
export default ModalCart;
