import { FC, useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { faUserCircle } from "@fortawesome/pro-duotone-svg-icons";
//import { useUserProfileState } from "../../contexts/UserProfileContext";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./style.scss";
import { useSelector } from "react-redux";
import { UserProfile } from "../../store/userProfileStore";
import { AppState } from "../../store/app";
import { AuthService } from "../../services/AuthService";

const UserMenuItem: FC<{}> = (): JSX.Element => {
  const user: UserProfile = useSelector<AppState, UserProfile>(
    (state) => state.userProfile
  );
  const authService = new AuthService();
  const [userType, setUserType] = useState<string | undefined>(undefined);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    let userType = user.userType?.name;
    let userName: string = "";
    let userEmail: string = "";
    if (user.firstName && user.lastName) {
      userName = user.firstName + " " + user.lastName;
      userEmail = user.userName;
    } else if (user.name) {
      userName = user.name;
      userEmail = user.userName;
    } else if (user.userName) {
      userName = user.userName;
    }
    setUserName(userName);
    setUserEmail(userEmail);
    setUserType(userType);
  }, [user]);
  const popovercontent = (
    <div className="text-center">
      <div className="user-avatar">
        {user.avatar ? (
          <img src={user.avatar} alt="user avatar" />
        ) : (
          <FontAwesomeIcon
            icon={faUserCircle}
            size="4x"
            className="hubbellUserCircle"
          />
        )}
      </div>
      <div className="user-name">{userName}</div>
      <div className="user-email">{userEmail}</div>
      <div className="user-type">
        {userType && userType.length ? userType : null}
      </div>
      <hr />
      <div className="d-grid">
        <Link
          className="btn btn-sm btn-primary btn-block"
          to="/user"
          onClick={() => document.body.click()}
        >
          View Profile
        </Link>
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            authService.signOut();
            document.body.click();
          }}
        >
          Sign Out
        </Button>
      </div>
    </div>
  );

  const pop = (
    <Popover id="user-popover">
      <PopoverBody>{popovercontent}</PopoverBody>
    </Popover>
  );

  return (
    <>
      {userName && (
        <OverlayTrigger
          trigger="click"
          rootClose={true}
          placement="bottom-end"
          overlay={pop}
          onToggle={(show: boolean) => setIsActive(show)}
        >
          <Button
            variant="clear"
            className={classNames({ active: isActive })}
            id="userPopoverTrigger"
          >
            <FontAwesomeIcon
              icon={faUserCircle}
              size="lg"
              className="hubbellUserCircle"
            />
            <span className="userType">
              <small className="sr-only">Signed in as:</small>
              <span className="userName" id="userName">
                {userName}
              </span>
            </span>
            <FontAwesomeIcon icon={faChevronDown} size="xs" />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};
export default UserMenuItem;
