import HubbellConnectOrder, {
  Address,
  Detail,
  Item,
  OrderAddressTypeName,
} from "../../models/HubbellConnectOrder";
import {
  AsyncStateObjectInitialStateFactory,
  AsyncStatus,
} from "../AsyncStateObject";
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import OrderEntryService from "../../services/OrderEntryService";
import CartState, { FormStatus } from "./models/CartState";
import {
  DocumentURLUpdateInterface,
  OrderAddressCall,
  UpdateOrderItemInterface,
  removeNotification,
} from "./dispatchers";
import { updateItem } from "../helpers";
import MaterialDataService from "../../services/MaterialDataService";
import { priceAndAvailabilitySearchSingleInterface } from "../../services/ApiServiceInterfaces";
import { LocalStorage } from "store/localStorage";

export const storeKey = "cart";

export const revertAll = createAction("REVERT_ALL");

export const initialState: CartState = {
  orderDraft: AsyncStateObjectInitialStateFactory(),
  checkoutValidity: {
    items: FormStatus.INVALID,
    shippingDetails: FormStatus.INVALID,
    orderDetails: FormStatus.INVALID,
  },
  loading: false,
  itemData: [],
  itemUpdateQueue: [],
  notificationToast: [],
  validatedItems: [],
  brands: []
};

const cachedState: CartState = LocalStorage.loadSerialized(storeKey);


export const getOrderDraftAccountNumber = createAsyncThunk(
  storeKey + "/getOrderDraftAccountNumber",
  async (args: string, thunkApi) => {
    const orderEntryServiceCall = new OrderEntryService();

    // let updatedQueue: any = [];
    let getPriceAndAvailablityList: any = [];
    try {
      let response = await orderEntryServiceCall.OrderDraftForAccountNumber(
        args
      );
      response.items.forEach((item) => {
        item.priceLastRefreshed = item.createdOn;
        item.inventoryLastRefreshed = item.createdOn;
        if (updateItem(item)) {
          // updatedQueue.push(item.orderItemId);
          const args: priceAndAvailabilitySearchSingleInterface = {
            item: {
              materialNumber: item?.materialNumber,
              quantity: Number(item?.quantity),
              salesOrg: item?.salesOrg,
              division: item?.division,
            },
            accountNumber: response.accountNumber,
            orderItemId: item.orderItemId,
          };
          getPriceAndAvailablityList.push(args);
        }
      });
      getPriceAndAvailablityList.forEach(
        (arg: priceAndAvailabilitySearchSingleInterface) =>
          thunkApi.dispatch(updatePriceAndAvailability(arg))
      );
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const updateAddressService = createAsyncThunk(
  storeKey + "/updateAddressService",
  async (args: OrderAddressCall, thunkApi) => {
    const orderEntryServiceCall = new OrderEntryService();
    const response = await orderEntryServiceCall.updateOrderEntryAddress(
      args.orderId,
      args.orderAddressId,
      args.suggestedData
    );
    return response;
  }
);

export const updatePriceAndAvailability = createAsyncThunk(
  storeKey + "/updatePriceAndAvailability",
  async (args: priceAndAvailabilitySearchSingleInterface, thunkApi) => {
    const materialDataService = new MaterialDataService();
    const payload: priceAndAvailabilitySearchSingleInterface = {
      item: args.item,
      accountNumber: args.accountNumber,
    };
    //const abortController = new AbortController();
    //const abortSignal = abortController.signal;
    let tries = 0;
    let response
    while (true) {
      try {
        response = await materialDataService.priceAndAvailabilitySingle(
          payload
        )
        return response.data;
      } catch (error: any) {
        if (tries === 3) {
          return thunkApi.rejectWithValue(
            error?.response?.data?.detail || error?.message || error
          );
        }
      }
      tries++
    }
  });

  

export const saveOrderItemToDraft = createAsyncThunk(
  storeKey + "/saveOrderItemToDraft ",
  async (args: UpdateOrderItemInterface, thunkApi) => {
    const orderEntryServiceCall = new OrderEntryService();
    let tries = 0;
    let response;
    if (args.orderId) {
      while (true) {
        try {
          response = await orderEntryServiceCall.updateOrderItem(
            args.orderId,
            args.item.orderItemId,
            args.item
          );
          return response;
        } catch (error: any) {
          //it will call the API 3 times before showing the error message
          if (tries === 3) {
            return thunkApi.rejectWithValue(
              error?.response?.data?.detail || error?.message || error
            );
          }
        }
        tries++;
      }
    }
  }
);

export const getPrice = createAsyncThunk(
  storeKey + "/getPrice",
  async (args: string, thunkApi) => {
    //getPrice service call
  }
);
export const getInventory = createAsyncThunk(
  storeKey + "/getInventory",
  async (args: string, thunkApi) => {
    //getInventory service call
  }
);
export const getPriceAndInventory = createAsyncThunk(
  storeKey + "/getPriceAndInventory",
  async (args: string, thunkApi) => {
    //getPriceAndInventory service call
  }
);

export const cartSlice = createSlice({
  name: storeKey,
  initialState: cachedState || initialState,
  reducers: {
    updateOrderItemsOnDelete: (
      state,
      action: PayloadAction<{ items: Partial<Item[]>; itemData: any[] }>
    ) => {
      const orderDraftItemsData: any = action.payload?.items;
      if (state.orderDraft.data !== undefined) {
        // state.itemData = state.itemData?.filter((item:any)=>{ return item.orderItemId !== action.payload.itemId})
        state.orderDraft.data.items = orderDraftItemsData;
        state.orderDraft.status = AsyncStatus.SUCCEEDED;
        state.orderDraft.error = undefined;
        state.itemData = action.payload.itemData;
      }
    },
    resetCart: (state) => {
      Object.assign(state, initialState);
    },
    updateCart: (state, action: PayloadAction<Partial<CartState>>) => {
      state = Object.assign({}, state, action.payload);
    },
    updateOrderDraft: (
      state,
      action: PayloadAction<Partial<HubbellConnectOrder>>
    ) => {
      const orderDraftData: any = action.payload;
      state.orderDraft.data = orderDraftData;
      state.orderDraft.status = AsyncStatus.LOADING;
      state.orderDraft.error = undefined;
    },
    existingUpdateOrderDraft: (state) => {
      const existingOrderDraftData: any = initialState;
      state.orderDraft.data = existingOrderDraftData;
      state.orderDraft.status = AsyncStatus.LOADING;
      state.orderDraft.error = undefined;
    },
    updateShippingAddress: (state, action: PayloadAction<Partial<Address>>) => {
      const addressIndex = state.orderDraft.data?.orderAddresses.findIndex(
        (item) => item.orderAddressType?.name === OrderAddressTypeName.ShipTo
      );
      const addresses = state.orderDraft.data?.orderAddresses.map((a: any) => {
        if (a.orderAddressType?.name !== OrderAddressTypeName.ShipTo) {
          return a;
        } else return action.payload;
      });
      if (addresses && addressIndex !== -1)
        state.orderDraft.data!.orderAddresses = addresses;
      else {
        state.orderDraft.data!.orderAddresses.push(action.payload as Address);
        console.error(storeKey + "/updateAddress: shipping address not found.");
      }
    },
    updateDraftItem: (
      state,
      action: PayloadAction<{
        item: Partial<Omit<Item, "orderItemId">>;
        itemId: string;
      }>
    ) => {
      const itemIndex: any = state.orderDraft?.data?.items.findIndex(
        (item) => item.orderItemId === action.payload.itemId
      );
      state.orderDraft?.data?.items.forEach((item: any) => {
        if (item.orderItemId === action.payload.itemId) {
          let qty = action.payload.item.quantity
            ? action.payload.item.quantity
            : item.quantity;
          item.lineNumber = action.payload.item.lineNumber;
          item.quantity = action.payload.item.quantity;
          item.referenceQuoteNumber = action.payload.item.referenceQuoteNumber;
          item.plantNumber = action.payload.item.plantNumber;
          item.plantDescription = action.payload.item.plantDescription;
          item.orderItemId = action.payload.itemId;
          item.extendedPrice = qty * item.unitPrice;
          item.inventory = action.payload.item.inventory;
          item.inventoryLastRefreshed =
            action.payload.item.inventoryLastRefreshed;
        }
        return item;
      });
      const items = state.orderDraft?.data?.items[itemIndex];
      if (items) {
        // state.orderDraft.data!.items = items;
        let updatedData: any = {};
        // updatedData.data = {...items[itemIndex], orderItemId:action.payload.itemId};
        updatedData.data = state.orderDraft?.data?.items[itemIndex];
        updatedData.status = AsyncStatus.IDLE;
        updatedData.error = undefined;
        itemIndex !== -1 && state.itemUpdateQueue?.push(updatedData);
      }
    },
    updateLocationAvailability: (
      state,
      action: PayloadAction<{
        item: Partial<Omit<Item, "orderItemId">>;
        itemId: string;
      }>
    ) => {
      state.orderDraft?.data?.items.forEach((item: any) => {
        if (item.orderItemId === action.payload.itemId) {
          item.plantNumber = action.payload.item.plantNumber;
          item.plantDescription = action.payload.item.plantDescription;
        }
      });
    },
    updateDraftOrderDetails: (state, action: PayloadAction<Detail>) => {
      state.orderDraft.error = undefined;
      if (action && action.payload)
        state.orderDraft.data!.details[0] = action.payload;
    },
    updatePrice: (state, action: PayloadAction<Partial<Address>>) => {
      //update price action
    },

    updateTotal: (state,
      action: PayloadAction<{
        itemId: string;
      }>) => {
      state.orderDraft.data?.items.forEach((item: any) => {
        if (item.orderItemId === action.payload.itemId) {
          item.extendedPrice = `${0.00}`
          item.quantity = "";
          
        }
      })
    },
    updateInventory: (state, action: PayloadAction<Partial<Address>>) => {
      //update price action
    },
    updatePriceAndInventory: (
      state,
      action: PayloadAction<Partial<Address>>
    ) => {
      //update price action
    },
    updateDocumentURL: (state, action: PayloadAction<DocumentURLUpdateInterface>) => {
      state.orderDraft.data?.details.forEach((item) => {
        if(item.orderDetailId === action.payload.orderDetailId){
          item.poAttachmentUrl = action.payload.documentURL;
        }
      })
    },
    resetDocumentURL: (state, action: PayloadAction<string>) => {
      state.orderDraft.data?.details.forEach((item) => {
        if(item.orderDetailId === action.payload){
          item.poAttachmentUrl = "";
        }
      })
    },
    setCartValidityStatus: (
      state,
      action: PayloadAction<Partial<CartState["checkoutValidity"]>>
    ) => {
      state.checkoutValidity = Object.assign(
        {},
        state.checkoutValidity,
        action.payload
      );
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      let index: any = state.notificationToast?.findIndex(
        (item) => item.id === action.payload
      );
      state.notificationToast?.splice(index, 1);
    },
    updateValidatedItems: (
      state,
      action: PayloadAction<{ items: Partial<Item[]> }>
    ) => {
      const validateItems: any = action.payload?.items;
      state.validatedItems = validateItems;
    },
    updateValidatedItemsOnDelete: (
      state,
      action: PayloadAction<{ items: Partial<Item[]>; itemId: string }>
    ) => {
      state.validatedItems = state.validatedItems?.filter((item: any) => {
        return item.orderItemId !== action.payload.itemId;
      });
    },
    setBrands: ( state, action: PayloadAction<String>) => {
      const brand = action.payload;
      if(state.orderDraft?.data?.items.length !== state.brands?.length) {
          state.brands?.push(brand);
      }
    },
    setOrderDetails: (state, action: PayloadAction<Detail[]>) => {
      if (action && action.payload)
        state.orderDraft.data!.details = action.payload
    }
  },
  extraReducers(builder) {
    builder.addCase(getOrderDraftAccountNumber.pending, (state) => {
      state.orderDraft.status = AsyncStatus.LOADING;
      state.orderDraft.error = undefined;
      state.loading = true;
      state.checkoutValidity.items = FormStatus.INVALID;
      state.checkoutValidity.shippingDetails = FormStatus.INVALID;
      state.checkoutValidity.orderDetails = FormStatus.INVALID;
      state.itemData = [];
    });
    builder.addCase(
      getOrderDraftAccountNumber.fulfilled,
      (state, { payload }: { payload: HubbellConnectOrder }) => {
        state.orderDraft.status = AsyncStatus.SUCCEEDED;
        state.orderDraft.data = payload;
        state.loading = false;
        if (payload?.items?.length === 0) {
          state.checkoutValidity.items = FormStatus.INVALID;
          state.checkoutValidity.shippingDetails = FormStatus.INVALID;
          state.checkoutValidity.orderDetails = FormStatus.INVALID;
        } else {
          state.checkoutValidity.items = FormStatus.VALID;
        }
      }
    );
    builder.addCase(getOrderDraftAccountNumber.rejected, (state, thunkApi) => {
      state.orderDraft.status = AsyncStatus.FAILED;
      state.orderDraft.data = undefined;
      state.orderDraft.error =
        thunkApi.error.message ||
        "An error occurred while fetching order draft";
      console.error(thunkApi.error, thunkApi.meta.arg);
      state.checkoutValidity.items = FormStatus.INVALID;
      state.checkoutValidity.shippingDetails = FormStatus.INVALID;
      state.checkoutValidity.orderDetails = FormStatus.INVALID;
    });
    builder.addCase(updateAddressService.pending, (state) => {
      state.orderDraft.status = AsyncStatus.LOADING;
      state.orderDraft.error = undefined;
      state.loading = true;
    });
    builder.addCase(
      updateAddressService.fulfilled,
      (state, { payload }: { payload: Address }) => {
        console.log("updateAddressService..payload", payload);
        const addressIndex = state.orderDraft.data?.orderAddresses.findIndex(
          (item) => item.orderAddressType?.name !== OrderAddressTypeName.ShipTo
        );
        state.orderDraft.status = AsyncStatus.SUCCEEDED;
        state.orderDraft.error = undefined;
        const addresses = state.orderDraft.data?.orderAddresses.map(
          (a: any) => {
            if (a.orderAddressType?.name !== OrderAddressTypeName.ShipTo) {
              return a;
            } else return payload;
          }
        );
        if (addresses && addressIndex !== -1) {
          state.orderDraft.data!.orderAddresses = addresses;
        } else {
          state.orderDraft.data!.orderAddresses.push(payload);
        }
        state.loading = false;
      }
    );
    builder.addCase(updateAddressService.rejected, (state, thunkApi) => {
      state.orderDraft.status = AsyncStatus.FAILED;
      state.orderDraft.error =
        thunkApi.error.message || "An error occurred while updating address";
      console.error(thunkApi.error, thunkApi.meta.arg);
    });
    builder.addCase(updatePriceAndAvailability.pending, (state, { meta }) => {
      const itemData: any = meta.arg;
      const updatedItemdata: any = {};
      updatedItemdata.materialNumber = itemData.item.materialNumber;
      updatedItemdata.orderItemId = itemData.orderItemId;
      updatedItemdata.status = AsyncStatus.LOADING;
      updatedItemdata.updateType = "price-inventory";
      updatedItemdata.error = undefined;
      updatedItemdata.data = undefined;
      state.itemData?.push(updatedItemdata);
    });
    builder.addCase(
      updatePriceAndAvailability.fulfilled,
      (state, { meta, payload }) => {
        const materialResult = payload.materialResults?.length > 0 && payload?.materialResults[0];
        const validationResults = payload?.validationResults;
        const orderItemId: any = meta?.arg;
        let updatedQueueItem: any = {
          status: AsyncStatus.IDLE,
          data: undefined,
        };
        state.orderDraft.data?.items.forEach((item) => {
          if (item?.materialNumber === materialResult?.materialNumber) {
            let qty: any = item?.quantity ? item?.quantity : 0;
            item.extendedPrice = materialResult?.price * qty + "";
            item.unitPrice = Number(materialResult.price).toFixed(2).toString(); // not getting unitPrice from P&A response
            item.catalogNumber = materialResult.catalogNumber;
            item.description = materialResult.description;
            item.minimumOrderQty = materialResult.minimumOrderQty;
            item.baseUnitOfMeasure = materialResult.baseUnitOfMeasure;
            item.standardPackageQty = materialResult.standardPackageQty;
            item.palletQty = materialResult.palletQty;
            item.caseQty = materialResult.caseQty;
            item.cartonQty = materialResult.cartonQty;
            item.grossWeight = materialResult.grossWeight;
            item.inventory = materialResult.inventory;
            item.priceLastRefreshed = new Date().toISOString();
            item.inventoryLastRefreshed = new Date().toISOString();
          }
        });
        let itemIndex: any = state.orderDraft.data?.items.findIndex(
          (item) => item?.materialNumber === materialResult?.materialNumber
        );
        updatedQueueItem.data = state.orderDraft.data?.items[itemIndex];
        state.itemData?.forEach((item: any) => {
          if (item?.materialNumber === materialResult?.materialNumber) {
            item.status = AsyncStatus.SUCCEEDED;
            item.data = materialResult;
            item.orderItemId = orderItemId.orderItemId;
            item.materialNumber = materialResult.materialNumber;
            item.validationResults = validationResults;
          } else if (
            payload?.materialResults?.length === 0 &&
            orderItemId.orderItemId === item.orderItemId
          ) {
            item.status = AsyncStatus.FAILED;
            item.data = payload;
            item.orderItemId = orderItemId.orderItemId;
            item.materialNumber =
              payload?.serviceErrorsReturned[0]?.materialNumber;
          }
        });
        if (updatedQueueItem) {
          state.itemUpdateQueue?.push(updatedQueueItem);
        }
      }
    );
    builder.addCase(updatePriceAndAvailability.rejected, (state, thunkApi) => {
      state.orderDraft.data?.items.forEach((item) => {
        if (thunkApi.meta.arg.orderItemId === item.orderItemId) {
          item.hasError = true;
        }
      })
      state.itemData?.forEach((item: any) => {
        if (thunkApi.meta.arg.orderItemId === item.orderItemId) {
          item.status = AsyncStatus.FAILED;
          item.data = undefined;
          item.error =
            thunkApi.error.message ||
            "An error occurred while retrieving Price and Availabilty";
        }
      });
      console.error(thunkApi.error, thunkApi.meta.arg);
    });
    builder.addCase(saveOrderItemToDraft.pending, (state, { meta }) => {
      let index: any = state.itemUpdateQueue?.findIndex(
        (item) => item.data?.orderItemId === meta.arg.item.orderItemId
      );
      if (index !== -1 && state.itemUpdateQueue)
        state.itemUpdateQueue[index].status = AsyncStatus.LOADING;
    });
    builder.addCase(saveOrderItemToDraft.fulfilled, (state, { payload }) => {
      let popIndex: any = state.itemUpdateQueue?.findIndex(
        (item) => item.data?.orderItemId === payload?.orderItemId
      );
      state.itemUpdateQueue?.splice(popIndex, 1);
    });
    builder.addCase(saveOrderItemToDraft.rejected, (state, thunkApi) => {
      let index: any = state.itemUpdateQueue?.findIndex(
        (item) =>
          item.data?.orderItemId === thunkApi.meta.arg?.item?.orderItemId
      );
      if (index !== -1 && state.itemUpdateQueue) {
        state.itemUpdateQueue?.splice(index, 1);
        let time = new Date();
        let id = time.getTime().toString();
        let error: any = {
          id,
          toastMessage: thunkApi?.payload
            ? thunkApi?.payload
            : "Error while updating database",
        };
        state.notificationToast?.push(error);
        setTimeout(() => {
          removeNotification(id);
        }, 2000);
      }
    });
    builder.addCase(getPrice.pending, (state) => { });
    builder.addCase(getPrice.fulfilled, (state) => { });
    builder.addCase(getPrice.rejected, (state) => { });
    builder.addCase(getInventory.pending, (state) => { });
    builder.addCase(getInventory.fulfilled, (state) => { });
    builder.addCase(getInventory.rejected, (state) => { });
    builder.addCase(getPriceAndInventory.pending, (state) => { });
    builder.addCase(getPriceAndInventory.fulfilled, (state) => { });
    builder.addCase(getPriceAndInventory.rejected, (state) => { });
  },
});