import { AxiosError, AxiosResponse } from "axios";
import {
  UserIdentityResponseInterface,
  UserSearchResponse,
  UserTypeInterface,
} from "../services/ApiServiceInterfaces";
import { UserRecord } from "../store/systemStore";
import ApiService, { ApiCancelTokenSource } from "./ApiService";
import { UserSearchInterface } from "./ApiServiceInterfaces";
import UserAccountService from "./UserAccountService";

const connectAPIBase = process.env.REACT_APP_HUBBELLCONNECT_API_BASE;
const hubbellConnect_Country =
  process.env.REACT_APP_COUNTRY_CODE?.length === 2
    ? process.env.REACT_APP_COUNTRY_CODE
    : "US";

class SystemService extends ApiService {
  async getSystemUser(cancelTokenSource?: ApiCancelTokenSource): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Country"] = hubbellConnect_Country;

    const result = await this.connection.get("ApplicationUser/GetSystemUser", {
      baseURL: connectAPIBase,
      params: { email: this.userName, name: this.authAccountName },
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });

    return result.data;
  }

  async getUserRecord(
    userName: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Country"] = hubbellConnect_Country;

    const result = await this.connection.get("ApplicationUser", {
      baseURL: connectAPIBase,
      params: { userName },
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });

    return result.data;
  }

  async postUserRecord(
    user: Partial<UserRecord>,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Country"] = hubbellConnect_Country;

    const url = connectAPIBase + `ApplicationUser`;
    const result = await this.connection.post(url, user, {
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });

    return result.data;
  }

  async getUserDetails(
    userName?: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<UserIdentityResponseInterface> {
    const userService = new UserAccountService();
    return await userService
      .getAssignedSoldToAccountsSummarybyEmail(
        userName || this.userName,
        cancelTokenSource
      )
      .then((response: AxiosResponse<UserIdentityResponseInterface>) => {
        if (response.status === 200 && response.data) {
          return response.data;
        } else {
          console.error(
            "Response was successful, but no data was returned.",
            response
          );
          throw new Error("Unable to retrieve details for this user.");
        }
      })
      .catch((error: AxiosError) => {
        console.error(error.toJSON());
        throw new Error("Unable to retrieve details for this user.");
      });
  }

  async getUserType(
    userName?: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<UserTypeInterface> {
    const userService = new UserAccountService();
    return await userService
      .getUserTypeByID(userName || this.userName, cancelTokenSource)
      .then((response: AxiosResponse<UserTypeInterface>) => {
        if (response.status === 200 && response.data) {
          return response.data;
        } else {
          throw new Error("Unable to retrieve role for this user.");
        }
      })
      .catch((error: AxiosError) => {
        throw new Error("Unable to retrieve role details for this user.");
      });
  }

  async users(
    query: UserSearchInterface,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<UserSearchResponse> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Country"] = hubbellConnect_Country;

    const result = await this.connection.get("ApplicationUser/Find", {
      baseURL: connectAPIBase,
      params: query,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });

    return result.data;
  }

  async userModules(
    userid: string | number,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Country"] = hubbellConnect_Country;

    const result = await this.connection.get("UserFunctionModule", {
      baseURL: connectAPIBase,
      params: { userName: userid },
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async functionModules(
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Country"] = hubbellConnect_Country;

    const result = await this.connection.get("FunctionModule", {
      baseURL: connectAPIBase,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data;
  }

  async recordLogon(
    userName: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<any> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Country"] = hubbellConnect_Country;

    let apiUrl =
      connectAPIBase +
      "ApplicationUser/RecordLogon/" +
      encodeURIComponent(userName);
    const result = await this.connection.put(apiUrl, null, {
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });

    return result.data;
  }
}

export default SystemService;
