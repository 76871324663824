import { Document, ResponseData } from "../services/ApiServiceInterfaces";
import { ProductImage } from "./Product";
import { Address as UserAddress, Brand } from "./UserAccount";

export interface ProductDetails {
  salesOrg: string;
  division: string;
}

export interface Orders {
  salesOrders: SalesOrder[];
  skippedSalesOrders?: SkippedSalesOrders[];
}

export interface SkippedSalesOrders {
  orderNumber: string;
  evaluationResult: {
    message: string;
    reason: string;
    callingFunction: string;
    operationName: string;
  }
}

export interface SalesOrder extends ProductDetails {
  brandName: string;
  orderTotal: string;
  purchaseOrderNumber: string;
  salesOrderDate: string;
  salesOrderNumber: string;
  salesOrderStatus: string;
  currency: string;
}

export interface SalesOrderExport  {
  accountNumber: string;
  accountInfo: string;
  location: string;
  brandName: string;
  purchaseOrderNumber: string;
  salesOrderNumber: string;
  salesOrderStatus: string;
  orderValue: string;
  orderTotal: string;
  orderDate: string;
}

export interface OrderNote {
  note: string;
}
export interface OrderDetails {
  brand: Brand;
  salesOrderNumber: string;
  date: string;
  total: string;
  status: string;
  currency: string;
  purchaseOrderNumber: string;
  accountNumber: string;
  statusMessage: string;
  soldTo?: {
    name: string;
    address: Address;
  };
  shipTo: {
    name: string;
    address: Address;
  };
  billTo: {
    name: string;
    address: Address;
  };
  items: Item[];
  notes: OrderNote[];
  terms?: OrderTermsOfSale;
}

export interface Address extends Partial<UserAddress> {
  houseNumber: string;
  street: string;
  poBox: {
    number: string;
    city: string;
    region: string;
    postal: string;
    country: string;
  };
  deliveryLine: string;
}

export interface Item {
  itemNumber: string;
  productNumber: string;
  customerProductNumber: string;
  description: string;
  quantity: string;
  unitPrice: string;
  taxAmount: string;
  lineTotal: string;
  currency: string;
  estShipDate: string;
  reqShipDate: string;
  unitCount: string;
  salesUnit: string;
  status: string;
  quoteNumber: string;
  shipments: {
    documentNumber: string;
    itemNumber: string;
    dateShipped: string;
    timeShipped: string;
    quantityShipped: string;
    tracking: {
      trackingNumber: string;
      carrier: string;
      carrierURL: string;
    }[];
  }[];
  ProductDetailData?: { [key: string]: any };

  image?: ProductImage; // TODO: validate URL for picture?
}

export enum OrderDocumentTypes {
  "ack" = "Order Acknowledgement",
  "invoice" = "Invoice",
  "shipment" = "Shipment",
  "packing" = "Packing Slip",
}

export interface OrderDocument extends Document {}

export interface EmailOrderResponse extends ResponseData<any> {}

export interface DownloadOrderResponse extends ResponseData<Document> {}

export interface OrderTermsOfSale {
  paymentTerms: Array<string>;
  shippingTerms: Array<string>;
}

export interface OrderTermsOfSaleResponse extends OrderTermsOfSale {
  salesOrderNumber: string;
}
