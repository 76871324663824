import ApiService, { ApiCancelTokenSource } from "./ApiService";
import {
  EmailOrderResponse,
  DownloadOrderResponse,
  OrderDetails,
  OrderDocumentTypes,
  OrderTermsOfSaleResponse,
} from "../models/Order";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../models/Error";
import { FileHelper } from "../util/fileHelper";

const coreAPIM_SRIBase = process.env.REACT_APP_SRI_API_BASE;
const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;
class CustomerOrderDetailsService extends ApiService {
  async GetOrderDetails(
    salesOrderNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<OrderDetails> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    let result = await this.connection.get(`salesorder/${salesOrderNumber}`, {
      baseURL: coreAPIM_SRIBase,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result.data as OrderDetails;
  }

  private getDownloadApiUriByDocType(docType: OrderDocumentTypes): string {
    let docTypeUri = "";
    switch (docType) {
      case OrderDocumentTypes.ack:
        docTypeUri = "pdf";
        break;
      case OrderDocumentTypes.invoice:
        docTypeUri = "invoices";
        break;
      case OrderDocumentTypes.packing:
        docTypeUri = "packinglists";
        break;
      case OrderDocumentTypes.shipment:
        docTypeUri = "shippingdocs";
        break;
      default:
        docTypeUri = "pdf";
        break;
    }

    return docTypeUri;
  }

  private getEmailApiDocTypeNameByDocType(docType: OrderDocumentTypes): string {
    let name = "";
    switch (docType) {
      case OrderDocumentTypes.ack:
        name = "acknowledgement";
        break;
      case OrderDocumentTypes.invoice:
        name = "invoice";
        break;
      case OrderDocumentTypes.packing:
        name = "packing_slip";
        break;
      case OrderDocumentTypes.shipment:
        name = "shipment";
        break;
      default:
        name = "acknowledgement";
        break;
    }

    return name;
  }

  async GetOrderDocuments(
    salesOrderNumber: string,
    accountNumber: string,
    docType: OrderDocumentTypes,
    itemsIds?: string[],
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<DownloadOrderResponse> {
    let docTypeUri = this.getDownloadApiUriByDocType(docType);
    let downloadApiRelPath = `salesorder/${salesOrderNumber}/${docTypeUri}?AccountNumber=${accountNumber}`;
    if (itemsIds && itemsIds.length > 0)
      downloadApiRelPath += `&lines=${itemsIds.join()}`;

    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }

    let result = await this.connection
      .get(downloadApiRelPath, {
        baseURL: coreAPIM_SRIBase,
        headers: headers,
        responseType: "blob",
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: AxiosResponse) => {
        return resp;
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          return error.response;
        } else {
          throw new Error(error.message);
        }
      });
    let fileHelper = new FileHelper<Document>();
    let downloadResponse = await fileHelper
      .parseDownloadApiResponse(salesOrderNumber, result)
      .then((downloadResponse) => {
        return downloadResponse;
      })
      .catch((error: Error) => {
        throw error;
      });
    return downloadResponse;
  }

  async EmailOrderDocuments(
    email: string[],
    salesOrderNumber: string,
    accountNumber: string,
    docType: OrderDocumentTypes,
    cc: string[],
    itemsIds?: string[],
    senderNameOrEmail?: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<EmailOrderResponse> {
    //to do udpate with API-M URL & path
    let apiBaseUrl = process.env.REACT_APP_QUEUEFUNCTIONS_BASE;
    let emailApiRelPath = `api/QueueDocumentsToEmailHTTPTrigger?${process.env.REACT_APP_QUEUEFUNCTIONS_KEYNAME}=${process.env.REACT_APP_QUEUEFUNCTIONS_KEYVALUE}`;

    let apiData = {
      accountNumber: accountNumber,
      documentType: this.getEmailApiDocTypeNameByDocType(docType),
      lineItemNumbers: itemsIds,
      to: email,
      cc: cc,
      salesOrderNumber: salesOrderNumber,
      senderEmail: senderNameOrEmail === undefined ? "" : senderNameOrEmail,
    };

    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    let result = await this.connection
      .post(emailApiRelPath, JSON.stringify(apiData), {
        baseURL: apiBaseUrl,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      })
      .then((resp: AxiosResponse) => {
        return resp;
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          return error.response;
        } else {
          throw new Error(error.message);
        }
      });

    let emailResult = undefined;
    if (result.status === 200) {
      emailResult = {
        Error: {
          Error: "",
          Message: "",
          Detail: "",
        },
        Results: "Requested document(s) will be emailed shortly.",
      };
    } else {
      emailResult = {
        Error: result.data as ErrorResponse,
        Results: undefined,
      };
      if (emailResult.Error && emailResult.Error.Detail) {
        let details = JSON.parse(emailResult.Error.Detail) as string[];
        if (details && details.length > 0) {
          emailResult.Error.Detail = details.join(". ");
        }
      }
    }
    return emailResult;
  }

  async GetOrderTerms(
    orderNumber: string,
    cancelTokenSource?: ApiCancelTokenSource
  ): Promise<OrderTermsOfSaleResponse> {
    const headers: { [key: string]: any } =
      await this.getAuthorizationHeaders();
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    const result = await this.connection.get(
      `quotes/${orderNumber}/termsofsale`,
      {
        baseURL: coreAPIM_SRIBase,
        headers: headers,
        cancelToken: cancelTokenSource?.token,
      }
    );
    return result.data;
  }
}

export default CustomerOrderDetailsService;
