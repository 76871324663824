import SearchTypes from "features/OrderSearch/store/models/SearchType";
import { ControlledTableState } from "features/common/components/Table/models";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";
import { AsyncStateObject } from "store/AsyncStateObject";

export interface OrderSearchApiResult
  extends SapRfcIntegrationApi.SalesOrder.Output.SalesOrder {}

// must serialize dates manually for redux state
export interface OrderSearchQueryState {
  type: SearchTypes | undefined;
  accountNumber: string | undefined;
  fromDate: string | undefined; // ISO date string
  toDate: string | undefined; // ISO date string
  materialNumber: string | undefined;
  orderNumber: string | undefined;
  purchaseOrderNumber: string | undefined;
  openOnly: boolean | undefined;
  isCustomerProductNumber: boolean | undefined;
}
export default interface OrderSearchState {
  query: OrderSearchQueryState;
  searchResult: AsyncStateObject<OrderSearchApiResult[]>;
  tableState: ControlledTableState | undefined;
}

export const initialOrderSearchQueryState: OrderSearchQueryState = {
  type: SearchTypes.SONumber,
  accountNumber: undefined,
  fromDate: undefined,
  toDate: undefined,
  materialNumber: undefined,
  orderNumber: undefined,
  purchaseOrderNumber: undefined,
  openOnly: undefined,
  isCustomerProductNumber: undefined,
};
