// determine if the current orders brand has shipment docs available

import UserAccountService from "services/UserAccountService";
import OrderEntryService from "../services/OrderEntryService";

const shipmentGroups = [{ salesOrg: "C520", division: "5W" }];

export function getShipmentDocs(salesOrg: string, headerDivision: string) {
  return (
    shipmentGroups.find(
      (i: any) => i.salesOrg === salesOrg && i.division === headerDivision
    ) !== undefined
  );
}

export function displayCartItem(system: any) {
  const orderEntryModule = system.findUserModule("order-entry");
  return orderEntryModule ? orderEntryModule : false;
}

export async function mapOrderDetailToNewOrder(
  orderDetailsData: any,
  source: string
) {
  let itemsPayload: any[] = [];
  orderDetailsData?.items &&
    orderDetailsData?.items[0].forEach((item: any) => {
      const modifiedItem: any = {};
      modifiedItem.materialNumber = item.materialNumber;
      modifiedItem.quantity = parseInt(item.quantity);
      modifiedItem.lineNumber = item.lineNumber;
      modifiedItem.salesOrg = item.salesOrg;
      modifiedItem.division = item.division;
      modifiedItem.referenceQuoteNumber = item.referenceQuoteNumber;
      modifiedItem.referenceOrderNumber = item.referenceOrderNumber;
      modifiedItem.description = item.description;
      modifiedItem.brand = item.brand;
      itemsPayload.push(modifiedItem);
    });
  const body = {
    source: source,
    accountNumber: Number(orderDetailsData?.accountNumber).toString(),
    items: itemsPayload,
  };

  const orderEntryServiceCall = new OrderEntryService();
  return await orderEntryServiceCall
    .createOrderDraft(body)
    .then((resp: any) => {
      if (resp?.status === 200) {
        return resp.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export async function validateOrderItem(orderItemId: string) {
  const orderEntryServiceCall = new OrderEntryService();
  return await orderEntryServiceCall
    .validateOrderItem(orderItemId)
    .then((resp: any) => {
      if (resp?.status === 200) {
        return resp.data;
      }
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
}

export function mapOrderDetailToDescriptionAndBrand(
  orderDetailsData: any,
  createOrderResponse: any
) {
  debugger
  createOrderResponse[0]?.items?.forEach((item: any, index: any) => {
    if (
      orderDetailsData?.items[0][index]?.materialNumber === item.materialNumber
    ) {
      item.description = orderDetailsData?.items[0][index]?.description;
      item.brandName = orderDetailsData?.items[0][index]?.brandName || orderDetailsData?.items[0][index]?.brand?.name;
    }
  });
  return createOrderResponse;
}

export const deleteDraftOrders = async (orderId: string) => {
  const orderEntryServiceCall = new OrderEntryService();
  return await orderEntryServiceCall.deleteOrder(orderId).then((resp: any) => {
    if (resp?.status === 200) {
      return true;
    } else {
      return false;
    }
  });
};

export const getReOrderDraft = (args: string) => {
  const orderEntryServiceCall = new OrderEntryService();
  const response = orderEntryServiceCall.OrderDraftForAccountNumber(args);
  return response;
};

export const changeCurrentAccount = (userId: string, account: number) => {
  
  const userAccountService = new UserAccountService();
  return userAccountService
    .findUserAccount(
      { userId: userId, accountNumber: account.toString() },
      // user.identityApiId
      userAccountService.generateSourceToken()
    )
    .then((r) => {
      debugger;
        return  r
    });
    // .catch((e: ApiError) => {
    //   console.error(e.message, e.response?.data);
    //   let msg = e.message;
    //   return undefined
    // });
};
