import { ApiError, ApiResponse } from "services/ApiService";
import { ResponseData } from "services/models/TypedResponse";
import { AbstractService } from "./AbstractService";
import Document from "../models/Document";
import { FileHelper } from "services/FileHelper";
import ErrorResponse from "services/models/ErrorResponse";
import { format } from "date-fns";
import SalesDocumentAddress from "services/models/SalesDocumentAddress";

export namespace SalesOrder {
  export namespace Input {
    export interface SearchByOrderNumber {
      purchaseOrderNumber?: string;
      orderNumber?: string;
    }

    export interface SearchBySoldToAccount {
      accountNumber: string;
      fromDate: Date; // YYYYMMDD
      toDate: Date; // YYYYMMDD
      openOnly: boolean;
    }

    export interface SearchByMaterialNumber extends SearchBySoldToAccount {
      productNumber: string;
      isCustomerProductNumber: boolean;
    }

    export enum DocumentTypes {
      "acknowledgement" = "Order Acknowledgement",
      "invoice" = "Invoice",
      "shipment" = "Shipment",
      "packing" = "Packing Slip",
    }
    export type SearchAll =
      | SearchByOrderNumber
      | SearchBySoldToAccount
      | SearchByMaterialNumber;
  }

  export namespace Output {
    export type Search = {
      salesOrders: SalesOrder[];
      skippedSalesOrders?: SkippedSalesOrder[];
    };
    export interface SkippedSalesOrder {
      orderNumber: string;
      evaluationResult: {
        message: string;
        reason: string;
        callingFunction: string;
        operationName: string;
      };
    }
    export interface SalesOrder {
      salesOrg: string;
      division: string;
      brandName: string;
      orderTotal: string;
      purchaseOrderNumber: string;
      salesOrderDate: string;
      salesOrderNumber: string;
      salesOrderStatus: string;
      currency: string;
      customerAccount: {
        accountNumber: string;
        name: string;
        city: string;
        region: string;
        branch: string;
      };
    }
    export type SalesOrderDetail = {
      brand: Brand;
      salesOrderNumber: string;
      date: string;
      total: string;
      status: string;
      currency: string;
      purchaseOrderNumber: string;
      accountNumber: string;
      statusMessage: string;
      soldTo?: {
        name: string;
        address: SalesDocumentAddress;
      };
      shipTo: {
        name: string;
        address: SalesDocumentAddress;
      };
      billTo: {
        name: string;
        address: SalesDocumentAddress;
      };
      items: Item[];
      notes: OrderNote[];
    };
    export interface Brand {
      division: string;
      salesOrg: string;
      name: string;
    }
    export interface OrderNote {
      note: string;
    }

    export interface Item {
      itemNumber: number;
      productNumber: string;
      customerProductNumber: string;
      description: string;
      quantity: number;
      unitPrice: number;
      taxAmount: number;
      lineTotal: number;
      currency: string;
      estShipDate: string;
      reqShipDate: string;
      unitCount: number;
      salesUnit: string;
      status: string;
      quoteNumber: string;
      shipments: ItemShipment[];
    }

    export interface ItemShipment {
      documentNumber: string;
      itemNumber: string;
      dateShipped: string;
      timeShipped: string;
      quantityShipped: number;
      tracking: ItemShipmentTracking[];
    }

    export interface ItemShipmentTracking {
      trackingNumber: string;
      carrier: string;
      carrierURL: string;
    }
    export type TermsOfSale = {
      salesOrderNumber: string;
      paymentTerms: Array<string>;
      shippingTerms: Array<string>;
    };
  }

  export class Service extends AbstractService {
    /**
     *
     * @description Legacy search api does not include shared parts
     * @param query
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async search(
      query:
        | Omit<Input.SearchByOrderNumber, "purchaseOrderNumber">
        | Input.SearchBySoldToAccount
        | Input.SearchByMaterialNumber,
      abortSignal?: AbortSignal
    ) {
      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection.get("salesorder/search", {
        baseURL: this.baseUrl,
        headers: headers,
        params: query,
        signal: abortSignal,
      });
      let data = result.data?.salesOrders?.filter(
        (o: Output.SalesOrder) => o.salesOrderNumber !== null
      );
      return data;
    }

    /**
     *
     * @description New search api includes shared parts
     * @param query
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async searchAll(
      query: Input.SearchAll,
      abortSignal?: AbortSignal
    ): Promise<Output.Search> {
      const headers = await this.getAuthorizationHeaders();
      let q: any = Object.assign({}, query);
      // convert dates to API required string format
      if (isSearchBySoldToAccountNumber(query)) {
        q.fromDate = format(query.fromDate, "yyyyMMdd");
        q.toDate = format(query.toDate, "yyyyMMdd");
      }
      let result = await this.connection
        .get("salesorder/searchall", {
          baseURL: this.baseUrl,
          headers: headers,
          params: q,
          signal: abortSignal,
        })
        .catch((e) => {
          if (e.response.status === 401 && q.accountNumber) {
            throw new Error(
              `User is not authorized to access account ${q.accountNumber}`
            );
          } else if (e.response?.data?.detail) {
            throw new Error(e.response.data.detail);
          } else {
            throw e;
          }
        });
      let data = result?.data?.salesOrders?.filter(
        (o: Output.SalesOrder) => o.salesOrderNumber !== null
      );
      return data;
    }

    /**
     *
     * @param salesOrderNumber
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async getDetails(
      salesOrderNumber: string,
      abortSignal?: AbortSignal
    ): Promise<Output.SalesOrderDetail> {
      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection.get(`salesorder/${salesOrderNumber}`, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: abortSignal,
      });

      return result.data;
    }

    /**
     *
     * @param orderNumber
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async getTermsOfSale(
      orderNumber: string,
      abortSignal?: AbortSignal
    ): Promise<Output.TermsOfSale> {
      const headers = await this.getAuthorizationHeaders();

      const result = await this.connection.get(
        `quotes/${orderNumber}/termsofsale`,
        {
          baseURL: this.baseUrl,
          headers: headers,
          signal: abortSignal,
        }
      );
      return result.data;
    }

    /**
     *
     * @param salesOrderNumber
     * @param accountNumber
     * @param docType
     * @param itemsIds
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async getOrderDocuments(
      salesOrderNumber: string,
      accountNumber: string,
      docType: Input.DocumentTypes,
      itemsIds?: number[],
      abortSignal?: AbortSignal
    ): Promise<ResponseData<Document>> {
      let docTypeUri = this.getDownloadApiUriByDocType(docType);
      let downloadApiRelPath = `salesorder/${salesOrderNumber}/${docTypeUri}?AccountNumber=${accountNumber}`;
      if (itemsIds && itemsIds.length > 0)
        downloadApiRelPath += `&lines=${itemsIds.join()}`;

      const headers = await this.getAuthorizationHeaders();

      let result = await this.connection
        .get(downloadApiRelPath, {
          baseURL: this.baseUrl,
          headers: headers,
          responseType: "blob",
          signal: abortSignal,
        })
        .then((resp: ApiResponse) => {
          return resp;
        })
        .catch((error: ApiError) => {
          if (error.response) {
            return error.response;
          } else {
            throw new Error(error.message);
          }
        });
      let fileHelper = new FileHelper<Document>();
      let downloadResponse = await fileHelper
        .parseDownloadApiResponse(salesOrderNumber, result)
        .then((downloadResponse) => {
          return downloadResponse;
        })
        .catch((error: Error) => {
          throw error;
        });
      return downloadResponse;
    }

    /**
     *
     * @param email
     * @param salesOrderNumber
     * @param accountNumber
     * @param docType
     * @param cc
     * @param itemsIds
     * @param senderNameOrEmail
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     * This function is not part of the SAP RFC Integration, but is only used for Sales Order Details
     */
    async emailOrderDocuments(
      email: string[],
      salesOrderNumber: string,
      accountNumber: string,
      docType: Input.DocumentTypes,
      cc?: string[],
      itemsIds?: number[],
      senderNameOrEmail?: string,
      abortSignal?: AbortSignal
    ): Promise<ResponseData<any>> {
      let apiData = {
        accountNumber: accountNumber,
        documentType: this.getEmailApiDocTypeNameByDocType(docType),
        lineItemNumbers: itemsIds,
        to: email,
        cc: cc,
        salesOrderNumber: salesOrderNumber,
        senderEmail: senderNameOrEmail === undefined ? "" : senderNameOrEmail,
      };

      const headers = await this.getAuthorizationHeaders();

      let result = await this.connection
        .post(this.emailQueueApiRelPath, JSON.stringify(apiData), {
          baseURL: this.documentQueueBaseUrl,
          headers: headers,
          signal: abortSignal,
        })
        .then((resp: ApiResponse) => {
          return resp;
        })
        .catch((error: ApiError) => {
          throw new Error(error.message);
        });

      let emailResult = undefined;
      if (result.status === 200) {
        emailResult = {
          error: undefined,
          results: "Requested document(s) will be emailed shortly.",
        };
      } else {
        emailResult = {
          error: result.data as ErrorResponse,
          results: undefined,
        };
        if (emailResult.error && emailResult.error.detail) {
          let details = JSON.parse(emailResult.error.detail) as string[];
          if (details && details.length > 0) {
            emailResult.error.detail = details.join(". ");
          }
        }
      }
      return emailResult;
    }

    // private methods

    private getDownloadApiUriByDocType(docType: Input.DocumentTypes): string {
      let docTypeUri = "";
      switch (docType) {
        case Input.DocumentTypes.acknowledgement:
          docTypeUri = "pdf";
          break;
        case Input.DocumentTypes.invoice:
          docTypeUri = "invoices";
          break;
        case Input.DocumentTypes.packing:
          docTypeUri = "packinglists";
          break;
        case Input.DocumentTypes.shipment:
          docTypeUri = "shippingdocs";
          break;
        default:
          docTypeUri = "pdf";
          break;
      }

      return docTypeUri;
    }

    private getEmailApiDocTypeNameByDocType(
      docType: Input.DocumentTypes
    ): string {
      let name = "";
      switch (docType) {
        case Input.DocumentTypes.acknowledgement:
          name = "acknowledgement";
          break;
        case Input.DocumentTypes.invoice:
          name = "invoice";
          break;
        case Input.DocumentTypes.packing:
          name = "packing_slip";
          break;
        case Input.DocumentTypes.shipment:
          name = "shipment";
          break;
        default:
          name = "acknowledgement";
          break;
      }

      return name;
    }
  }

  const isSearchBySoldToAccountNumber = (
    x: any
  ): x is Input.SearchBySoldToAccount =>
    typeof x === "object" && "accountNumber" in x;
}
