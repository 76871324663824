import axios, {
  AxiosError,
  AxiosResponse,
  AxiosStatic,
  CancelTokenSource,
} from "axios";
import * as rax from "retry-axios";
import { AuthService } from "./AuthService";
import { IPublicClientApplication } from "@azure/msal-browser";

/**
 * Wrap axios interfaces as an abstraction.
 */
export interface ApiCancelTokenSource extends CancelTokenSource {}
export interface ApiError extends AxiosError {}
export interface ApiResponse extends AxiosResponse {}

/**
 *
 * Wrapper used when a service call promise is passed as a parameter.
 *  *
 */
export interface ApiCall<T> {
  query: Promise<T>;
  token: ApiCancelTokenSource | undefined;
}

// by default, we'll retry a request 3 times for default status codes:
// [[100, 199], [429, 429], [500, 599]]
// https://github.com/JustinBeckwith/retry-axios#readme
rax.attach();

/**
 * Base class for other API services.
 */
class ApiService {
  public connection: AxiosStatic;
  private authService: AuthService;
  public userName: string;
  public authAccountName: string;
  public msal: IPublicClientApplication = window.msalInstance;

  /**
   * Constructor function. Initializes properties and instantiates an authService class for authentication.
   */
  constructor() {
    const account = this.msal.getActiveAccount();
    if (!account) {
      console.error("User not signed in");
    }
    this.authAccountName = account?.name || "";
    this.userName = account?.username || "";
    this.connection = axios;
    this.authService = new AuthService();
  }

  /**
   * Method used to check authorize a user within a service class. Checks that the user's session token is valid.
   *
   * @returns Promise<string>
   */
  authorize = async (): Promise<string | undefined> => {
    try {
      return await this.authService.acquireToken();
    } catch (e) {
      console.error("Unable to acquire token.");
      this.authService.signOut();
    }
  };

  /**
   * Method used to generate a cancel token
   *
   * @returns CancelTokenSource
   */
  generateSourceToken(): ApiCancelTokenSource {
    const CancelToken = axios.CancelToken;
    return CancelToken.source();
  }

  /**
   * Method used to generate a key:value pair array. If the user is authorized a bearer token is added under the "Authorization" key.
   *
   * @returns  { [key: string]: any }
   */
  getAuthorizationHeaders = async (): Promise<{ [key: string]: any }> => {
    const headers: { [key: string]: any } = {};
    let token = await this.authorize();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
  };
}
export default ApiService;
