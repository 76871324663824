export const MockData = {
  rmas: [
      {
          rmaNumber: "8400682066", 
          accountNumber: "201014", 
          dateCreated: "8/22/2022", 
          purchaseOrderNumber: "2369418-00", 
          currency: "USD", 
          status: "Approved", 
          returnReason: {
              code: "RCE",
              name: "Customer Error"
          }, 
          total: "367.18" 
      },
      {
          rmaNumber: "8400658764", 
          accountNumber: "201014", 
          dateCreated: "10/11/2022", 
          purchaseOrderNumber: "2205544-00", 
          currency: "USD", 
          status: "Approved", 
          returnReason: {
              code: "RDR",
              name: "Distributor Return (overstock)"
          }, 
          total: "1038.93" 
      }
  ]
}
;
