import { Dispatch } from "redux";
import actionCreatorFactory, {
  ActionCreator,
  AsyncActionCreators,
  Failure,
  Success,
} from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { wrapAsyncWorker, wrapWorker } from "./helpers";
import { ApiError } from "../services/ApiService";
import { UserAccount } from "../models/UserAccount";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "./app";
import { UserRecord } from "./systemStore";
import UserAccountService from "../services/UserAccountService";
import {
  UserAccountMetaDataUpdateInterface,
  UserAccountMetaDataFavoriteInterface,
  UserAccountMetadataUpdate,
  UserTypeInterface,
} from "../services/ApiServiceInterfaces";
import { AuthService } from "../services/AuthService";
import { ErrorResponse } from "../models/Error";
import AdminService from "../services/AdminService";
import { Notify } from "./system/notifications/helpers";

const ac = actionCreatorFactory("profile");

export interface UserProfile {
  id: string;
  connectId: string;
  identityApiId: string;
  name: string;
  userName: string;
  firstName: string;
  lastName: string;
  isInternalUser: boolean;
  lastLogon: string;
  signOut?: Function;
  signIn?: Function;
  currentAccount: UserAccount | undefined;
  setAccountError: ErrorResponse | undefined;
  favoriteAccounts: Array<UserAccount>;
  defaultAccount: UserAccount | undefined;
  accountsAssigned: boolean;
  loading: boolean;
  loadingBrands: boolean;
  togglingFavorite: boolean | null;
  settingDefault: boolean | null;
  account?: UserAccount;
  error?: { errorMessage: string };
  avatar?: string;
  initialized?: boolean;
  isRefreshing: boolean;
  userType: UserTypeInterface | undefined;
  phoneNumber: string;
  companyName: string;
  updatingProfile: {};
}

export interface UpdateProfile {
  userId: string;
  phoneNumber: string;
  companyName: string;
  userType: UserTypeInterface;
}

export interface UpdatePhoneCompany {
  userId: string;
  phoneNumber: string;
  companyName: string;
}
export interface UserProfileDispatch {
  updateProfle: UpdateProfile;
}
export interface UserAccountParams {
  account: UserAccount;
  user: UserProfile;
  enabled?: boolean;
}

export const initialState: UserProfile = {
  id: "",
  connectId: "",
  identityApiId: "",
  name: "",
  userName: "",
  firstName: "",
  lastName: "",
  isInternalUser: false,
  lastLogon: "",
  currentAccount: undefined,
  setAccountError: undefined,
  favoriteAccounts: [],
  defaultAccount: undefined,
  accountsAssigned: false,
  loading: false,
  loadingBrands: false,
  togglingFavorite: false,
  settingDefault: false,
  initialized: false,
  isRefreshing: false,
  userType: undefined,
  phoneNumber: "",
  companyName: "",
  updatingProfile: {},
};

const FavoriteAccountPrefix = "user_togglefavorite";
const DefaultAccountPrefix = "user_toggledefault";
const CurrentAccountPrefix = "user_currentaccount";

// Async Action creators
const UPDATE_USERPROFILE: ActionCreator<UpdateProfile | undefined> = ac<
  UpdateProfile | undefined
>("updateUserProfile");
const UPDATE_PHONENUMBER: ActionCreator<UpdatePhoneCompany> =
  ac<UpdatePhoneCompany>("updatePhoneNumber");
const UPDATE_COMPANYNAME: ActionCreator<UpdatePhoneCompany> =
  ac<UpdatePhoneCompany>("updateCompanyName");
const UPDATE_PHONENUMBER_COMPANYNAME: ActionCreator<UpdatePhoneCompany> =
  ac<UpdatePhoneCompany>("updatePhoneNumber_CompanyName");
const UPDATE_USER: ActionCreator<
  UserRecord | Partial<UserProfile> | undefined
> = ac<UserRecord | Partial<UserProfile> | undefined>("updateUser");
const TOGGLE_FAVORED_ACCOUNT: ActionCreator<UserAccount | undefined> = ac<
  UserAccount | undefined
>("ToggleFavoredAccount");
const TOGGLE_FAVORED_ACCOUNT_ASYNC: AsyncActionCreators<
  UserAccountParams | undefined,
  UserAccountMetadataUpdate[],
  any
> = ac.async<UserAccountParams | undefined, UserAccountMetadataUpdate[], any>(
  "ToggleFavoredAccountAsync"
);
const SET_FAVORED_ACCOUNTS: ActionCreator<UserAccount[]> =
  ac<UserAccount[]>("setFavoredAccounts");
const SET_DEFAULT_ACCOUNT: ActionCreator<UserAccount | undefined> = ac<
  UserAccount | undefined
>("setDefaultAccount");
const UPDATE_DEFAULT_ACCOUNT: AsyncActionCreators<
  UserAccountParams | undefined,
  UserAccountMetadataUpdate[],
  any
> = ac.async<UserAccountParams | undefined, UserAccountMetadataUpdate[], any>(
  "updateDefaultAccount"
);
const SET_LOADING_STATUS: ActionCreator<boolean> =
  ac<boolean>("setLoadingStatus");
const SET_INITIALIZED: ActionCreator<boolean> = ac<boolean>("setInitialized");
const SET_REFRESHING: ActionCreator<boolean> = ac<boolean>("setRefreshing");
const SET_CURRENT_ACCOUNT: AsyncActionCreators<
  UserAccountParams | undefined,
  UserAccount | undefined,
  ErrorResponse | undefined
> = ac.async<
  UserAccountParams | undefined,
  UserAccount | undefined,
  ErrorResponse | undefined
>("setCurrentAccountBrands");

//thunks - use this if you need to access state within the async call
export const getUserProfileDependencies = (isRefresh?: boolean) => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    const { userProfile } = getState();
    const username = new AuthService().getAndSetAccount()?.username;
    const userAccountService = new UserAccountService();
    if (username) {
      if (!isRefresh) {
        ProfileDuck.actions.SET_LOADING_STATUS(dispatch, true);
      } else {
        ProfileDuck.actions.SET_REFRESHING(dispatch, true);
      }
      const cancelToken = userAccountService.generateSourceToken();
      userAccountService
        .getUserAccountsSoldTos(username, cancelToken)
        .then((response: any) => {
          let userCustomAccountIds = response.data.map(
            (favorite: any) => favorite.soldToPartyId
          );
          if (userCustomAccountIds?.length > 0) {
            userAccountService
              .getCustomerAccountsSummary(
                userProfile.userName,
                userCustomAccountIds,
                cancelToken
              )
              .then((r: any) => {
                let summaryItems = r.data;
                let defaultaccount = response.data?.find(
                  (fav: any) => fav.isDefault === true
                );

                if (defaultaccount) {
                  let defaultAccountSummary = summaryItems?.find(
                    (s: any) => s.accountNumber === defaultaccount.soldToPartyId
                  ) as UserAccount;

                  if (defaultAccountSummary) {
                    ProfileDuck.actions.SET_DEFAULT_ACCOUNT(
                      dispatch,
                      defaultAccountSummary
                    );
                    // for refresh - check if there a new current account before retrieving account details
                    let currentAccountSummary = undefined;
                    if (
                      isRefresh &&
                      userProfile.currentAccount &&
                      userProfile.currentAccount.accountNumber !==
                        defaultAccountSummary.accountNumber
                    ) {
                      currentAccountSummary = summaryItems?.find(
                        (s: any) =>
                          s.accountNumber ===
                            userProfile.currentAccount?.accountNumber &&
                          s.address === userProfile.currentAccount?.address &&
                          s.branch === userProfile.currentAccount?.branch
                      ) as UserAccount;

                      //user's current account is not found in favorites or defaults from app and must be looked up seperately
                      if (!currentAccountSummary) {
                        currentAccountSummary = userProfile.currentAccount;
                      }
                    }
                    ProfileDuck.actions.SET_CURRENT_ACCOUNT(dispatch, {
                      account: currentAccountSummary
                        ? currentAccountSummary
                        : defaultAccountSummary,
                      user: userProfile,
                    });
                  }
                }
                let favorites = response.data?.filter(
                  (fav: any) => fav.isFavorite === true
                );
                let favoriteAccounts: Array<UserAccount> = [];
                favorites?.forEach((favorite: any) => {
                  let favoriteAccountSummary = summaryItems?.find(
                    (s: any) => s.accountNumber === favorite.soldToPartyId
                  ) as UserAccount;
                  if (favoriteAccountSummary) {
                    favoriteAccounts.push(favoriteAccountSummary);
                  }
                });
                ProfileDuck.actions.SET_FAVORED_ACCOUNTS(
                  dispatch,
                  favoriteAccounts
                );
                if (!isRefresh) {
                  ProfileDuck.actions.SET_INITIALIZED(dispatch, true);
                  ProfileDuck.actions.SET_LOADING_STATUS(dispatch, false);
                } else {
                  ProfileDuck.actions.SET_REFRESHING(dispatch, false);
                }
              })
              .catch((e: ApiError) => {
                console.error(e.message);
                cancelToken.cancel();
              });
          } else {
            if (!isRefresh) {
              ProfileDuck.actions.SET_INITIALIZED(dispatch, true);
              ProfileDuck.actions.SET_LOADING_STATUS(dispatch, false);
            } else {
              ProfileDuck.actions.SET_REFRESHING(dispatch, false);
            }
          }
        })
        .catch((e: ApiError) => {
          console.error(e.message);
          cancelToken.cancel();
          if (!isRefresh) {
            ProfileDuck.actions.SET_LOADING_STATUS(dispatch, false);
            ProfileDuck.actions.SET_REFRESHING(dispatch, false);
          }
        });
    }
  };
};

//actions are where you can handle your async calls to the API and manipulate them as needed. Afterwards, the result is passed to the reducers to modify the global state.
const actions = {
  UPDATE_USERPROFILE: wrapWorker(
    UPDATE_USERPROFILE,
    (
      dispatch: Dispatch,
      params: UpdateProfile | undefined
    ): UpdateProfile | undefined => {
      if (
        params &&
        params.userId &&
        params.companyName &&
        params.phoneNumber &&
        params.userType
      ) {
        console.log("hubbell...store", params);
        const adminService = new AdminService();
        adminService
          .updateUserProfile(
            params.userId,
            params.companyName,
            params.phoneNumber,
            params.userType
          )
          .then((response: any) => {
            return response.data;
          })
          .catch((error: ApiError) => {
            console.error(error.message);
          });
      }
      return params;
    }
  ),
  UPDATE_PHONENUMBER: wrapWorker(
    UPDATE_PHONENUMBER,
    (dispatch: Dispatch, params: UpdatePhoneCompany): UpdatePhoneCompany => {
      if (params && params.phoneNumber) {
        const adminService = new AdminService();
        adminService
          .updatePhoneandCompany(params.userId, undefined, params.phoneNumber)
          .then((response: any) => {
            return response.data;
          })
          .catch((error: ApiError) => {
            console.error(error.message);
          });
      }
      return params;
    }
  ),
  UPDATE_COMPANYNAME: wrapWorker(
    UPDATE_COMPANYNAME,
    (dispatch: Dispatch, params: UpdatePhoneCompany): UpdatePhoneCompany => {
      if (params && params.companyName) {
        const adminService = new AdminService();
        adminService
          .updatePhoneandCompany(params.userId, params.companyName, undefined)
          .then((response: any) => {
            return response.data;
          })
          .catch((error: ApiError) => {
            console.error(error.message);
          });
      }
      return params;
    }
  ),
  UPDATE_PHONENUMBER_COMPANYNAME: wrapWorker(
    UPDATE_PHONENUMBER_COMPANYNAME,
    (dispatch: Dispatch, params: UpdatePhoneCompany): UpdatePhoneCompany => {
      if (params && params.phoneNumber && params.companyName) {
        const adminService = new AdminService();
        adminService
          .updatePhoneandCompany(
            params.userId,
            params.companyName,
            params.phoneNumber
          )
          .then((response: any) => {
            return response.data;
          })
          .catch((error: ApiError) => {
            console.error(error.message);
          });
      }
      return params;
    }
  ),

  UPDATE_USER: wrapWorker(
    UPDATE_USER,
    (
      dispatch: Dispatch,
      params: UserRecord | Partial<UserProfile> | undefined
    ): UserRecord | Partial<UserProfile> | undefined => {
      return params;
    }
  ),
  TOGGLE_FAVORED_ACCOUNT: wrapWorker(
    TOGGLE_FAVORED_ACCOUNT,
    (
      dispatch: Dispatch,
      params: UserAccount | undefined
    ): UserAccount | undefined => {
      return params;
    }
  ),
  TOGGLE_FAVORED_ACCOUNT_ASYNC: wrapAsyncWorker(
    TOGGLE_FAVORED_ACCOUNT_ASYNC,
    (
      dispatch: Dispatch,
      payload: UserAccountParams | undefined
    ): Promise<UserAccountMetadataUpdate[]> => {
      return new Promise<UserAccountMetadataUpdate[]>((resolve, reject) => {
        if (payload?.user?.userName) {
          const userAccountService = new UserAccountService();
          const isFavorite =
            payload.user?.favoriteAccounts?.find(
              (i: UserAccount) =>
                i.accountNumber === payload.account.accountNumber
            ) !== undefined;
          let userAccount: UserAccountMetaDataFavoriteInterface = {
            soldToPartyId: payload.account.accountNumber,
            isDeleted: false,
            isFavorite: !isFavorite,
          };
          // persist change on server
          userAccountService
            .updateUserAccountFavorite(payload.user.userName, userAccount)
            .then((response: any) => {
              // update local state change outside of the async call to speed up interaction
              resolve(response.data);
            })
            .catch((error: ApiError) => {
              console.error(error.message);
              let time = new Date();
              Notify({
                id: time.getTime().toString(),
                type: FavoriteAccountPrefix,
                title: "Unable to toggle favorite account, please try again.",
                allowDismiss: true,
              });
              reject();
            });
        }
      });
    }
  ),
  SET_DEFAULT_ACCOUNT: wrapWorker(
    SET_DEFAULT_ACCOUNT,
    (
      dispatch: Dispatch,
      params: UserAccount | undefined
    ): UserAccount | undefined => {
      return params;
    }
  ),
  SET_FAVORED_ACCOUNTS: wrapWorker(
    SET_FAVORED_ACCOUNTS,
    (dispatch: Dispatch, params: UserAccount[]): UserAccount[] => {
      return params;
    }
  ),
  UPDATE_DEFAULT_ACCOUNT: wrapAsyncWorker(
    UPDATE_DEFAULT_ACCOUNT,
    (
      dispatch: Dispatch,
      payload: UserAccountParams | undefined
    ): Promise<UserAccountMetadataUpdate[]> => {
      return new Promise<UserAccountMetadataUpdate[]>((resolve, reject) => {
        if (payload?.user?.userName) {
          const userAccountService = new UserAccountService();
          const isFavorite =
            payload.user?.favoriteAccounts?.find(
              (i: UserAccount) =>
                i.accountNumber === payload.account?.accountNumber
            ) !== undefined;
          let userAccount: UserAccountMetaDataUpdateInterface = {
            isDefault: payload.enabled == null ? true : payload.enabled,
            isFavorite,
            soldToPartyId: payload.account?.accountNumber,
            isDeleted: false,
          };
          // persist change on server
          userAccountService
            .updateDefaultUserAccount(payload.user?.userName, userAccount)
            .then((response: any) => {
              // update local state change outside of the async call to speed up interaction
              resolve(response.data);
            })
            .catch((error: ApiError) => {
              console.error(error.message);
              let time = new Date();
              Notify({
                id: time.getTime().toString(),
                type: CurrentAccountPrefix,
                title: "Unable to set current account, please try again.",
                allowDismiss: true,
              });
            });
        }
      });
    }
  ),
  SET_LOADING_STATUS: wrapWorker(
    SET_LOADING_STATUS,
    (dispatch: Dispatch, params: boolean): boolean => {
      return params;
    }
  ),
  SET_INITIALIZED: wrapWorker(
    SET_INITIALIZED,
    (dispatch: Dispatch, params: boolean): boolean => {
      return params;
    }
  ),
  SET_REFRESHING: wrapWorker(
    SET_REFRESHING,
    (dispatch: Dispatch, params: boolean): boolean => {
      return params;
    }
  ),
  SET_CURRENT_ACCOUNT: wrapAsyncWorker(
    SET_CURRENT_ACCOUNT,
    (
      dispatch: Dispatch,
      params: UserAccountParams | undefined
    ): Promise<UserAccount | undefined> => {
      return new Promise<UserAccount | undefined>((resolve, reject) => {
        if (!params) return reject();
        const userAccountService = new UserAccountService();
        const cancelToken = userAccountService.generateSourceToken();
        userAccountService
          .findUserAccount(
            {
              userId: params?.user.identityApiId,
              accountNumber:
                params.account.accountNumber ||
                params?.user.currentAccount?.accountNumber,
            },
            cancelToken
          )
          .then((r: any) => {
            if (r?.data?.customers?.length > 0) {
              resolve(r.data.customers[0]);
            } else {
              let time = new Date();
              Notify({
                id: time.getTime().toString(),
                type: DefaultAccountPrefix,
                title: "Account is invalid. Please contact customer service.",
                allowDismiss: true,
              });
              resolve(undefined);
            }
          })
          .catch((e: ApiError) => {
            console.error(e.message);
            cancelToken.cancel();
            let time = new Date();
            Notify({
              id: time.getTime().toString(),
              type: DefaultAccountPrefix,
              title:
                "Unable to fetch current account brands, please try again.",
              allowDismiss: true,
            });
          });
      });
    }
  ),
};

//the reducers modify the global state of redux with the results of the actions above
const UserProfileReducer = reducerWithInitialState(initialState)
  .case(
    UPDATE_USERPROFILE,
    (state: UserProfile, userText: UpdateProfile | undefined) => {
      return {
        ...state,
        updatingProfile: { ...state.updatingProfile, userText },
      };
    }
  )
  .case(
    UPDATE_PHONENUMBER,
    (state: UserProfile, userInfo: UpdatePhoneCompany) => {
      return { ...state, phoneNumber: userInfo?.phoneNumber };
    }
  )
  .case(
    UPDATE_PHONENUMBER_COMPANYNAME,
    (state: UserProfile, userInfo: UpdatePhoneCompany) => {
      return {
        ...state,
        phoneNumber: userInfo?.phoneNumber,
        companyName: userInfo?.companyName,
      };
    }
  )
  .case(
    UPDATE_COMPANYNAME,
    (state: UserProfile, userInfo: UpdatePhoneCompany) => {
      return { ...state, companyName: userInfo?.companyName };
    }
  )
  .case(
    UPDATE_USER,
    (
      state: UserProfile,
      userContext: UserRecord | Partial<UserProfile> | undefined
    ) => {
      return { ...state, ...userContext };
    }
  )
  .case(
    SET_DEFAULT_ACCOUNT,
    (state: UserProfile, defaultAccount: UserAccount | undefined) => {
      return { ...state, defaultAccount };
    }
  )
  .case(
    SET_FAVORED_ACCOUNTS,
    (state: UserProfile, favoriteAccounts: UserAccount[]) => {
      return { ...state, favoriteAccounts };
    }
  )
  .case(
    TOGGLE_FAVORED_ACCOUNT,
    (state: UserProfile, account: UserAccount | undefined) => {
      let favoriteAccounts = state.favoriteAccounts;
      let match = favoriteAccounts?.find(
        (i: UserAccount) => i?.accountNumber === account?.accountNumber
      );
      if (!match && account && favoriteAccounts) {
        favoriteAccounts = [...favoriteAccounts, account];
      } else if (favoriteAccounts) {
        favoriteAccounts = favoriteAccounts.filter(
          (i: UserAccount) => i.accountNumber !== account?.accountNumber
        );
      }
      return { ...state, favoriteAccounts };
    }
  )
  .case(
    TOGGLE_FAVORED_ACCOUNT_ASYNC.started,
    (state: UserProfile, payload: UserAccountParams | undefined) => {
      return { ...state, togglingFavorite: true };
    }
  )
  .case(
    TOGGLE_FAVORED_ACCOUNT_ASYNC.failed,
    (
      state: UserProfile,
      payload: Failure<UserAccountParams | undefined, any>
    ) => {
      return { ...state, togglingFavorite: null };
    }
  )
  .case(
    TOGGLE_FAVORED_ACCOUNT_ASYNC.done,
    (
      state: UserProfile,
      payload: Success<
        UserAccountParams | undefined,
        UserAccountMetadataUpdate[]
      >
    ) => {
      let updatedFavorites: Array<UserAccount> = [];
      if (payload.result?.length > 0) {
        payload.result.forEach((f: UserAccountMetadataUpdate) => {
          if (f.isFavorite) {
            let match = state.favoriteAccounts?.find(
              (i: UserAccount) => i?.accountNumber === f.soldToPartyId
            );
            if (match) {
              updatedFavorites.push(match);
            } else {
              if (
                payload.params?.account &&
                f.soldToPartyId === payload.params?.account.accountNumber
              ) {
                // favorite toggled on
                updatedFavorites.push(payload.params.account);
              } else {
                // other new favorities returned by API - should not occur
              }
            }
          }
        });
      }
      return {
        ...state,
        togglingFavorite: false,
        favoriteAccounts: updatedFavorites,
      };
    }
  )
  .case(
    UPDATE_DEFAULT_ACCOUNT.started,
    (state: UserProfile, payload: UserAccountParams | undefined) => {
      return { ...state, settingDefault: true };
    }
  )
  .case(
    UPDATE_DEFAULT_ACCOUNT.failed,
    (
      state: UserProfile,
      payload: Failure<UserAccountParams | undefined, any>
    ) => {
      return { ...state, settingDefault: null };
    }
  )
  .case(
    UPDATE_DEFAULT_ACCOUNT.done,
    (
      state: UserProfile,
      payload: Success<
        UserAccountParams | undefined,
        UserAccountMetadataUpdate[]
      >
    ) => {
      let updatedDefault: UserAccount | undefined = undefined;
      let updatedFavorites: Array<UserAccount> = [];
      if (payload.result?.length > 0) {
        payload.result.forEach((f: UserAccountMetadataUpdate) => {
          let match = state.favoriteAccounts?.find(
            (i: UserAccount) => i?.accountNumber === f.soldToPartyId
          );
          if (f.isFavorite) {
            if (match) {
              updatedFavorites.push(match);
            } else {
              if (
                payload.params?.account &&
                f.soldToPartyId === payload.params?.account.accountNumber
              ) {
                // favorite toggled on
                updatedFavorites.push(payload.params.account);
              } else {
                // other new favorities returned by API - should not occur
              }
            }
          }
          if (f.isDefault) {
            let toggleOn =
              payload.params?.enabled == null || payload.params.enabled;
            updatedDefault = toggleOn
              ? match || payload.params?.account
              : match;
          }
        });
      }
      return {
        ...state,
        settingDefault: false,
        favoriteAccounts: updatedFavorites,
        defaultAccount: updatedDefault,
      };
    }
  )
  .case(
    SET_CURRENT_ACCOUNT.started,
    (state: UserProfile, payload: UserAccountParams | undefined) => {
      return { ...state, loadingBrands: !state.isRefreshing };
    }
  )
  .case(
    SET_CURRENT_ACCOUNT.done,
    (
      state: UserProfile,
      payload: Success<UserAccountParams | undefined, UserAccount | undefined>
    ) => {
      return { ...state, loadingBrands: false, currentAccount: payload.result };
    }
  )
  .case(
    SET_CURRENT_ACCOUNT.failed,
    (
      state: UserProfile,
      payload: Failure<UserAccountParams | undefined, ErrorResponse | undefined>
    ) => {
      return { ...state, loadingBrands: false, setAccountError: payload.error };
    }
  )
  .case(SET_LOADING_STATUS, (state: UserProfile, loading: boolean) => {
    return { ...state, loading };
  })
  .case(SET_INITIALIZED, (state: UserProfile, initialized: boolean) => {
    return { ...state, initialized };
  })
  .case(SET_REFRESHING, (state: UserProfile, isRefreshing: boolean) => {
    return { ...state, isRefreshing };
  })
  .build();

export const ProfileDuck = {
  actions,
  reducer: UserProfileReducer,
};
