import { FC, useState } from "react";
import { PrimaryNavigation, SecondaryNavigation } from "../AppNavigation";
import logo from "../../assets/images/HubbellConnectLogo.svg";
import UserMenuItem from "../UserMenuItem";
import UserAccountMenuItem from "../UserAccountMenuItem";
import Page from "../PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons";
import IdleUserComponent from "../IdleUserComponent";
import UserSessionTokenPollerComponent from "../UserSessionTokenPollerComponent";
import EnvironmentIndicator from "../EnvironmentIndicator";
import ErrorBoundary from "../ErrorBoundary";
import { useSelector } from "react-redux";
import {
  MappedSystemState,
  mapSystemStateToProps,
} from "../../store/systemStore";
import { AppState } from "../../store/app";
import { AuthenticatedTemplate } from "@azure/msal-react";
import UserAccountCartItem from "../UserAccountCartItem";
import Toast from "store/system/notifications/components/Toast";

const AuthenticatedPage: FC<{ children: any }> = (props): JSX.Element => {
  const system = useSelector<AppState, MappedSystemState>((state) =>
    mapSystemStateToProps(state.system)
  );
  const [sideBarState, setSidebarState] = useState(false);
  const toggleSidebar = () => {
    setSidebarState(!sideBarState);
  };

  return (
    <AuthenticatedTemplate>
      <Page className={sideBarState ? "max" : ""}>
        <Page.Header>
          <button
            className="btn btn-text btn-sm btn-toggleSidebar"
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon icon={faBars} fixedWidth />
          </button>
          <div className="appLogo">
            <img src={logo} alt="Hubbell Connect" />
          </div>
          <div className="headerNav">
            <div className="primaryNav"></div>
            <div className="secondaryNav">
              <ErrorBoundary>
                <UserAccountCartItem isLoading={!system.isReady} />
              </ErrorBoundary>
              <ErrorBoundary>
                <UserAccountMenuItem isLoading={!system.isReady} />
              </ErrorBoundary>
              <ErrorBoundary>
                <UserMenuItem />
              </ErrorBoundary>
            </div>
          </div>
        </Page.Header>

        <Page.Sidebar>
          <div id="sidebar">
            <div id="sidebarHeader">
              <button
                className="btn btn-text btn-sm btn-toggleSidebar"
                onClick={toggleSidebar}
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </button>
              <div className="appLogo">
                <img src={logo} alt="Hubbell Connect" />
              </div>
              <EnvironmentIndicator />
            </div>
            <ErrorBoundary>
              <PrimaryNavigation />
            </ErrorBoundary>
            <ErrorBoundary>
              <SecondaryNavigation />
            </ErrorBoundary>
          </div>
        </Page.Sidebar>

        <Page.Body>
          {props.children}
          <Toast />
        </Page.Body>

        <Page.Footer>
          <></>
        </Page.Footer>
        {process.env.REACT_APP_ENABLE_IDLE_TIMER === "true" && (
          <>
            <IdleUserComponent />
            <UserSessionTokenPollerComponent />
          </>
        )}
      </Page>
    </AuthenticatedTemplate>
  );
};
export default AuthenticatedPage;
