import {
  isPriceAndAvailabilitySearchMultipleInterface,
  materialLookupInterface,
  materialToStepIdInterface,
  priceAndAvailabilitySearchMultipleInterface,
  priceAndAvailabilitySearchSingleInterface,
  productDetailInterface,
  availabilityForecastForPlantLookupInterface,
  AvailabilityForecastForPlant,
  customerMaterialLookupInterface,
  materialSearchInterface,
} from "./ApiServiceInterfaces";
import ApiService, { ApiCancelTokenSource } from "./ApiService";
import { ProductImageFormats, ProductImageTypes } from "../models/Product";
import { SetImagePath } from "../services/Storage";
import { MaterialPriceAndAvailabilityResult } from "../models/MaterialPriceAndAvailabilityResult";
import { AxiosResponse } from "axios";
import { MaterialDetailResult } from "../models/MaterialDetailResult";
import {
  CatalogNumberLookupResult,
  CustomerMaterialLookupResult,
  MaterialLookupResult,
  MaterialSearchResult,
} from "../models/MaterialLookup";
import { Dictionary } from "../models/Indexable";
import format from "date-fns/format";

const hybrisAPIBase = process.env.REACT_APP_HYBRIS_API_BASE;

const coreAPIMBase = process.env.REACT_APP_APIM_CORE_BASE;
const coreAPIM_Header = process.env.REACT_APP_APIM_CORE_KEY_HEADER_NAME;
const coreAPIM_Key = process.env.REACT_APP_APIM_CORE_KEY;
const coreAPIM_SRI_PATH = process.env.REACT_APP_SRI_API_BASE;

class MaterialDataService extends ApiService {
  // material to stepID call
  async materialToStepId(query: materialToStepIdInterface) {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const params = {
      salesOrg: query.salesOrg,
      division: query.division,
    };

    const url = "commerce/material/{1}/stepid".replace(
      "{1}",
      encodeURIComponent(query.materialNumber)
    );
    return this.connection.get(url, {
      baseURL: coreAPIMBase,
      params: params,
      headers: headers,
      cancelToken: query?.cancelTokenSource?.token,
    });
  }

  // P&A endpoint call - single item only
  async priceAndAvailabilitySingle(
    query: priceAndAvailabilitySearchSingleInterface,
    useBrand = true
  ): Promise<any> {
    const { item, accountNumber, cancelTokenSource, priceDate } = query;
    let token = await this.authorize();
    const url = "materials/{1}/pricing".replace(
      "{1}",
      encodeURIComponent(item.materialNumber)
    );
    const headers: { [key: string]: any } = {};

    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let params: Dictionary = {
      accountNumber: accountNumber,
      qty: item.quantity,
    };
    if (useBrand) {
      params["salesOrg"] = item.salesOrg;
      params["division"] = item.division;
    }
    if (priceDate) {
      // const priceDateString =moment(priceDate).format("yyyyMMDD");
      const priceDateString = format(new Date(priceDate), "yyyyMMdd");
      params = Object.assign(params, { priceDate: priceDateString });
    }
    return this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
      params: params,
    });
  }

  // P&A endpoint call - multiple Items ... not used & untested ...
  async priceAndAvailabilityMulti(
    query: priceAndAvailabilitySearchMultipleInterface
  ): Promise<any> {
    const { items, accountNumber, cancelTokenSource, priceDate } = query;
    let token = await this.authorize();
    const url = "materials/withnewpricing";
    const headers: { [key: string]: any } = {};

    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let params = {
      accountNumber: accountNumber,
      materials: items,
    };
    if (priceDate) {
      // const priceDateString = moment(priceDate).format("yyyyMMDD");
      const priceDateString = format(new Date(priceDate), "yyyyMMdd");
      params = Object.assign(params, { priceDate: priceDateString });
    }
    return this.connection.post(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
      params: params,
    });
  }
  /*
  // P&A endpoint for multiple request items
  async priceAndAvailabilityMulti(
    query: priceAndAvailabilitySearchMultipleInterface
  ): Promise<AxiosResponse<MaterialPriceAndAvailabilityResult[]>> {
    const { items, accountNumber, cancelTokenSource, priceDate } = query;
    let token = await this.authorize();
    const url = "materials";
    const products: Array<string> = [];
    items.forEach((i: priceAndAvailabilitySearchItemInterface) => {
      products.push(i.materialNumber);
    });
    const headers: { [key: string]: any } = {};

    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let params = {
      accountNumber: accountNumber,
      productNumbers: products.join(","),
    };
    if (priceDate) {
      const priceDateString = moment(priceDate).format("yyyyMMDD");
      params = Object.assign(params, { priceDate: priceDateString });
    }
    return this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
      params: params,
    });
  }

  // P&A endpoint for a single request item
  async priceAndAvailabilitySingle(
    query: priceAndAvailabilitySearchSingleInterface
  ): Promise<AxiosResponse<MaterialPriceAndAvailabilityResult[]>> {
    const { item, accountNumber, cancelTokenSource, priceDate } = query;
    let token = await this.authorize();
    const url = "material/{1}".replace(
      "{1}",
      encodeURIComponent(item.materialNumber)
    );

    const headers: { [key: string]: any } = {};

    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    let params = {
      accountNumber: accountNumber,
    };
    if (priceDate) {
      const priceDateString = moment(priceDate).format("yyyyMMDD");
      params = Object.assign(params, { priceDate: priceDateString });
    }
    return this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
      params: params,
    });
  }
*/
  async priceAndAvailability(
    query:
      | priceAndAvailabilitySearchSingleInterface
      | priceAndAvailabilitySearchMultipleInterface,
    useNew: boolean = false
  ): Promise<
    AxiosResponse<
      MaterialPriceAndAvailabilityResult | MaterialPriceAndAvailabilityResult[]
    >
  > {
    if (useNew) {
      console.info("using new pricing api");
    } else {
      console.info("using existing pricing api");
    }

    if (isPriceAndAvailabilitySearchMultipleInterface(query)) {
      return this.priceAndAvailabilityMulti(query);
    } else {
      return this.priceAndAvailabilitySingle(query, useNew);
    }
  }

  flattenClassifications = (classifications: { [key: string]: any }) => {
    let classificationsFlat: { [key: string]: Array<string> } = {};
    Object.entries(classifications)
      .map((a: Array<any>) => a[1])
      .reduce((f, e) => f.concat(e), [])
      .forEach(
        (entry: any) =>
          (classificationsFlat = { ...classificationsFlat, ...entry })
      );
    return classificationsFlat;
  };

  async productDetail(
    query: productDetailInterface,
    cancelTokenSource?: ApiCancelTokenSource,
    imageFetch?: boolean
  ): Promise<MaterialDetailResult | undefined> {
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const materialStepData = await this.materialToStepId(query);

    if (materialStepData.data) {
      const result = await this.connection.get(
        hybrisAPIBase + `products/${materialStepData.data.stepId}?fields=FULL`,
        { cancelToken: cancelTokenSource?.token }
      );

      if (result && result.data) {
        const data = result.data as MaterialDetailResult;

        // TODO: tech debt of image caching - may be removed once image serve API comes online
        data.images.forEach((img) => {
          if (
            img.format === ProductImageFormats.Product &&
            img.imageType === ProductImageTypes.Primary
          )
            SetImagePath(data.catalogNumber, img);
        });

        if (!imageFetch) {
          // flatten and map data so our view doesn't have to
          data.materialNumber = query.materialNumber;

          let classifications: { [key: string]: any } = {};
          if ("classifications" in data && data.classifications.length) {
            (data.classifications as string[]).forEach((c: any) => {
              if ("name" in c && "features" in c && c.features.length) {
                classifications[c.name] = c.features.map((f: any) => {
                  let features: { [key: string]: string } = {};
                  if ("featureValues" in f && f.featureValues.length) {
                    features[f.name] = f.featureValues.map((m: any) => m.value);
                  }
                  return features;
                });
              }
            });
          }
          let classificationsFlat: { [key: string]: Array<string> } =
            this.flattenClassifications(classifications);
          let upc =
            classificationsFlat["UPC"] && classificationsFlat["UPC"].length
              ? classificationsFlat["UPC"].join("")
              : "";
          // TODO: classifications typing is dubious, altered typing to preserve code
          data.classifications = classificationsFlat;
          data.upc = upc;
        }
        return data;
      }
    } else {
      return Promise.resolve(undefined);
    }
  }

  async getForecastedAvailabilityByPlant(
    query: availabilityForecastForPlantLookupInterface
  ): Promise<AvailabilityForecastForPlant> {
    let relativeUrl = `/materials/${encodeURIComponent(
      query.materialNumber
    )}/forecast`;
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    let params = {
      plantNumber: query.plantNumber,
      requestedDate: query.requestedDate,
      qty: query.qty,
    };

    let result = await this.connection.get(relativeUrl, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      params: params,
    });

    let forecast = result.data as AvailabilityForecastForPlant;
    return forecast;
  }

  // @deprecated
  // catalog lookup service
  async catalogNumberLookup(
    query: materialLookupInterface
  ): Promise<AxiosResponse<CatalogNumberLookupResult[]>> {
    const { cancelTokenSource, value } = query;
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = "commerce/material/catalog/{1}".replace(
      "{1}",
      encodeURIComponent(value)
    );
    if (!url) throw new Error("API URL undefined");
    const result = this.connection.get(url, {
      baseURL: coreAPIMBase,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result;
  }

  // @deprecated
  // material lookup service
  async materialNumberLookup(
    query: materialLookupInterface
  ): Promise<AxiosResponse<MaterialLookupResult>> {
    const { cancelTokenSource, value } = query;
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = "materials/{1}/isexists".replace(
      "{1}",
      encodeURIComponent(value)
    );
    if (!url) throw new Error("API URL undefined");
    const result = this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result;
  }

  // customer material CMIR lookup service
  async customerMaterialNumberLookup(
    query: customerMaterialLookupInterface
  ): Promise<AxiosResponse<CustomerMaterialLookupResult>> {
    const { cancelTokenSource, value, accountNumber } = query;
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};
    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
      headers["Authorization"] = `Bearer ${token}`;
    }

    const url = "customermaterials/{1}".replace(
      "{1}",
      encodeURIComponent(value)
    );

    const params = {
      accountNumber: accountNumber,
    };
    if (!url) throw new Error("API URL undefined");
    const result = this.connection.get(url, {
      baseURL: coreAPIM_SRI_PATH,
      headers: headers,
      params: params,
      cancelToken: cancelTokenSource?.token,
    });
    return result;
  }

  // new consolidated catalog/material search with shared parts
  async materialSearch(
    query: materialSearchInterface
  ): Promise<AxiosResponse<MaterialSearchResult[]>> {
    const { cancelTokenSource, value, accountNumber } = query;
    let token = await this.authorize();
    const headers: { [key: string]: any } = {};

    if (coreAPIM_Header) {
      headers[coreAPIM_Header] = coreAPIM_Key;
    }
    headers["Authorization"] = `Bearer ${token}`;

    let url = "commerce/materials/search";
    let baseUrl = coreAPIMBase;

    if (process.env.NODE_ENV.trim() === "development") {
      url =
        "api/MaterialSearch?code=6pHMcpuZBH22vdl3CSuijjaz1rLBOkfahsg0wAVuoiX9GvPLavbEpw==";
      baseUrl = "https://func-commerce-materials-dev.azurewebsites.net";
    }

    let apiData = {
      accountNumber: accountNumber,
      searchTerm: value,
    };

    if (!url) throw new Error("API URL undefined");
    const result = this.connection.post(url, apiData, {
      baseURL: baseUrl,
      headers: headers,
      cancelToken: cancelTokenSource?.token,
    });
    return result;
  }
}
export default MaterialDataService;
