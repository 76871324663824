import { Dispatch } from "redux";
import actionCreatorFactory, {
  ActionCreator,
  AsyncActionCreators,
  Failure,
  Success,
} from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { format } from "date-fns";
import {
  createStringHash,
  dataExpired,
  hashExpired,
  wrapAsyncWorker,
  wrapWorker,
} from "./helpers";
import {
  DownloadOrderResponse,
  EmailOrderResponse,
  OrderDetails,
  OrderDocument,
  OrderDocumentTypes,
  Orders,
  OrderTermsOfSaleResponse,
} from "../models/Order";
import { ErrorResponse } from "../models/Error";
import OrderDetailsService from "../services/OrderDetailsService";
import {
  ProductDetails,
  SalesOrder,
  SkippedSalesOrders,
} from "../models/Order";
import { SalesOrganizationInterface } from "../models/SalesOrganization";
import OrderStatusSearchService from "../services/OrderStatusSearchService";
import {
  ApiCall,
  ApiCancelTokenSource,
  ApiError,
} from "../services/ApiService";

import { DateRangeWithType } from "../models/DateRange";
import {
  SavedTableFilterSettings,
  SavedTablePagingSettings,
  SavedTableSettings,
} from "../models/Table";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "./app";
import { productDetailInterface } from "../services/ApiServiceInterfaces";
import MaterialDataService from "../services/MaterialDataService";
import OrderDocumentsService from "../services/OrderDetailsService";
import { DeNotify, Notify } from "./system/notifications/helpers";
import { triggerFileDownload } from "./system/downloadManager/helpers";

const ac = actionCreatorFactory("ordersSearch");
const OrderSearchTableName = "OrderSearch";
const ORDER_DETAILS_CACHE_SIZE = 10;
const notificationPrefix = "DOCUMENTS_DOWNLOAD";

export interface BaseParams {
  brands: Array<SalesOrganizationInterface> | undefined;
  call?: ApiCall<Orders>;
}

export interface SearchParameters extends BaseParams {
  newSearch?: boolean;
}

export interface DateSearchParameters
  extends SearchParameters,
    DateRangeWithType {}

export interface SoldToAccSearchParameters extends DateSearchParameters {
  soldToAccNumber: string | undefined;
  openOnly: boolean;
}

export interface MaterialNumberSearchParameters
  extends SoldToAccSearchParameters {
  materialNumber: string | undefined;
  isCustomerProductNumber: boolean;
}

export interface OrderNumberSearchParameters extends SearchParameters {
  purchaseOrderNumber?: string | undefined;
  orderNumber?: string | undefined;
}

interface OrderSearchStore {
  orderDataHash: string | undefined;
  orderDataDateRange: DateRangeWithType | undefined;
  orderDataTime: string | undefined;
  orderData: undefined | SalesOrder[];
  skippedOrderData: undefined | SkippedSalesOrders;
  searching: boolean;
  error: ErrorResponse | undefined;
  cancelToken: ApiCancelTokenSource | undefined;
  tableSettings: SavedTableSettings;
  productDetails: undefined | ProductDetails;
}

export interface TimedOrderDetails extends OrderDetails {
  timestamp: string;
}

interface OrderDetailsStore {
  orderDetailsLoading: boolean | undefined;
  orderDetailsData: OrderDetails | undefined;
  orderDetailsHash: { [hash: string]: TimedOrderDetails };
  orderDetailsCancel: ApiCancelTokenSource | undefined;
  orderDetailsError: ErrorResponse | undefined;
  orderTermsLoading: boolean | undefined;
  orderTermsCancel: ApiCancelTokenSource | undefined;
  orderTermsError: ErrorResponse | undefined;
}

export interface OrderDetailsParams {
  salesOrderNumber: string;
  salesOrg?: string;
  division?: string;
  refreshDetails?: string;
  call?: ApiCall<OrderDetails> | undefined;
}

export interface OrderDocumentMapInterface {
  id: string;
  document: OrderDocument | undefined;
  downloading: boolean;
  error: ErrorResponse | undefined;
  cancelToken: ApiCancelTokenSource | undefined;
}

interface OrderDocumentsStore {
  downloading: boolean;
  emailing: boolean;
  emailSuccess: EmailOrderResponse | undefined;
  emailError: ErrorResponse | undefined;
  orderDocumentsMap: {
    [key: string]: OrderDocumentMapInterface;
  };
}

interface OrderDocumentParams {
  salesOrderNumber: string;
  accountNumber: string;
  itemIds: string[];
}

export interface OrderDocumentsDownloadParams extends OrderDocumentParams {
  id?: string;
  documentType: OrderDocumentTypes;
  call?: ApiCall<DownloadOrderResponse>;
  onSuccess?: (id: string) => void;
}
export interface OrderDocumentsEmailParams extends OrderDocumentParams {
  documentType: OrderDocumentTypes;
  email: string[];
  cc: string[];
  sender?: string;
  call?: ApiCall<EmailOrderResponse>;
  onSuccess?: (result: EmailOrderResponse) => void;
}

export interface OrderStatusStore
  extends OrderSearchStore,
    OrderDocumentsStore,
    OrderDetailsStore {}

const initialTableSettings: SavedTableSettings = {
  tableId: OrderSearchTableName,
  paging: {
    pageIndex: 0,
    pageSize: 10,
  },
  filters: { filters: [] },
  selectedIds: {},
  sortBy: [],
};

export const initialState: OrderStatusStore = {
  orderDataHash: undefined,
  orderDataDateRange: undefined,
  orderDataTime: undefined,
  orderData: undefined,
  skippedOrderData: undefined,
  searching: false,
  error: undefined,
  cancelToken: undefined,
  tableSettings: initialTableSettings,
  //for order details from exisitng order status search
  orderTermsCancel: undefined,
  orderTermsError: undefined,
  orderTermsLoading: false,
  orderDetailsLoading: false,
  orderDetailsData: undefined,
  orderDetailsHash: {},
  orderDetailsCancel: undefined,
  orderDetailsError: undefined,
  productDetails: undefined,
  downloading: false,
  emailing: false,
  emailSuccess: undefined,
  emailError: undefined,
  orderDocumentsMap: {},
};

enum InvoiceAction {
  noInvoice = '"No Invoice document found"',
}

function getDetails(salesOrderNumber: string) {
  const orderDetailsService = new OrderDetailsService();
  const token = orderDetailsService.generateSourceToken();
  return {
    query: orderDetailsService.GetOrderDetails(salesOrderNumber, token),
    token,
  };
}

function getProductDetails(
  productOrMaterialNumbers: productDetailInterface[],
  imageFetch?: boolean
) {
  let service = new MaterialDataService();
  let token = service.generateSourceToken();
  let queries = productOrMaterialNumbers?.map((query) =>
    service.productDetail(query, token, imageFetch)
  );
  return { queries, token };
}

function getDocuments(params: OrderDocumentsDownloadParams) {
  const { salesOrderNumber, accountNumber, documentType, itemIds } = params;
  const orderDocumentsService = new OrderDocumentsService();
  const token = orderDocumentsService.generateSourceToken();

  switch (documentType) {
    case OrderDocumentTypes.ack:
      return {
        query: orderDocumentsService.GetOrderDocuments(
          salesOrderNumber,
          accountNumber,
          documentType,
          [],
          token
        ),
        token,
      };
    case OrderDocumentTypes.invoice:
    case OrderDocumentTypes.packing:
    case OrderDocumentTypes.shipment:
      return {
        query: orderDocumentsService.GetOrderDocuments(
          salesOrderNumber,
          accountNumber,
          documentType,
          itemIds,
          token
        ),
        token,
      };
    default:
      return undefined;
  }
}

function emailDocuments(params: OrderDocumentsEmailParams) {
  const {
    salesOrderNumber,
    accountNumber,
    documentType,
    itemIds,
    email,
    cc,
    sender,
  } = params;
  const orderDocumentsService = new OrderDocumentsService();
  const token = orderDocumentsService.generateSourceToken();

  switch (documentType) {
    case OrderDocumentTypes.ack:
      return {
        query: orderDocumentsService.EmailOrderDocuments(
          email,
          salesOrderNumber,
          accountNumber,
          documentType,
          cc,
          [],
          sender,
          token
        ),
        token,
      };
    case OrderDocumentTypes.invoice:
    case OrderDocumentTypes.packing:
    case OrderDocumentTypes.shipment:
      return {
        query: orderDocumentsService.EmailOrderDocuments(
          email,
          salesOrderNumber,
          accountNumber,
          documentType,
          cc,
          itemIds,
          sender,
          token
        ),
        token,
      };
    default:
      return undefined;
  }
}

function soldToAccSearch(
  soldToAccNumber: string,
  dateStart: Date,
  dateEnd: Date,
  openOnly: Boolean
): ApiCall<Orders> {
  const orderSearchService = new OrderStatusSearchService();
  const token = orderSearchService.generateSourceToken();
  const query = {
    accountNumber: soldToAccNumber,
    fromDate: format(dateStart, "yyyyMMdd"),
    toDate: format(dateEnd, "yyyyMMdd"),
    openOnly: openOnly,
  };
  return { query: orderSearchService.Search(query, token), token };
}

function materialNumberSearch(
  materialNumber: string,
  dateStart: Date,
  dateEnd: Date,
  soldToAccNumber: string,
  openOnly: Boolean,
  isCustomerProductNumber: Boolean
): ApiCall<Orders> {
  const orderSearchService = new OrderStatusSearchService();
  const token = orderSearchService.generateSourceToken();
  const query = {
    accountNumber: soldToAccNumber,
    fromDate: format(dateStart, "yyyyMMdd"),
    toDate: format(dateEnd, "yyyyMMdd"),
    productNumber: materialNumber,
    openOnly: openOnly,
    isCustomerProductNumber: isCustomerProductNumber,
  };
  return { query: orderSearchService.Search(query, token), token };
}

function orderNumberSearch(
  purchaseOrderNumber: string | undefined,
  orderNumber: string | undefined
): ApiCall<Orders> {
  const orderSearchService = new OrderStatusSearchService();
  const token = orderSearchService.generateSourceToken();
  if (purchaseOrderNumber) {
    const query = {
      purchaseOrderNumber: purchaseOrderNumber as any,
    };
    return { query: orderSearchService.Search(query, token), token };
  } else {
    const query = {
      orderNumber: orderNumber as any,
    };
    return { query: orderSearchService.Search(query, token), token };
  }
}

function mapResults(orders: Orders, brands?: SalesOrganizationInterface[]) {
  let allBrands = brands || [];
  return {
    salesOrders: orders.salesOrders
      ?.map((o: SalesOrder) => {
        let brand = allBrands?.find(
          (b: SalesOrganizationInterface) => b.name === o.brandName
        );
        if (brand) {
          o.salesOrg = brand.salesOrg;
          o.division = brand.division;
        }
        return o;
      })
      /* filter out entries with no brandName field value; these are not supported */
      .filter((i) => i.brandName !== undefined && i.brandName !== null),
    skippedSalesOrders: orders.skippedSalesOrders,
  };
}
// Action creators
// Async Action creators
const SEARCH_ORDERS = ac.async<BaseParams | undefined, Orders, ErrorResponse>(
  "search"
);
const SEARCH_ORDERS_CANCEL = ac("cancel_search");
const SEARCH_ORDERS_BY_ORDERNUMBER = ac<
  OrderNumberSearchParameters | undefined
>("by_ordernumber");
const CLEARDATAHASHANDTIME = ac("clear datahash");
//const SEARCH_ORDERS_BY_DATE = ac<DateSearchParameters | undefined>("by_date");
const SEARCH_ORDERS_BY_SOLDTOACC = ac<SoldToAccSearchParameters | undefined>(
  "by_soldto_account"
);
const SEARCH_ORDERS_BY_MATERIALNUMBER = ac<
  MaterialNumberSearchParameters | undefined
>("by_materialNumber");
const SET_SEARCH_RESULTS_TABLEPAGING = ac<SavedTablePagingSettings>(
  "set_search_results_tablepaging"
);
const SET_SEARCH_RESULTS_TABLEFILTERS = ac<SavedTableFilterSettings>(
  "set_search_results_tablefilters"
);
const SET_SEARCH_RESULTS_TABLESELECTEDROWS = ac<Record<string, boolean>>(
  "set_search_results_tableselectedrows"
);
const SET_SEARCH_RESULTS_TABLESORTBY = ac<
  Array<{ id: string; desc?: boolean }>
>("set_search_results_tablesortby");
const ORDERS_CLEAR = ac("orders_clear");

const SET_DETAILS_TERMS_LOADING: ActionCreator<boolean> = ac<boolean>(
  "set_details_terms_loading"
);

const SET_DETAILS_TERMS: ActionCreator<OrderTermsOfSaleResponse> =
  ac<OrderTermsOfSaleResponse>("set_details_terms");
const SET_DETAILS_TERMS_ERROR: ActionCreator<ErrorResponse> = ac<ErrorResponse>(
  "set_details_terms_error"
);
const SET_DETAILS_TERMS_CANCEL_TOKEN: ActionCreator<ApiCancelTokenSource> =
  ac<ApiCancelTokenSource>("set_details_terms_cancel_token");
const CANCEL_DETAILS_TERMS = ac("cancel_details_terms");

const GET_ORDER_DETAILS = ac<OrderDetailsParams | undefined>("details");
const GET_ORDER_DETAILS_FETCH: AsyncActionCreators<
  OrderDetailsParams | undefined,
  OrderDetails,
  ErrorResponse
> = ac.async<OrderDetailsParams | undefined, OrderDetails, ErrorResponse>(
  "details_fetch"
);
const GET_ORDER_DETAILS_CANCEL = ac("details_cancel");
const GET_ORDER_DETAILS_CLEAR = ac("detail_clear");

const DOCUMENTS_DOWNLOAD: AsyncActionCreators<
  OrderDocumentsDownloadParams | undefined,
  OrderDocumentMapInterface,
  ErrorResponse
> = ac.async<
  OrderDocumentsDownloadParams | undefined,
  OrderDocumentMapInterface,
  ErrorResponse
>("documents");
const DOCUMENTS_DOWNLOAD_CANCEL = ac<string | undefined>(
  "documents_download_clear"
);
const DOCUMENT_CLEAR = ac<string>("document_clear");
const DOCUMENTS_CLEAR = ac("documents_clear");
// create the URL object for downloading, used in conjunction with notifications
const DOCUMENTS_EMAIL = ac.async<
  OrderDocumentsEmailParams | undefined,
  EmailOrderResponse,
  ErrorResponse
>("documents_email");
const DOCUMENTS_EMAIL_CLEAR = ac("documents_email_clear");
const UNSET_SELECTED_ORDER_DETAILS = ac("unset_selected_order_details");

//actions are where you can handle your async calls to the API and manipulate them as needed. Afterwards, the result is passed to the reducers to modify the global state.
const actions = {
  SEARCH_ORDERS: wrapAsyncWorker(
    SEARCH_ORDERS,
    (dispatch: Dispatch, params: BaseParams | undefined): Promise<Orders> => {
      return new Promise<Orders>((resolve, reject) => {
        if (params && params.call) {
          params.call.query
            ?.then((result) => {
              if (result && result.salesOrders) {
                resolve(mapResults(result, params?.brands));
              } else {
                resolve(result);
              }
            })
            .catch((e: ApiError) =>
              reject({
                Message: "Error",
                Error: e.response ? e.response?.status : "1012",
                Detail: e.response ? e.response?.data?.detail : e.message,
              } as ErrorResponse)
            );
        } else {
          reject("Search params not provided");
        }
      });
    }
  ),
  SEARCH_ORDERS_CANCEL: wrapWorker(
    SEARCH_ORDERS_CANCEL,
    (dispatch: Dispatch) => {
      return;
    }
  ),
  CLEARDATAHASHANDTIME: wrapWorker(
    CLEARDATAHASHANDTIME,
    (dispatch: Dispatch) => {
      return;
    }
  ),
  SEARCH_ORDERS_BY_ORDERNUMBER: wrapWorker(
    SEARCH_ORDERS_BY_ORDERNUMBER,
    (dispatch: Dispatch, params: OrderNumberSearchParameters | undefined) => {
      if (params && params.newSearch) {
        if (params.purchaseOrderNumber || params.orderNumber) {
          if (params.purchaseOrderNumber) {
            params.call = orderNumberSearch(
              params.purchaseOrderNumber,
              params.orderNumber
            );
          } else {
            params.call = orderNumberSearch(
              params.purchaseOrderNumber,
              params.orderNumber
            );
          }
          OrdersStatusDuck.actions.SEARCH_ORDERS(dispatch, {
            call: params.call,
            brands: params.brands,
          });
        }
      }
      return params;
    }
  ),
  SEARCH_ORDERS_BY_SOLDTOACC: wrapWorker(
    SEARCH_ORDERS_BY_SOLDTOACC,
    (dispatch: Dispatch, params: SoldToAccSearchParameters | undefined) => {
      if (params && params.newSearch && params.soldToAccNumber) {
        params.call = soldToAccSearch(
          params.soldToAccNumber,
          params.dateStart,
          params.dateEnd,
          params.openOnly
        );
        OrdersStatusDuck.actions.SEARCH_ORDERS(dispatch, {
          call: params.call,
          brands: params.brands,
        });
      }
      return params;
    }
  ),
  SEARCH_ORDERS_BY_MATERIALNUMBER: wrapWorker(
    SEARCH_ORDERS_BY_MATERIALNUMBER,
    (
      dispatch: Dispatch,
      params: MaterialNumberSearchParameters | undefined
    ) => {
      if (
        params &&
        params.newSearch &&
        params.materialNumber &&
        params.soldToAccNumber
      ) {
        params.call = materialNumberSearch(
          params.materialNumber,
          params.dateStart,
          params.dateEnd,
          params.soldToAccNumber,
          params.openOnly,
          params.isCustomerProductNumber
        );
        OrdersStatusDuck.actions.SEARCH_ORDERS(dispatch, {
          call: params.call,
          brands: params.brands,
        });
      }
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLEPAGING: wrapWorker(
    SET_SEARCH_RESULTS_TABLEPAGING,
    (
      dispatch: Dispatch,
      params: SavedTablePagingSettings
    ): SavedTablePagingSettings => {
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLESELECTEDROWS: wrapWorker(
    SET_SEARCH_RESULTS_TABLESELECTEDROWS,
    (
      dispatch: Dispatch,
      params: Record<string, boolean>
    ): Record<string, boolean> => {
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLEFILTERS: wrapWorker(
    SET_SEARCH_RESULTS_TABLEFILTERS,
    (
      dispatch: Dispatch,
      params: SavedTableFilterSettings
    ): SavedTableFilterSettings => {
      return params;
    }
  ),
  SET_SEARCH_RESULTS_TABLESORTBY: wrapWorker(
    SET_SEARCH_RESULTS_TABLESORTBY,
    (
      dispatch: Dispatch,
      params: Array<{ id: string; desc?: boolean }>
    ): Array<{ id: string; desc?: boolean }> => {
      return params;
    }
  ),
  ORDERS_CLEAR: wrapWorker(ORDERS_CLEAR, (dispatch: Dispatch) => {
    return;
  }),
  GET_ORDER_DETAILS: wrapWorker(
    GET_ORDER_DETAILS,
    (dispatch: Dispatch, params: OrderDetailsParams | undefined) => {
      if (params && params.refreshDetails && params.salesOrderNumber) {
        OrdersStatusDuck.actions.GET_ORDER_DETAILS_FETCH(dispatch, params);
      }
      return params;
    }
  ),
  GET_ORDER_DETAILS_FETCH: wrapAsyncWorker(
    GET_ORDER_DETAILS_FETCH,
    (
      dispatch: Dispatch,
      params: OrderDetailsParams | undefined
    ): Promise<OrderDetails> => {
      return new Promise<OrderDetails>((resolve, reject) => {
        if (params && params.call) {
          Promise.resolve(params.call.query)
            .then((result) => {
              resolve(result);
            })
            .catch(() => {
              reject();
            });
        }
      });
    }
  ),
  GET_ORDER_DETAILS_CANCEL: wrapWorker(
    GET_ORDER_DETAILS_CANCEL,
    (dispatch: Dispatch) => {
      return;
    }
  ),
  GET_ORDER_DETAILS_CLEAR: wrapWorker(
    GET_ORDER_DETAILS_CLEAR,
    (dispatch: Dispatch) => {
      return;
    }
  ),
  CANCEL_DETAILS_TERMS: wrapWorker(
    CANCEL_DETAILS_TERMS,
    (dispatch: Dispatch) => {
      return;
    }
  ),

  DOCUMENTS_DOWNLOAD_CANCEL: wrapWorker(
    DOCUMENTS_DOWNLOAD_CANCEL,
    (dispatch: Dispatch, id: string | undefined): string | undefined => {
      return id;
    }
  ),
  DOCUMENTS_DOWNLOAD: wrapAsyncWorker(
    DOCUMENTS_DOWNLOAD,
    (
      dispatch: Dispatch,
      params: OrderDocumentsDownloadParams | undefined
    ): Promise<OrderDocumentMapInterface> => {
      return new Promise<OrderDocumentMapInterface>((resolve, reject) => {
        if (params && params.call && params.id != null) {
          let id = params.id;
          Notify({
            id,
            type: notificationPrefix,
            title: "Downloading... ",
          });
          let waitTime = setTimeout(() => {
            Notify({
              id,
              message: "Please wait. This might take a few moments.",
            });
          }, 2000);
          Promise.resolve(params.call.query)
            .then((result) => {
              clearTimeout(waitTime);
              if (result.Error !== undefined && result.Results === undefined) {
                Notify({
                  id,
                  title: "Download Failed",
                  message: `No ${params.documentType} Document Found`,
                  allowDismiss: true,
                });
                OrdersStatusDuck.actions.DOCUMENT_CLEAR(dispatch, id);
                return;
              }
              if (result.Error?.Detail === InvoiceAction.noInvoice) {
                Notify({
                  id,
                  title: "Download Failed",
                  message: `No ${params.documentType} Document Found`,
                  allowDismiss: true,
                });
                OrdersStatusDuck.actions.DOCUMENT_CLEAR(dispatch, id);
                return;
              }
              if (
                result != null &&
                result.Results?.fileName &&
                result.Results?.fileData
              ) {
                if (params.onSuccess) {
                  params.onSuccess(id);
                }
                const url = window.URL.createObjectURL(result.Results.fileData);
                const filename = result.Results.fileName;
                triggerFileDownload({ url: url, fileName: filename });
                DeNotify(id);

                resolve({
                  id,
                  document: {
                    ...result.Results,
                    url: window.URL.createObjectURL(result.Results.fileData),
                  },
                  downloading: false,
                  error: result.Error,
                  cancelToken: undefined,
                });
              } else {
                Notify({
                  id,
                  title: result.Error?.Message,
                  message: result.Error?.Detail,
                  allowDismiss: true,
                });
                reject(result.Error);
              }
            })
            .catch((error) => {
              Notify({
                id,
                title: error.name,
                message: error.message,
                allowDismiss: true,
              });
              reject(error);
            });
        } else {
          Notify({
            id: new Date().toTimeString(),
            title: "Error",
            message: "No document download params provided.",
            allowDismiss: true,
          });
          reject("No document download params provided.");
        }
      });
    }
  ),
  UNSET_SELECTED_ORDER_DETAILS: wrapWorker(
    UNSET_SELECTED_ORDER_DETAILS,
    (dispatch: Dispatch) => {
      return;
    }
  ),
  DOCUMENT_CLEAR: wrapWorker(
    DOCUMENT_CLEAR,
    (dispatch: Dispatch, id: string) => {
      return id;
    }
  ),
  DOCUMENTS_CLEAR: wrapWorker(DOCUMENTS_CLEAR, (dispatch: Dispatch) => {
    return;
  }),
  DOCUMENTS_EMAIL: wrapAsyncWorker(
    DOCUMENTS_EMAIL,
    (
      dispatch: Dispatch,
      params: OrderDocumentsEmailParams | undefined
    ): Promise<EmailOrderResponse> => {
      return new Promise<EmailOrderResponse>((resolve, reject) => {
        let emailCall:
          | { query: Promise<EmailOrderResponse>; token: ApiCancelTokenSource }
          | undefined = undefined;
        if (
          params &&
          params.salesOrderNumber &&
          params.accountNumber &&
          params.documentType &&
          params.itemIds &&
          params.email
        ) {
          emailCall = emailDocuments(params);
        }
        if (emailCall) {
          Promise.resolve(emailCall.query)
            .then((result) => {
              if (result?.Results != null) {
                if (params?.onSuccess) {
                  params.onSuccess(result);
                }
                resolve(result.Results);
              } else {
                reject(result?.Error || "Email result is undefined.");
              }
            })
            .catch((reason) => {
              reject({
                Message: "Error",
                Error: "1012",
                Detail: reason.message,
              } as ErrorResponse);
            });
        } else {
          reject({
            Message: "Validation Error",
            Error: "1012",
            Detail: "Required parameters are missing.",
          } as ErrorResponse);
        }
      });
    }
  ),
  DOCUMENTS_EMAIL_CLEAR: wrapWorker(
    DOCUMENTS_EMAIL_CLEAR,
    (dispatch: Dispatch) => {
      return;
    }
  ),
};

export const fetchOrderTerms = (orderNumber: string) => {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => AppState
  ) => {
    try {
      if (orderNumber?.length > 0) {
        const { orders: orderStore } = getState();
        const detailsState = orderStore.orderDetailsData;
        if (detailsState?.terms) {
          console.info("terms found");
        } else {
          console.info("no terms found");
          dispatch(SET_DETAILS_TERMS_LOADING(true));
          let service = new OrderDetailsService();
          let token = service.generateSourceToken();
          dispatch(SET_DETAILS_TERMS_CANCEL_TOKEN(token));
          try {
            let results: OrderTermsOfSaleResponse = await service.GetOrderTerms(
              orderNumber,
              token
            );
            if (results) {
              dispatch(SET_DETAILS_TERMS(results));
            } else {
              let noTermsError: ErrorResponse = {
                Message: "Not Found",
                Error: "1011",
                Detail: "Terms & Conditions were not found.",
              };
              dispatch(SET_DETAILS_TERMS_ERROR(noTermsError));
            }
          } catch (e: any) {
            let errorResponse: ErrorResponse = {
              Message: "Error",
              Error: "1011",
              Detail: e.message,
            };
            dispatch(SET_DETAILS_TERMS_ERROR(errorResponse));
            console.error(errorResponse);
          }
        }
      } else {
        console.error("Invalid call to fetchOrderTerms");
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };
};

//the reducers modify the global state of redux with the results of the actions above
const OrderReducer = reducerWithInitialState(initialState)
  .case(SEARCH_ORDERS_CANCEL, (state: OrderStatusStore) => {
    let cancelToken: Partial<ApiCancelTokenSource> | undefined = {
      ...state.cancelToken,
    };
    if (cancelToken && cancelToken.cancel != null) {
      cancelToken.cancel();
    }
    return { ...state, searching: false, cancelToken: undefined };
  })
  .case(CLEARDATAHASHANDTIME, (state: OrderStatusStore) => {
    return { ...state, orderDataHash: undefined, orderDataTime: undefined };
  })
  .case(
    SEARCH_ORDERS_BY_ORDERNUMBER,
    (
      state: OrderStatusStore,
      params: OrderNumberSearchParameters | undefined
    ) => {
      let { orderDataTime, orderDataHash } = state;
      if (params) {
        let hash = createStringHash(
          params.purchaseOrderNumber
            ? params.purchaseOrderNumber
            : params.orderNumber
        );
        if (
          hash !== state.orderDataHash ||
          !orderDataTime ||
          dataExpired(new Date(orderDataTime as string))
        ) {
          params.newSearch = true;
          orderDataHash = hash;
        }
      }
      return { ...state, orderDataHash, cancelToken: params?.call?.token };
    }
  )
  .case(
    SEARCH_ORDERS_BY_SOLDTOACC,
    (
      state: OrderStatusStore,
      params: SoldToAccSearchParameters | undefined
    ) => {
      let { orderDataTime, orderDataHash, orderDataDateRange } = state;
      if (params) {
        let hash = createStringHash(
          params.soldToAccNumber,
          params.dateStart?.toDateString(),
          params.dateEnd?.toDateString(),
          params.openOnly
        );
        if (
          hash !== state.orderDataHash ||
          !orderDataTime ||
          dataExpired(new Date(orderDataTime as string))
        ) {
          params.newSearch = true;
          orderDataHash = hash;
          orderDataDateRange = {
            dateStart: params.dateStart,
            dateEnd: params.dateEnd,
          };
        }
      }
      return {
        ...state,
        orderDataHash,
        orderDataDateRange,
        cancelToken: params?.call?.token,
      };
    }
  )
  .case(
    SEARCH_ORDERS_BY_MATERIALNUMBER,
    (
      state: OrderStatusStore,
      params: MaterialNumberSearchParameters | undefined
    ) => {
      let { orderDataTime, orderDataHash, orderDataDateRange } = state;
      if (params) {
        let hash = createStringHash(
          params.soldToAccNumber,
          params.dateStart?.toDateString(),
          params.dateEnd?.toDateString(),
          params.materialNumber,
          params.openOnly
        );
        if (
          hash !== state.orderDataHash ||
          !orderDataTime ||
          dataExpired(new Date(orderDataTime as string))
        ) {
          params.newSearch = true;
          orderDataHash = hash;
          orderDataDateRange = {
            dateStart: params.dateStart,
            dateEnd: params.dateEnd,
          };
        }
      }
      return {
        ...state,
        orderDataHash,
        orderDataDateRange,
        cancelToken: params?.call?.token,
      };
    }
  )
  .case(
    SEARCH_ORDERS.started,
    (state: OrderStatusStore, payload: BaseParams | undefined) => {
      return {
        ...state,
        orderData: [],
        skippedOrderData: undefined,
        searching: true,
        error: undefined,
      };
    }
  )
  .case(
    SEARCH_ORDERS.done,
    (
      state: OrderStatusStore,
      payload: Success<BaseParams | undefined, Orders>
    ) => {
      return {
        ...state,
        searching: false,
        orderData: payload?.result?.salesOrders,
        skippedOrderData: payload?.result?.skippedSalesOrders
          ? payload?.result?.skippedSalesOrders[0]
          : undefined,
        cancelToken: undefined,
        error: undefined,
        orderDataTime: new Date().getTime().toString(),
        tableSettings: initialTableSettings,
      };
    }
  )
  .case(
    SEARCH_ORDERS.failed,
    (
      state: OrderStatusStore,
      payload: Failure<BaseParams | undefined, ErrorResponse>
    ) => {
      console.debug("failed", payload);
      if (payload.error) {
        return {
          ...state,
          searching: false,
          error: payload.error,
          cancelToken: undefined,
          orderDataTime: new Date().getTime().toString(),
        };
      } else {
        return {
          ...state,
          searching: false,
          error: {
            Message: "Unknown Error",
            Error: "1011",
            Detail: "An unknown error has occurred, please contact support.",
          },
        };
      }
    }
  )
  .case(
    SET_SEARCH_RESULTS_TABLEPAGING,
    (state: OrderStatusStore, paging: SavedTablePagingSettings) => {
      return { ...state, tableSettings: { ...state.tableSettings, paging } };
    }
  )
  .case(ORDERS_CLEAR, (state: OrderStatusStore) => {
    return {
      ...state,
      orderDataHash: undefined,
      orderData: undefined,
      skippedOrderData: undefined,
      orderDataTime: undefined,
      error: undefined,
      cancelToken: undefined,
      orderDetailsError: undefined,
    };
  })
  .case(
    SET_SEARCH_RESULTS_TABLEFILTERS,
    (state: OrderStatusStore, filters: SavedTableFilterSettings) => {
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          filters,
          paging: { ...state.tableSettings.paging, pageIndex: 0 },
          selectedIds: {},
        },
      };
    }
  )
  .case(
    SET_SEARCH_RESULTS_TABLESORTBY,
    (
      state: OrderStatusStore,
      sortBy: Array<{ id: string; desc?: boolean }>
    ) => {
      return { ...state, tableSettings: { ...state.tableSettings, sortBy } };
    }
  )
  .case(
    GET_ORDER_DETAILS,
    (state: OrderStatusStore, payload: OrderDetailsParams | undefined) => {
      let { orderDetailsData, orderDetailsHash, orderDetailsLoading } = {
        ...state,
      };
      let detailHashes = orderDetailsHash && Object.keys(orderDetailsHash);
      let sortedHashes =
        detailHashes &&
        Object.values(detailHashes)?.sort(
          (x, y) =>
            parseInt(orderDetailsHash[y]?.timestamp) -
            parseInt(orderDetailsHash[x]?.timestamp)
        );
      while (sortedHashes.length > ORDER_DETAILS_CACHE_SIZE) {
        // if stack holds 10, clear oldest
        delete orderDetailsHash[sortedHashes[sortedHashes.length - 1]];
        sortedHashes?.pop();
        detailHashes = sortedHashes;
      }
      if (payload && payload.salesOrderNumber) {
        let hash = createStringHash(
          payload.salesOrderNumber,
          payload.salesOrg,
          payload.division
        );
        if (
          detailHashes.indexOf(hash) === -1 ||
          state.orderDetailsHash[hash] == null ||
          hashExpired(state.orderDetailsHash[hash]?.timestamp)
        ) {
          payload.refreshDetails = hash;
          // clear previous timestamped details
          delete orderDetailsHash[hash];
          orderDetailsLoading = true;
          payload.call = getDetails(payload.salesOrderNumber);
        } else if (orderDetailsHash[hash] != null) {
          // set active order details
          orderDetailsData = orderDetailsHash[hash];
        }
      }
      return {
        ...state,
        orderDetailsHash,
        orderDetailsData,
        orderDetailsCancel: payload?.call?.token,
        orderDetailsError: undefined,
        orderDetailsLoading,
      };
    }
  )
  .case(
    GET_ORDER_DETAILS_FETCH.started,
    (state: OrderStatusStore, payload: OrderDetailsParams | undefined) => {
      return {
        ...state,
        orderDetailsData: undefined,
        orderDetailsLoading: true,
        orderDetailsError: undefined,
      };
    }
  )
  .case(
    GET_ORDER_DETAILS_FETCH.done,
    (
      state: OrderStatusStore,
      payload: Success<OrderDetailsParams | undefined, OrderDetails> | undefined
    ) => {
      if (payload?.params?.salesOrg && payload?.params?.division) {
        let query: productDetailInterface[] = [];
        payload.result?.items?.forEach((i) => {
          if (payload?.params?.salesOrg && payload?.params?.division) {
            query.push({
              materialNumber: i.productNumber,
              salesOrg: payload?.params.salesOrg,
              division: payload?.params.division,
            });
          }
        });
        // fetch images for path caching only, no need for blocking code
        let detailsQuery = getProductDetails(query, true);
        Promise.allSettled(detailsQuery?.queries);
      }
      let orderDetailsHash = { ...state.orderDetailsHash };
      /*
      // TODO: replace block
      // update the summary result to include salesOrg and division
      // these values are not always set because the attempt to lookup the brand by name fails
      if (payload && payload.result) {
        let hash = createStringHash(
          payload.result.salesOrderNumber,
          payload.result.brand.salesOrg,
          payload.result.brand.division
        );
        orderDetailsHash[hash] = {
          ...payload.result,
          timestamp: new Date().getTime().toString(),
        };
      }
      */
      if (payload && payload.result && payload.params?.refreshDetails) {
        orderDetailsHash[payload.params.refreshDetails] = {
          ...payload.result,
          timestamp: new Date().getTime().toString(),
        };
      }
      // end TODO: replace block

      return {
        ...state,
        orderDetailsData: payload?.result,
        orderDetailsHash,
        orderDetailsLoading: false,
      };
    }
  )
  .case(
    GET_ORDER_DETAILS_FETCH.failed,
    (
      state: OrderStatusStore,
      payload: Failure<
        OrderDetailsParams | undefined,
        ErrorResponse | undefined
      >
    ) => {
      return {
        ...state,
        orderDetailsCancel: undefined,
        orderDetailsLoading: false,
        orderDetailsError: payload.error || {
          Message: "Unknown Error",
          Error: "1011",
          Detail: "An unknown error has occurred, please contact support.",
        },
      };
    }
  )
  .case(GET_ORDER_DETAILS_CLEAR, (state: OrderStatusStore) => {
    return {
      ...state,
      orderDetailsData: undefined,
      orderDetailsCancel: undefined,
      orderDetailsError: undefined,
      orderDetailsLoading: false,
    };
  })
  .case(UNSET_SELECTED_ORDER_DETAILS, (state: OrderStatusStore) => {
    return { ...state, orderDetailsData: undefined };
  })
  .case(
    SET_SEARCH_RESULTS_TABLESELECTEDROWS,
    (state: OrderStatusStore, selectedIds: Record<string, boolean>) => {
      return {
        ...state,
        tableSettings: { ...state.tableSettings, selectedIds },
      };
    }
  )
  .case(GET_ORDER_DETAILS_CANCEL, (state: OrderStatusStore) => {
    let orderDetailsCancel: Partial<ApiCancelTokenSource> | undefined = {
      ...state.orderDetailsCancel,
    };
    if (orderDetailsCancel && orderDetailsCancel.cancel) {
      orderDetailsCancel.cancel();
    }
    return {
      ...state,
      orderDetailsLoading: false,
      orderDetailsCancel: undefined,
      error: undefined,
      orderDetailsError: undefined,
    };
  })
  .case(
    SET_DETAILS_TERMS_LOADING,
    (state: OrderStatusStore, loadingTerms: boolean) => {
      return {
        ...state,
        orderTermsLoading: loadingTerms,
      };
    }
  )
  .case(
    SET_DETAILS_TERMS,
    (state: OrderStatusStore, terms: OrderTermsOfSaleResponse) => {
      const details = { ...state.orderDetailsData } as OrderDetails;

      details.terms = {
        paymentTerms: terms.paymentTerms,
        shippingTerms: terms.shippingTerms,
      };

      // this rarely works because the hash is often mapped with undefined values
      // for salesOrg and division
      let hash = createStringHash(
        details.salesOrderNumber,
        details.brand?.salesOrg,
        details.brand?.division
      );
      let orderDetailsHash = { ...state.orderDetailsHash };
      if (orderDetailsHash[hash]) orderDetailsHash[hash].terms = details.terms;

      return {
        ...state,
        orderDetailsData: details,
        orderTermsLoading: false,
        orderTermsError: undefined,
        orderTermsCancel: undefined,
        orderDetailsHash: orderDetailsHash,
      };
    }
  )
  .case(
    SET_DETAILS_TERMS_ERROR,
    (state: OrderStatusStore, error: ErrorResponse) => {
      return {
        ...state,
        orderTermsError: error,
      };
    }
  )
  .case(
    SET_DETAILS_TERMS_CANCEL_TOKEN,
    (state: OrderStatusStore, cancelToken: ApiCancelTokenSource) => {
      return { ...state, orderTermsCancel: cancelToken };
    }
  )
  .case(CANCEL_DETAILS_TERMS, (state: OrderStatusStore) => {
    if (state.orderTermsCancel) {
      state.orderTermsCancel?.cancel();
    }
    return {
      ...state,
      orderTermsCancel: undefined,
      orderTermsError: undefined,
      orderTermsLoading: false,
    };
  })
  .case(
    DOCUMENTS_DOWNLOAD_CANCEL,
    (state: OrderStatusStore, id: string | undefined) => {
      let orderDocumentsMap = { ...state.orderDocumentsMap };
      if (id && orderDocumentsMap[id]) {
        if (orderDocumentsMap[id]?.cancelToken) {
          orderDocumentsMap[id]?.cancelToken?.cancel();
        }
        delete orderDocumentsMap[id];
      }
      return { ...state, orderDocumentsMap };
    }
  )
  .case(
    DOCUMENTS_DOWNLOAD.started,
    (
      state: OrderStatusStore,
      payload: OrderDocumentsDownloadParams | undefined
    ) => {
      let orderDocumentsMap = { ...state.orderDocumentsMap };
      if (payload) {
        if (!payload.id) {
          let time = new Date();
          payload.id = time.getTime().toString();
        }
        let downloadCall:
          | {
              query: Promise<DownloadOrderResponse>;
              token: ApiCancelTokenSource;
            }
          | undefined = getDocuments(payload);
        if (downloadCall) {
          let cancelToken = downloadCall.token;
          orderDocumentsMap[payload.id] = {
            id: payload?.id,
            downloading: true,
            error: undefined,
            document: undefined,
            cancelToken,
          };
          payload.call = downloadCall;
        }
      }
      return { ...state, orderDocumentsMap };
    }
  )
  .case(
    DOCUMENTS_DOWNLOAD.done,
    (
      state: OrderStatusStore,
      payload: Success<
        OrderDocumentsDownloadParams | undefined,
        OrderDocumentMapInterface
      >
    ) => {
      let orderDocumentsMap = { ...state.orderDocumentsMap };
      orderDocumentsMap[payload?.result?.id] = payload.result;
      // if (payload.params?.onSuccess) {
      //   payload.params.onSuccess()
      // }
      return { ...state, orderDocumentsMap };
    }
  )
  .case(
    DOCUMENTS_DOWNLOAD.failed,
    (
      state: OrderStatusStore,
      payload: Failure<OrderDocumentsDownloadParams | undefined, ErrorResponse>
    ) => {
      let orderDocumentsMap = { ...state.orderDocumentsMap };
      if (payload && payload.params && payload.params?.id) {
        orderDocumentsMap[payload.params?.id] = {
          ...orderDocumentsMap[payload.params?.id],
          downloading: false,
          error: payload.error || {
            Message: "Unknown Error",
            Error: "1011",
            Detail: "An unknown error has occurred, please contact support.",
          },
          cancelToken: undefined,
        };
      }
      return { ...state, orderDocumentsMap };
    }
  )
  .case(DOCUMENT_CLEAR, (state: OrderStatusStore, id: string) => {
    if (id && state.orderDocumentsMap[id]) {
      let orderDocumentsMap = { ...state.orderDocumentsMap };
      let url = orderDocumentsMap[id].document?.url;
      if (url) window.URL.revokeObjectURL(url);
      delete orderDocumentsMap[id];
      return { ...state, orderDocumentsMap };
    }
    return state;
  })
  .case(DOCUMENTS_CLEAR, (state: OrderStatusStore) => {
    let orderDocumentsMap = { ...state.orderDocumentsMap };
    if (orderDocumentsMap) {
      Object.keys(orderDocumentsMap)?.forEach((x) => {
        let url = orderDocumentsMap[x].document?.url;
        if (url) window.URL.revokeObjectURL(url);
      });
    }
    return { ...state, orderDocumentsMap: {} };
  })
  .case(
    DOCUMENTS_EMAIL.started,
    (
      state: OrderStatusStore,
      payload: OrderDocumentsEmailParams | undefined
    ) => {
      return {
        ...state,
        emailing: true,
        emailSuccess: undefined,
        emailError: undefined,
      };
    }
  )
  .case(
    DOCUMENTS_EMAIL.done,
    (
      state: OrderStatusStore,
      payload: Success<
        OrderDocumentsEmailParams | undefined,
        EmailOrderResponse
      >
    ) => {
      return { ...state, emailing: false, emailSuccess: payload.result };
    }
  )
  .case(
    DOCUMENTS_EMAIL.failed,
    (
      state: OrderStatusStore,
      payload: Failure<OrderDocumentsEmailParams | undefined, ErrorResponse>
    ) => {
      return {
        ...state,
        emailing: false,
        emailError: payload.error || {
          Message: "Unknown Error",
          Error: "1011",
          Detail: "An unknown error has occurred, please contact support.",
        },
      };
    }
  )
  .case(DOCUMENTS_EMAIL_CLEAR, (state: OrderStatusStore) => {
    return {
      ...state,
      emailing: false,
      emailSuccess: undefined,
      emailError: undefined,
    };
  })
  .build();

export const OrdersStatusDuck = {
  actions,
  reducer: OrderReducer,
};
