// import moment from "moment";
import {format} from "date-fns";
import {
  QuoteSearchParameters,
  QuoteDetailData,
  QuoteSearchPreset,
  QuoteSearchInputParameters,
} from "../store/quotesStore";
import { QuoteDetails, QuoteTermsAndConditions } from "../models/Quote";
import {
  CustomerQuoteSearchInterface,
  CustomerQuoteSearchMode,
} from "../services/QuoteServiceInterface";

export const mapInputSearchParameters = (
  input: QuoteSearchInputParameters
): QuoteSearchParameters => {
  // empty search params
  let searchParameters: QuoteSearchParameters = {
    presetValue: undefined,
    materialNumber: undefined,
    quoteNumber: undefined,
    projectName: undefined,
    quoteType: undefined,
    createdDateTo: undefined,
    createdDateFrom: undefined,
    mode: undefined,
    customerAccountNumber: undefined,
    quoteTypeName: undefined,
  };

  if (input?.customerAccountNumber) {
    // minimum required
    searchParameters = {
      ...searchParameters,
      presetValue: input.presetValue,
      customerAccountNumber: input.customerAccountNumber,
    };
    // advanced search form inputs
    if (input?.quoteNumber) {
      // quote number
      searchParameters = {
        ...searchParameters,
        quoteNumber: input.quoteNumber,
      };
    } else if (input?.createdDateFrom && input?.createdDateTo) {
      // full advanced form
      searchParameters = {
        ...searchParameters,
        createdDateFrom: format(input.createdDateFrom ,"yyyyMMdd"),
        createdDateTo: format(input.createdDateTo ,"yyyyMMdd"),
        mode: input.mode,
        materialNumber: input.materialNumber,
        projectName: input.projectName,
        quoteType: input.quoteType,
        quoteTypeName: input.quoteTypeName,
      };
    }
  }

  return searchParameters;
};

export const checkForNewSearchParameters = (
  current: QuoteSearchParameters,
  newParameters: QuoteSearchParameters
): boolean => {
  let currentKeys = current && Object.keys(current);
  let newKeys = newParameters && Object.keys(newParameters);
  if (currentKeys.length !== newKeys.length) {
    return true;
  }

  let anyPropNotEqual = false;
  for (let i = 0; i < currentKeys.length && !anyPropNotEqual; i++) {
    let key = currentKeys[i];
    let keyString = key as keyof QuoteSearchParameters;
    anyPropNotEqual = current[keyString] !== newParameters[keyString];
  }
  return anyPropNotEqual;
};

export const buildQuotesSearchQuery = (
  searchParameters: QuoteSearchParameters
): CustomerQuoteSearchInterface | undefined => {
  let searchQuery = undefined;
  if (searchParameters?.customerAccountNumber) {
    searchQuery = {
      accountNumber: searchParameters.customerAccountNumber,
    } as CustomerQuoteSearchInterface;

    if (searchParameters.presetValue === QuoteSearchPreset.ADVANCED) {
      searchQuery.quoteType = searchParameters?.quoteType;
      searchQuery.projectName = searchParameters?.projectName;
      searchQuery.productNumber = searchParameters?.materialNumber;
      searchQuery.quoteNumber = searchParameters?.quoteNumber;
      searchQuery.createdFromDate = searchParameters?.createdDateFrom;
      searchQuery.createdToDate = searchParameters?.createdDateTo;
      searchQuery.mode = searchParameters?.mode;
      searchQuery.expiringInDays = searchParameters?.expiringInDays;
    } else {
      switch (searchParameters.presetValue) {
        case QuoteSearchPreset.VALID:
          searchQuery.mode = CustomerQuoteSearchMode.ALL;
          break;
        case QuoteSearchPreset.ACTIVE:
          searchQuery.mode = CustomerQuoteSearchMode.ACTIVE;
          break;
        case QuoteSearchPreset.EXPIRED:
          searchQuery.mode = CustomerQuoteSearchMode.EXPIRED;
          break;
        case QuoteSearchPreset.FUTURE:
          searchQuery.mode = CustomerQuoteSearchMode.FUTURE;
          break;
        case QuoteSearchPreset.EXPIRING_30:
          searchQuery.mode = CustomerQuoteSearchMode.EXPIRING_IN;
          searchQuery.expiringInDays = 30;
          break;
        case QuoteSearchPreset.EXPIRING_60:
          searchQuery.mode = CustomerQuoteSearchMode.EXPIRING_IN;
          searchQuery.expiringInDays = 60;
          break;
        case QuoteSearchPreset.EXPIRING_90:
          searchQuery.mode = CustomerQuoteSearchMode.EXPIRING_IN;
          searchQuery.expiringInDays = 90;
          break;
        default:
      }
    }
  }
  return searchQuery;
};

export const updateDetailsMap = (
  quoteNumber: string,
  cacheSize: number,
  currentMap: Map<string, QuoteDetailData>,
  data: QuoteDetails | undefined,
  terms: QuoteTermsAndConditions | undefined
): Map<string, QuoteDetailData> => {
  let newMap: Map<string, QuoteDetailData> = new Map(currentMap);
  if (data) {
    if (newMap.has(quoteNumber)) {
      newMap.delete(quoteNumber);
    }
    if (newMap.size === cacheSize) {
      let oldestQuote = newMap.keys().next().value;
      newMap.delete(oldestQuote);
    }
    let quotesDetailData: QuoteDetailData = {
      terms: undefined,
      data: data,
      timestamp: new Date(),
    };
    newMap.set(quoteNumber, quotesDetailData);
  } else {
    let existingData: QuoteDetailData | undefined;
    existingData = newMap.get(quoteNumber);
    if (existingData) {
      newMap.set(quoteNumber, { ...existingData, terms });
    }
    // should not occur
    if (!existingData) {
      let quotesDetailData: QuoteDetailData = {
        terms: terms,
        data: undefined,
        timestamp: new Date(),
      };
      newMap.set(quoteNumber, quotesDetailData);
    }
  }
  return newMap;
};
