import MaterialInventory from "./MaterialInventory";

export enum OrderAddressTypeName {
  SoldTo = "Sold-To",
  BillTo = "Bill-To",
  ShipTo = "Ship-To",
  ThirdPartyFreight = "Freight-Third-Party",
}

export default interface HubbellConnectOrder {
  orderId: string;
  orderNumber: string;
  accountNumber: string;
  sapOrderNumbers: string;
  orderState: OrderState;
  orderStateNote: string;
  createdBy: userData;
  createdOn: string;
  modifiedBy: userData;
  modifiedOn: string;
  orderAddresses: Address[];
  items: Item[];
  details: Detail[];
  suggestedAddress?: Address;
}

export interface userData {
  userId: string;
  userName: string;
  name: string;
}

export interface Detail {
  agentNotes: string;
  carrierInstructions: string;
  carrierPreference: string;
  carrierPreferenceName: string;
  createdBy: userData;
  createdOn: string;
  destinationCountry: string;
  division: string;
  freightAccountNumber: string;
  freightTermsName: string;
  freightTermsValue: string;
  freightThirdPartyAddress: Address | undefined;
  holdForReview: boolean;
  internalNotes: string;
  markings: string;
  modifiedBy: userData;
  modifiedOn: string;
  notes: string;
  orderDetailId: string;
  orderType: string;
  poAttachmentUrl: string;
  purchaseOrderNumber: string;
  purchaseOrderDate: string | Date;
  requestedShipDate: string | Date;
  rush: boolean;
  salesOrg: string;
  sapOrderNumber: string;
  shipComplete: boolean;
  specialInstructions: string;
  freightTerms: FreightTerms;
}

export interface FreightTerms {
  freightTermsId: string;
  name: string;
  description: string;
  value: string;
}

export interface FreightDetailsTerms extends FreightTerms {
  salesOrg: string;
  division: string;
  isRush: boolean;
  isStandard: boolean;
}

export interface Address {
  orderAddressId: string;
  orderAddressType: OrderAddressType;
  name1: string;
  name2: string;
  name3: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  postal: string;
  country: string;
  createdBy?: userData;
  createdOn?: string;
  modifiedBy?: userData;
  modifiedOn?: string;
  isUserSupplied: boolean;
}

export interface OrderAddressType {
  orderAddressTypeId: string;
  name: string;
}

export interface Item {
  orderItemId: string;
  currency: string;
  salesOrg: string;
  division: string;
  materialNumber: string;
  quantity: string;
  lineNumber: string;
  purchaseOrderLineNumber: string;
  orderItemState: OrderItemState;
  orderItemStateNote: string;
  sapOrderNumber: string;
  customerPartNumber: string;
  catalogNumber: string;
  description: string;
  referenceQuoteNumber: string;
  referenceOrderNumber: string;
  requestedShipDate: string;
  consigneePurchaseOrderNumber: string;
  markings: string;
  unitPrice: string;
  extendedPrice: string;
  priceLastRefreshed: string;
  inventoryLastRefreshed: string;
  itemCategory: string;
  minimumOrderQty: string;
  baseUnitOfMeasure: string;
  standardPackageQty: string;
  palletQty: number;
  caseQty: number;
  cartonQty: string;
  plantNumber: string;
  plantDescription: string;
  createdBy: userData;
  createdOn: string;
  modifiedBy: userData;
  modifiedOn: string;
  grossWeight: number;
  inventory: Omit<MaterialInventory, "leadTime">;
  hasError?: boolean
}

export interface OrderItemState {
  orderItemStateId: string;
  name: string;
}

export interface OrderState {
  orderStateId: string;
  name: string;
}

export enum FreightTermsEnum {
  PrepayAdd = "Prepay & Add",
  CollectYOB = "Collect",
  ThirdParty = "3rd Party",
  WillCall = "Will Call",
}

export enum OrderTypeEnum {
  Standard = "Standard",
  Export = "Export",
}

export interface Notification {
  id: string;
  toastMessage: string;
}

export interface DetailData extends Detail {
  sapOrderNumber: string;
  carrierPreferenceName: string;
  poAttachmentUrl: string;
}
