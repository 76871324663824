export const triggerFileDownload = (fileDownload: {
  url: string;
  fileName: string;
}) => {
  if (fileDownload.fileName && fileDownload.url) {
    const link = document.createElement("a");
    link.href = fileDownload.url;
    link.setAttribute("download", fileDownload.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};
